import styled from "styled-components";

export const TextBlock = styled.div`
  margin-bottom: 12px;
  font-size: 18px;
`;

export const TextBlockTop = styled(TextBlock)`
  margin-bottom: 25px;
`;

export const Container = styled.div`
  //display: flex;
  //flex-direction: column;
  padding: 20px;
  margin-top: 20px;
`;

export const QText = styled.span`
  text-decoration: underline;
  font-weight: 800;
`;

export const RightAnswer = styled.div`
  font-weight: 800;
  padding: 12px;
  color: #43a047;
`;

export const WrongAnswer = styled(RightAnswer)`
  color: #e53935;
`;

export const QuestionBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageBlock = styled.div`
  margin-right: 20px;
`;

export const AnswerBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexBlock = styled.div`
  display: flex;
`;
