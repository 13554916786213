import React, { useState } from "react";
import ControlTab from "../ControlTab";
import Button from "@material-ui/core/Button";

import {
  AnswerBlock,
  FlexBlock,
  ImageBlock,
  QText,
  QuestionBlock,
  RightAnswer,
  TextBlock,
  TextBlockTop,
  WrongAnswer,
  Container
} from "./index.style";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const quiz = [
  {
    id: 1,
    q: "Какой факультет МГУ окончил Юрий Мильнер?",
    a: [
      "Вычислительной математики и кибернетики",
      "Механико-математический",
      "Физический",
      "Экономический"
    ],
    right: 2,
    image:
      "https://storage.googleapis.com/alafia-tech.appspot.com/images/56b5ce3.jpg?GoogleAccessId=firebase-adminsdk-kocka%40alafia-tech.iam.gserviceaccount.com&Expires=16731014400&Signature=rqbnfiFlpTtjaFaOkbocxXWRAvXiHmDGH%2By3dI6t07XlVu%2BUibujs9VA3yH2L3OZO%2FNbCP3tvh4Hziktl9vAhAyeJJctMLBAM3FOT03A%2FeVXhonpWtAW3et2oNQH8Uiak%2BFFnvsHfmwAN7xBUM4NlZyNbbu2G4Q9xcQkGL0x2gGp9yFgFUCSsnWkl5%2FnoRSJ8JPPMO1OzgMc15JgAByYW6VuXTEWjCJEBx%2Bw60dQEi3URL1u4YwlWEDUZw%2Fe0xdPyULvttvRs9%2FHFFAyZihopsAHEIk67dtDMJBPZEWdHYWyJMqDlsD9FXzqnagZ0Yo%2FI6IURZgfJbcUb7yGaPURmw%3D%3D",
    err: "Юрий окончил Физфак МГУ"
  },
  {
    id: 2,
    q: "В каком городе родился Павел Дуров?",
    a: ["Москва", "Новосибирск", "Санкт-Петербург", "Нижний Новгород"],
    right: 2,
    image:
      "https://storage.googleapis.com/alafia-tech.appspot.com/images/5452e5c.jpg?GoogleAccessId=firebase-adminsdk-kocka%40alafia-tech.iam.gserviceaccount.com&Expires=16731014400&Signature=kVKux7lRYLmjJAC3kNNh%2FGo2cPgepiImZuXeJzD%2FNK4O5Co4kiBQomBjSR3A8Rv40paTf3eVNlkxU9pI8bOl0QCPdVjamVDSw0B6xnlZQL9ZZSdRK%2B9kQfah6Gdyu1tnyPxShJCo4JrnW0EvGn25rO3Y5eE%2BH55Eju616gklmcrxi5Z2E6mLfaI%2BBHz6zog0w6SEnayklGWTKK6DHxwjO3bx8MBTGcTUlwPTyTj9n3eWFvsg1rNYoCB4cYguTEzrJj%2BpC9qDB7LQufun4zH%2BSac7aYw%2FUYp26ZXWdS03dFthaxZhT7dlPGUkKIOftFegRKnjjo60PUjiHF4enAveZg%3D%3D",
    err: "Павел родился в Санкт-Петербурге"
  },
  {
    id: 3,
    q:
      "Как называется металлургическая компания, представляющая собой самый крупный актив Александра Абрамова?",
    a: ["НЛМК", "ЕВРАЗ", "Северсталь", "РУСАЛ"],
    right: 1,
    image:
      "https://storage.googleapis.com/alafia-tech.appspot.com/images/37cc853.jpg?GoogleAccessId=firebase-adminsdk-kocka%40alafia-tech.iam.gserviceaccount.com&Expires=16731014400&Signature=tXBUgOMKlHGuy84QdiasXH92qaSGiTdvRSz5044QBnaZSN2VNQoKbd%2BdvbLy01qu6P113adLnKwJLKldVvTnW0K7NEzK6v%2BcgSHm1plGk0dWcqRsdgGTZUXoY3C4kpjspTr7O4V%2FCkEwzvb%2FlP5Fs%2BEG%2BgGNqee1r39BHsEO2QydaqOXit02iXJdMy%2Fmh7WgW5%2Biwj9drHRtsoQfXLRJuv2PmNSiRO3w7sW0NgAooAcy0bMO7lSMOVvwkczLTz8UajN2UJlknkaWlXVDt7fioM%2Byfo9mraHe1lL6vRwX8qjsDmrSTFfqKWFQp1%2FaIfh3rRiJlomaVBF92r3f0VDuMw%3D%3D",
    err: "ЕВРАЗ является самым крупным активом Александра"
  },
  {
    id: 4,
    q:
      "Где Сергей Галицкий начал свою профессиональную карьеру после службы в Армии?",
    a: ["В торговле", "В строительстве", "В отрасли ИТ", "В банковской сфере"],
    right: 3,
    image:
      "https://storage.googleapis.com/alafia-tech.appspot.com/images/490797.jpg?GoogleAccessId=firebase-adminsdk-kocka%40alafia-tech.iam.gserviceaccount.com&Expires=16731014400&Signature=bfAERDlVCoRaraxhA%2BZcJXf5E6%2BSP53CIjtYYclkWZo7i1sj%2BvPmu2HsvxLkeJOOBjNShIihd38bT9cry3vrvcASsdW66THLP1pRA%2B2CVnEqF7Dbo8GKNZuoKHYYOOAf0pTRTw1a8ycvvht6c90cU8WvroX9LkcNZxAnDSBo83ww9ZLcwyjNxvSgqfrCtwcgEgcQOGJZNjEw9kfsaDT6EFitvYR3%2BPIh5W6r8ftvowf1CYQnMe%2B%2FWkUoSLyJOeZRwIW96%2BqdHiOrFVT0dKTqjhHQFj5PZlcPAxqg%2FfIShD%2FqBgiXU9%2Bw%2BpdWFyaxUBAjqEhlwNybcSDCU3%2FSYy%2B7tg%3D%3D",
    err: "Сергей начал свою карьеру в банковской сфере"
  },
  {
    id: 5,
    q: "В какой холдинг входит Альфа-Банк, самый крупный актив Петра Авена?",
    a: [
      "Letter One Holdings",
      "Консорциум Альфа-Групп",
      "USM Holdings",
      "Интеррос"
    ],
    right: 1,
    image:
      "https://storage.googleapis.com/alafia-tech.appspot.com/images/4e1f01e.jpg?GoogleAccessId=firebase-adminsdk-kocka%40alafia-tech.iam.gserviceaccount.com&Expires=16731014400&Signature=O0g%2FYspjUgeOUdO7ULaGspXVz3qGBD2GT0K%2BmknEouCj9%2BvfsS4mx8EhhYX%2BVA7x5DdHh7k4Teoj%2FXy9Oz460VobMVy6J1u6MIvI4Tc%2BcalMm6gsmV9a8ROGktuhjuItyIvGbMmgcAvMsP5yEYe%2BF1Msi4xaS2cc0DardY7BivR8GI56SilNFWJJh%2BllKZq9CY9ti%2BsUdvRYzblNLQcVj3SRl0SuSbRVm3vRtqV%2BURv0QiEQLX00BvajHXFJBX%2FAkyRIfvIeE880%2BQPPc1FvuPsVEV7ZBsrdhLvo5k30W3HJ%2FnpwwQ6HW6LCSqCmbgBJ7bE1hlKvVYk98xICwsckQA%3D%3D",
    err: "Альфа-Банк входит в холдинг Консорциум Альфа-Групп"
  },
  {
    id: 6,
    q:
      "Где открылись первые два международных офиса компании «Тройка Диалог» в 1991 году?",
    a: [
      "Нью-Йорк и Лондон",
      "Гонконг и Сингапур",
      "Сан-Франциско и Токио",
      "Женева и Шанхай"
    ],
    right: 0,
    image:
      "https://storage.googleapis.com/alafia-tech.appspot.com/images/51442b4.jpg?GoogleAccessId=firebase-adminsdk-kocka%40alafia-tech.iam.gserviceaccount.com&Expires=16731014400&Signature=NKkjZllBddeDPhPwGgJtneIOLU8hnffUuY6rllb8wJe%2Bbl1DhOnBY%2FZBrRgq8AI552sRQyOZIsVNbO4RLj%2FcC49r4IWt%2BHDM8xUZROBkxKFFZQd2%2Fj94WCDUkd4k8G9HE%2F3frCnnW0kOBiVUt5y1tTBwusgrSgpva70pWhR5ugMGHAglJtbNoj5aktk6aiOsIiTLxfjierrohTIlOyuqqbUbDJ0uOngRTIhR0qOltOZXdhoITDt3mtNVEIQCTbh531UrYkZ6zeE2mN9TBV%2FCnHA%2BErM28dtXAZePoC%2BXwwOiuDHJMB%2BEp%2FKwBlnCtFyRyrUkp3qoPkTvGZBTt2i05Q%3D%3D",
    err: 'Первые 2 офиса "Тройки Диалог" открылись в Нью-Йорке и Лондоне'
  },
  {
    id: 7,
    q: "Как зовут супругу Михаила Куснировича?",
    a: [
      "Ида Бернгольц",
      "Ирина Ашкоянц",
      "Надежда Рабинович",
      "Екатерина Моисеева"
    ],
    right: 3,
    image:
      "https://storage.googleapis.com/alafia-tech.appspot.com/images/2da390b.jpg?GoogleAccessId=firebase-adminsdk-kocka%40alafia-tech.iam.gserviceaccount.com&Expires=16731014400&Signature=XX3hkIFzmJk%2FlIm%2FWk%2FPivNX0JqZIgbL0jBj8OZbks3v%2FKgTqP5QlRQNlNn4Ut13niquv0kaZfYv%2FXNHgBsCg2M3V8F24Yrr5PhUbyHQBouwwFcnifhXldRCvRLJz9FjI1atU%2B8ycc1wwJjFrDKUThT0sdDDe7xqH5X5PxkZLCeZr2QOwtqW2vawJ4CYF7EdCVISP7bFi46ZN4lSecpmE62v9DMhKgI9J%2FeZLt0%2F%2BQ5wCdcOTXE9lS7NP5I%2FSKpauQjbpuMQx9c3cVfPqkS0cNEtATYb88CF837CuKyi4Bl31DBn%2FbKJgzsZQpnNvfWdgtwHzyfp4oeYyH10sBvntA%3D%3D",
    err: "Супруга Михаила зовут Екатерина Моисеева"
  },
  {
    id: 8,
    q: "Какое из указанных хобби относится к Михаилу Фридману?",
    a: [
      "Коллекционирование раритетных автомобилей",
      "Каслинское чугунное литье",
      "Коллекционирование раритетных музыкальных инструментов",
      "Коллекционирование самурайских мечей"
    ],
    right: 3,
    image:
      "https://storage.googleapis.com/alafia-tech.appspot.com/images/12df68b.jpg?GoogleAccessId=firebase-adminsdk-kocka%40alafia-tech.iam.gserviceaccount.com&Expires=16731014400&Signature=WHqaItae%2BYph5ztZXdi7I%2BX8g6mTtNDqqEc3N0p%2BMfaKccYi7Yioore3jllBCYK1yB6FDzCFZjZMhHwXsBNpQ8D2eKhYxTWEGLJvIsFdGWLuJYvK4nHtLSj16gbbnOMhqXAgBYqIb0agwsoEsK1bh96W7Qr0J6Nsac5Vd2162byPLtLZXHOKvhv0pOGLb7h1rEhSdY5UeeehDXYSIGTZDIppp1jgKY0jWCO3Iyr4ZqBsy%2FqH5pMFHTlC%2BQQavVNS6CBK2jdBGDxywYRiEVLbnEVfLHK5vd3Hn38fdDp3uEyqFLjx5ReLyxTiR4%2BhFjOjgqcDnJ6MURi%2BS6e9cQ8EDQ%3D%3D",
    err: "Михаил увлекается коллекционированием самурайских мечей"
  },

  {
    id: 9,
    q: "Сколько детей у Романа Абрамовича?",
    a: ["3", "5", "7", "8"],
    right: 2,
    image:
      "https://storage.googleapis.com/alafia-tech.appspot.com/images/4c31ce2.jpg?GoogleAccessId=firebase-adminsdk-kocka%40alafia-tech.iam.gserviceaccount.com&Expires=16731014400&Signature=M6TIPfs5v8nede5gXPtWaE%2FaJ0ZC0AC6UZcRj1wsXjlqbDvxvL4qDegM9Y7FzoA4XulANnA5ZUWk15yth5lPRSiPvlAhtUXSJKkzpwYO3HQ%2B0wCh4Pk3WZR0yt20BD61fzFdxf1s6qQr9PhLWVkF8gGVM9sPwyt7CA2JWDhhZ583OdcSAvIGSwmBcy88%2BiALmMTjiIg%2B9BHqQhq1%2FZIBquQLfpiocE8naW05UyPXGvao2%2FL1TrT1E6bQYsubFUTm3lQDCApij%2FgHvibE5aHDVedtIxphq65DTKF8QjcUBLi9S26eJ4BXBR0ZW6Bq6Lfbj%2FGdQQaWyML1WKN2cEVoSw%3D%3D",
    err: "У Романа 7 детей"
  },
  {
    id: 10,
    q: "В какой республике России родился Михаил Михельсон?",
    a: ["Дагестан", "Адыгея", "Кабардино-Балкария", "Ингушетия"],
    right: 0,
    image:
      "https://storage.googleapis.com/alafia-tech.appspot.com/images/468dd96.jpg?GoogleAccessId=firebase-adminsdk-kocka%40alafia-tech.iam.gserviceaccount.com&Expires=16731014400&Signature=P%2FxcERs3L1k0wn469YWVkEZ71v0%2BdMVuB5Arp7ml6rSoxrB60M1iAuO1Hms%2Bt38aw5zMOwIAptOTisr62Ppe9WL64eI1xbjAkNpWmvfYWkGPOlGzkwzmjwuBK0qBlo193Crk6hR6vK3MIJ80XrgZZ2y3VlSbGiA21ytVH6bKgL732ZOcn4VPbBQip6VPfHIqZpmbp6aKZ0pSlJGVk6cV%2Bp1g3pWVP6zIjxesRtwgu%2FHkpJfpGl%2FQu3bxz7dnM5gzQDrXXftTmhOlsrhk2YC1BZA%2BfYxpBY7cHQIp4vFhw8kT926WdmxNFHXIHsOuZAsaNoIoJAYYzI5RA3RTw60Ppg%3D%3D",
    err: "Леонид родился в Дагестане"
  }
];

const RadarGames = () => {
  const [question, setQuestion] = useState(-1);
  const [right, setRight] = useState(0);
  const [result, setResult] = useState(-1);
  const [final, setFinal] = useState(-1);
  const [click, setClick] = useState(-1);

  const handleAnswer = e => {
    if (result === -1) {
      //console.log(e.target.name);
      const id = parseInt(e.target.name, 10);
      setClick(id);
      if (quiz[question].right === id) {
        setRight(r => r + 1);
        setResult(1);
      } else {
        setResult(0);
      }
    }
  };

  const handleNext = () => {
    setResult(-1);
    setClick(-1);
    setQuestion(q => q + 1);
  };

  const restartGame = () => {
    setResult(-1);
    setClick(-1);
    setQuestion(0);
    setFinal(-1);
  };

  const stopGame = () => {
    setResult(-1);
    setClick(-1);
    setQuestion(10);
  };

  return (
    <>
      <ControlTab />
      <Container>
        <TextBlock>
          <b>Игра 1. КАРТОЧКИ КОНТАКТОВ</b>
        </TextBlock>
        <TextBlockTop>
          Давайте сыграем в игру, которая поможет Вам лучше узнать Ваших
          партнёров, а также потренировать память
        </TextBlockTop>
        {question === -1 && (
          <Button
            color="primary"
            variant="contained"
            onClick={() => setQuestion(0)}
          >
            Начать игру
          </Button>
        )}

        {question >= 0 && question < 10 && (
          <Button
            color="primary"
            variant="contained"
            onClick={stopGame}
            style={{ marginBottom: "20px" }}
          >
            Остановить игру
          </Button>
        )}

        {question >= 0 && question < 10 && (
          <QuestionBlock>
            <TextBlock>
              <QText>{`Вопрос ${quiz[question].id}.`}</QText>{" "}
              {`${quiz[question].q}`}
            </TextBlock>
            <FlexBlock>
              <ImageBlock>
                <img src={quiz[question].image} alt="question" />
              </ImageBlock>
              <AnswerBlock>
                <TextBlock>Выберите правильный вариант:</TextBlock>
                {quiz[question].a.map((el, idx) => {
                  return (
                    <div key={idx}>
                      {/*  <Button
                        onClick={handleAnswer(idx)}
                        disabled={result >= 0}
                      >
                        {el}
                      </Button>*/}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              click === idx ||
                              (result >= 0 && quiz[question].right === idx)
                            }
                            onChange={handleAnswer}
                            name={idx.toString()}
                            color={
                              idx === click && quiz[question].right === idx
                                ? "primary"
                                : quiz[question].right === idx
                                ? "primary"
                                : "secondary"
                            }
                            //disabled={result >= 0}
                          />
                        }
                        label={el}
                      />
                    </div>
                  );
                })}
              </AnswerBlock>
            </FlexBlock>
          </QuestionBlock>
        )}
        {result === 0 && (
          <>
            <WrongAnswer>
              Неправильный ответ{" "}
              <span role="img" aria-label="badsmile">
                ☹
              </span>
            </WrongAnswer>
            <WrongAnswer>{quiz[question].err}</WrongAnswer>
          </>
        )}
        {result === 1 && (
          <>
            <RightAnswer>
              Правильный ответ{" "}
              <span role="img" aria-label="smile">
                😀
              </span>
            </RightAnswer>
          </>
        )}

        {result >= 0 && (
          <Button color="primary" variant="contained" onClick={handleNext}>
            К следующему вопросу
          </Button>
        )}

        {question >= 10 && (
          <>
            <TextBlock>
              Ваш результат: {right} правильных ответов из 10 возможных!
              Неплохо, но есть к чему стремиться!
            </TextBlock>
            <TextBlock>
              Вы играли с карточками Ваших контактов, отмеченных «Партнеры».
              Хотите продолжить игру с теми, кто в Вашем Human Radar отмечены
              как «Друзья»?
            </TextBlock>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
                marginBottom: "20px",
                width: "300px"
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => setFinal(0)}
              >
                Да, хочу
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setFinal(1)}
              >
                Нет, спасибо
              </Button>
            </div>
          </>
        )}

        {final === 0 && (
          <>
            <TextBlock>
              Понравилось, да?{" "}
              <span role="img" aria-label="smile">
                😀
              </span>
            </TextBlock>
            <TextBlock>
              Эту игру мы придумали по аналогии с тем, как многие из нас в
              детстве учили слова на иностранных языках! Мы уверены, что без
              геймификации – никуда, а для управления своим нетворком игры
              станут той фишкой, которая поможет пользователям лучше знать тех
              людей, с которыми они связаны!
            </TextBlock>
            <TextBlock>
              К сожалению, раздел Radar Games находится в стадии проектирования,
              и других игр пока нет{" "}
              <span role="img" aria-label="badsmile">
                ☹
              </span>{" "}
              Но у нас уже есть roadmap из нескольких игр, которые помогут
              пользователям лучше знать свой нетворк, а также тренировать
              память.
            </TextBlock>
            <TextBlock>
              После привлечения финансирования мы запустим несколько игр в
              рамках разработки мобильного приложения Human Radar.
            </TextBlock>
          </>
        )}

        {final === 1 && (
          <>
            <TextBlock>
              Спасибо! Раздел Radar Games находится в стадии проектирования, и
              других игр пока нет{" "}
              <span role="img" aria-label="badsmile">
                ☹
              </span>{" "}
              Но у нас уже есть roadmap из нескольких игр, которые помогут
              пользователям лучше знать свой нетворк, а также тренировать
              память.
            </TextBlock>
            <TextBlock>
              После привлечения финансирования мы запустим несколько игр в
              рамках разработки мобильного приложения Human Radar.
            </TextBlock>
          </>
        )}
        {final >= 0 && (
          <Button color="primary" variant="contained" onClick={restartGame}>
            Начать игру заново
          </Button>
        )}
      </Container>
    </>
  );
};

export default RadarGames;
