import React, { memo } from "react";
import { connect } from "react-redux";
import { Container, InfoLine, Label } from "./index.style";
import { changeFormValue } from "../../actions/form";
import TextField from "@material-ui/core/TextField";

const ExtraActivityBlock = ({
  editMode,
  changeFormValue,
  addactivity1_activity,
  addactivity1_about,
  addactivity1_position,
  addactivity2_activity,
  addactivity2_about,
  addactivity2_position
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Container>
      <Label>Другая деятельность</Label>
      <InfoLine>
        <TextField
          id="addactivity1_activity"
          label="Activity 1"
          value={addactivity1_activity}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          style={{ marginRight: "15px" }}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="addactivity1_about"
          label="About"
          value={addactivity1_about}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="addactivity1_position"
          label="Position"
          value={addactivity1_position}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />

        <TextField
          id="addactivity2_activity"
          label="Activity 2"
          value={addactivity2_activity}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          style={{ marginRight: "15px" }}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="addactivity2_about"
          label="About"
          value={addactivity2_about}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="addactivity2_position"
          label="Position"
          value={addactivity2_position}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    addactivity1_activity: state.form.addactivity1_activity,
    addactivity1_about: state.form.addactivity1_about,
    addactivity1_position: state.form.addactivity1_position,
    addactivity2_activity: state.form.addactivity2_activity,
    addactivity2_about: state.form.addactivity2_about,
    addactivity2_position: state.form.addactivity2_position
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(ExtraActivityBlock)
);
