import React, { memo } from "react";
import { connect } from "react-redux";
import {
  Container,
  InfoLine,
  Label,
  LineBlock,
  FlexBlock
} from "./index.style";
import { changeFormValue } from "../../actions/form";
import TextField from "@material-ui/core/TextField";

const ExtConnectionsBlock = ({
  extlinks1,
  extlinks2,
  extlinks3,
  extlinks4,
  extlinks5,
  extlinks6,
  extlinksdesc1,
  extlinksdesc2,
  extlinksdesc3,
  extlinksdesc4,
  extlinksdesc5,
  extlinksdesc6,
  editMode,
  changeFormValue
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Container>
      <Label>Внешние связи</Label>
      <InfoLine>
        <LineBlock>
          <FlexBlock>
            <TextField
              id="extlinks1"
              label="Name 1"
              value={extlinks1}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              style={{ marginRight: "20px" }}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              id="extlinksdesc1"
              label="Details"
              value={extlinksdesc1}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
          </FlexBlock>
          <FlexBlock>
            <TextField
              id="extlinks4"
              label="Name 4"
              value={extlinks4}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              style={{ marginRight: "20px" }}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              id="extlinksdesc4"
              label="Details"
              value={extlinksdesc4}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
          </FlexBlock>
        </LineBlock>
        <LineBlock>
          <FlexBlock>
            <TextField
              id="extlinks2"
              label="Name 2"
              value={extlinks2}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              style={{ marginRight: "20px" }}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              id="extlinksdesc2"
              label="Details"
              value={extlinksdesc2}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
          </FlexBlock>
          <FlexBlock>
            <TextField
              id="extlinks5"
              label="Name 5"
              value={extlinks5}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              style={{ marginRight: "20px" }}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              id="extlinksdesc5"
              label="Details"
              value={extlinksdesc5}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
          </FlexBlock>
        </LineBlock>
        <LineBlock noRightBorder>
          <FlexBlock>
            <TextField
              id="extlinks3"
              label="Name 3"
              value={extlinks3}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              style={{ marginRight: "20px" }}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              id="extlinksdesc3"
              label="Details"
              value={extlinksdesc3}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
          </FlexBlock>
          <FlexBlock>
            <TextField
              id="extlinks6"
              label="Name 6"
              value={extlinks6}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              style={{ marginRight: "20px" }}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              id="extlinksdesc6"
              label="Details"
              value={extlinksdesc6}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
          </FlexBlock>
        </LineBlock>
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    extlinks1: state.form.extlinks1,
    extlinks2: state.form.extlinks2,
    extlinks3: state.form.extlinks3,
    extlinks4: state.form.extlinks4,
    extlinks5: state.form.extlinks5,
    extlinks6: state.form.extlinks6,
    extlinksdesc1: state.form.extlinksdesc1,
    extlinksdesc2: state.form.extlinksdesc2,
    extlinksdesc3: state.form.extlinksdesc3,
    extlinksdesc4: state.form.extlinksdesc4,
    extlinksdesc5: state.form.extlinksdesc5,
    extlinksdesc6: state.form.extlinksdesc6
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(ExtConnectionsBlock)
);
