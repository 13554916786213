import React, { memo } from "react";
import { connect } from "react-redux";
import { Container, InfoLine, Label, LineBlock } from "./index.style";
import { changeFormValue } from "../../actions/form";
import TextField from "@material-ui/core/TextField";

const LangBlock = ({
  editMode,
  changeFormValue,
  language1,
  language2,
  language3,
  language4,
  level1,
  level2,
  level3,
  level4
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Container>
      <Label>Языки</Label>
      <InfoLine>
        <LineBlock>
          <TextField
            id="language1"
            label="Language 1"
            value={language1}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            style={{ marginRight: "15px" }}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="level1"
            label="Level"
            value={level1}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
        </LineBlock>
        <LineBlock>
          <TextField
            id="language2"
            label="Language 2"
            value={language2}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            style={{ marginRight: "15px" }}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="level2"
            label="Level"
            value={level2}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
        </LineBlock>
        <LineBlock>
          <TextField
            id="language3"
            label="Language 3"
            value={language3}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            style={{ marginRight: "15px" }}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="level3"
            label="Level"
            value={level3}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
        </LineBlock>
        <LineBlock noRightBorder>
          <TextField
            id="language4"
            label="Language 4"
            value={language4}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            style={{ marginRight: "15px" }}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="level4"
            label="Level"
            value={level4}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
        </LineBlock>
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    language1: state.form.language1,
    language2: state.form.language2,
    language3: state.form.language3,
    language4: state.form.language4,
    level1: state.form.level1,
    level2: state.form.level2,
    level3: state.form.level3,
    level4: state.form.level4
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(LangBlock));
