import React from "react";
import Cytoscape from "cytoscape";
import CytoscapeReact from "react-cytoscapejs";
import fcose from "cytoscape-fcose";
import ControlTab from "../ControlTab";
import {
  TextBlock,
  TextBlockBold,
  Container,
  TextSideBlock
} from "./index.style";

Cytoscape.use(fcose);

const layout = {
  name: "fcose",
  quality: "default",
  randomize: true,
  animate: true,
  animationDuration: 1000,
  animationEasing: undefined,
  fit: true,
  padding: 10,
  nodeDimensionsIncludeLabels: false,
  uniformNodeDimensions: false,
  packComponents: true,
  samplingType: true,
  sampleSize: 25,
  nodeSeparation: 125,
  piTol: 0.0000001,
  nodeRepulsion: 4500,
  idealEdgeLength: 50,
  edgeElasticity: 0.45,
  nestingFactor: 0.1,
  numIter: 2500,
  tile: true,
  tilingPaddingVertical: 10,
  tilingPaddingHorizontal: 10,
  gravity: 0.25,
  gravityRangeCompound: 1.5,
  gravityCompound: 1.0,
  gravityRange: 3.8,
  initialEnergyOnIncremental: 0.3
};

const states = [
  "AK",
  "AL",
  "AR",
  "AS",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "GU",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VI",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY"
];

const nodes = states.map((e, idx) => ({
  data: { id: idx, label: e },
  position: { x: 0, y: 0 }
}));

// const links = nodes
//   .map((_, idx) => ({ data: { source: "max", target: idx } }))
//   .filter((_, idx) => idx % 2);
//
// const secondLinks = nodes
//   .filter((_, idx) => !(idx % 2))
//   .map(e => {
//     return {
//       data: {
//         source: e.data.id,
//         target: Math.floor(Math.random() * nodes.length)
//       }
//     };
//   });

let counter = 0;
let currentIdx = 0;
const test = nodes.map(({ data: { id } }) => {
  if (!(id % 6)) {
    return { data: { source: "max", target: id } };
  }
  counter++;
  if (counter === 4) {
    currentIdx++;
    counter = 0;
  }
  return { data: { source: currentIdx, target: id } };
});

const elements = [
  // { data: { id: "one", label: "Node 1" }, position: { x: 0, y: 0 } },
  // { data: { id: "two", label: "Node 2" }, position: { x: 100, y: 0 } },
  {
    data: { id: "max", label: "Max", color: "#f44336", padding: "8px" },
    position: { x: 0, y: 0 }
  },
  ...nodes,
  // ...links,
  // ...secondLinks
  ...test,
  { data: { source: 0, target: 19 } },
  { data: { source: 1, target: 15 } },
  { data: { source: 1, target: 21 } },
  { data: { source: "max", target: 1 } },
  { data: { source: "max", target: 13 } },
  { data: { source: "max", target: 9 } },
  { data: { source: "max", target: 23 } }
];

const RadarConnections = () => {
  //useEffect(() => {
  //   const nodes = genNodes(data.nodes);
  //   const edges = genLinks(data.links);
  //   setElements(CytoscapeReact.normalizeElements({ nodes, edges }));
  // }, [data]);

  return (
    <>
      <ControlTab />

      <Container>
        <div style={{ width: "800px" }}>
          <CytoscapeReact
            elements={elements}
            style={{ width: "800px", height: "600px" }}
            stylesheet={[
              {
                selector: "node",
                style: {
                  label: "data(label)",
                  color: "white",
                  "text-valign": "center",
                  "text-halign": "center",
                  "font-size": "12px",
                  "font-family": "Courier new",
                  shape: "data(shape)",
                  //'background-color': 'mapData(inDegree, 1, 8, rgba(163, 154, 164), rgba(240, 146, 60))',
                  //'background-opacity': 'mapData(inDegree, 1, 8, .3, 1)',
                  "border-color": "transparent",
                  "background-color": "data(color)",
                  width: "data(width)",
                  height: "data(height)",
                  padding: "data(padding)"
                }
              },
              {
                selector: "edge",
                style: {
                  //"curve-style": "bezier",
                  // "target-arrow-shape": "triangle",
                  // "target-arrow-fill": "hollow",
                  // "target-arrow-color": "#2C2029",
                  width: "1px",
                  color: "#2C2029"
                }
              }
            ]}
            layout={layout}
            //cy={x => setCy(x)}
            minZoom={0.5}
            maxZoom={2}
            wheelSensitivity={0.1}
          />
        </div>

        <TextSideBlock>
          <TextBlockBold>
            Данный раздел находится в стадии проектирования. Мы сможем
            приступить к его разработке после привлечения финансирования.
          </TextBlockBold>
          <TextBlock>
            Смысл функции Radar Connections заключается в том, чтобы графически
            отобразить все связи между людьми в Вашем нетворке, которые Вы
            отмечаете в разделе <b>Связи в Radar</b> в карточке контакта. Такой
            способ визуализации удобен для того, чтобы понять, кто в ваших
            кругах замыкает на себе большое количество связей; а также полезен
            для таких действий как определение наиболее оптимального «выхода» на
            нужного человека, сбор информации, подбор opinion makers, пр.
          </TextBlock>
          <TextBlock>
            Ha этой схеме вы видите реальную визуализацию профессиональных
            связей Максима Фельдмана в Израиле. В доработанной версии при клике
            на каждое звено схемы будет открываться карточка соответствующего
            контакта, где [помимо прочей информации] будет возможность отмечать
            подробности про тип связи между людьми (деловые, личные, дружеские,
            пр.) – то есть линии между звеньями можно будет «покрасить» разными
            цветами для большей наглядности.
          </TextBlock>
          <TextBlock>
            Данная диаграмма будет формироваться автоматически, то есть связи
            будут добавляться/удаляться динамически по мере работы с разделом
            <b> Связи в Radar</b> в карточках Ваших контактов. А сами звенья
            диаграммы уже можно перемещать для отображения общей «картинки» в
            такой форме, которая удобна пользователю.
          </TextBlock>
        </TextSideBlock>
      </Container>
    </>
  );
};

export default RadarConnections;
