import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import { compose } from "redux";
import Button from "@material-ui/core/Button";
import moment from "moment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import DeleteIcon from "@material-ui/icons/Delete";
import ExportIcon from "@material-ui/icons/Input";
import "moment/locale/ru";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { deleteDataFirestore } from "../../actions/firestoreActions";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    minWidth: 650
  }
}));

const TableView = ({
  fullList,
  rows,
  firestore,
  deleteDataFirestore,
  auth,
  handleOpenCard,
  db,
  noNotes,
  demo
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected([]);
  }, [fullList]);

  const connect = (...args) => {
    return args.filter(e => e).join(", ");
  };

  const getDate = date => {
    if (date) {
      return moment(new Date(date))
        .locale("ru")
        .format("L");
    }
    return;
  };

  const isSelected = id => {
    return selected.includes(id);
  };

  const handleSelect = id => {
    if (!selected.includes(id)) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter(el => el !== id));
    }
  };

  const selectAll = () => {
    console.log(fullList);
    if (selected.length === fullList.length) {
      setSelected([]);
    } else {
      setSelected(fullList.map(el => el.id));
    }
  };

  const handleDelete = () => {
    if (window.confirm("Вы точно хотите удалить выбранные карточки?")) {
      selected.forEach(el => {
        deleteDataFirestore(firestore, el, auth.uid, db);
      });
    }
    setSelected([]);
  };

  const handleClick = id => () => {
    handleOpenCard(id);
  };

  const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }

    return buf;
  };

  const json2excel = opts => {
    let { data = [], name = "excel", formateDate = "dd.mm.yyyy" } = opts;

    let fileNames = [];
    let myHeader = [
      "surname",
      "name",
      "patronymic",
      "birthdate",
      "city",
      "country",
      "geo1",
      "geo2",
      "profile1",
      "profile2",
      //"company",
      // "position1",
      // "position2",
      // "mobtel",
      // "officetel",
      "tel1",
      "tel2",
      "email1",
      "email2",
      "cont_field1",
      "cont_field2",
      "cont_field3",
      "tag1",
      "tag2",
      "tag3",
      "tag4",
      "tag5",
      "tag6",
      "info1",
      "info2",
      "info3",
      "info4",
      "prof1_activity",
      "prof1_position1",
      "prof1_position2",
      "prof1_industry1",
      "prof1_industry2",
      "prof1_industry3",
      "prof2_activity",
      "prof2_position1",
      "prof2_position2",
      "prof2_industry1",
      "prof2_industry2",
      "prof2_industry3",
      "prof3_activity",
      "prof3_position1",
      "prof3_position2",
      "prof3_industry1",
      "prof3_industry2",
      "prof3_industry3",
      // "industry1",
      // "industry2",
      // "industry3",
      "company1",
      "company1_about",
      "company1_industry",
      "company1_position",
      "company1_years",
      "company2",
      "company2_about",
      "company2_industry",
      "company2_position",
      "company2_years",
      "company3",
      "company3_about",
      "company3_industry",
      "company3_position",
      "company3_years",
      "company4",
      "company4_about",
      "company4_industry",
      "company4_position",
      "company4_years",
      "company5",
      "company5_about",
      "company5_industry",
      "company5_position",
      "company5_years",
      "company6",
      "company6_about",
      "company6_industry",
      "company6_position",
      "company6_years",
      "professional1",
      "professional2",
      "professional3",
      "professional4",
      "professional5",
      "professional6",
      "professional7",
      "professional8",
      "addactivity1_activity",
      "addactivity1_about",
      "addactivity1_position",
      "addactivity2_activity",
      "addactivity2_about",
      "addactivity2_position",

      "assistant",

      "spousename",
      "spouse_birthdate",
      "spouse_work",
      "spouse_details",
      "number_of_kids",
      "kid1",
      "kid1_birthdate",
      "kid2",
      "kid2_birthdate",
      "kid3",
      "kid3_birthdate",
      "kid4",
      "kid4_birthdate",

      "hobby1",
      "hobby2",
      "hobby3",
      "hobby4",
      "hobby5",
      "hobby6",

      "placeofbirth",
      "bio_lived1",
      "bio_lived2",
      "mother",
      "father",
      "siblings",
      "bio_addinfo",
      "childhoodact",
      // "details1",
      // "details2",
      "lifestory1",
      "lifestory2",
      "education1",
      "major1",
      "years1",
      "education1_addinfo",
      "education2",
      "major2",
      "years2",
      "education2_addinfo",
      "education3",
      "major3",
      "years3",
      "education3_addinfo",
      "language1",
      "level1",
      "language2",
      "level2",
      "language3",
      "level3",
      "language4",
      "level4",
      "links1",
      "linksdesc1",
      "links2",
      "linksdesc2",
      "links3",
      "linksdesc3",
      "links4",
      "linksdesc4",
      "links5",
      "linksdesc5",
      "links6",
      "linksdesc6",
      "connection1",
      "connectiondesc1",
      "connection2",
      "connectiondesc2",
      "connection3",
      "connectiondesc3",
      "connection4",
      "connectiondesc4",
      "connection5",
      "connectiondesc5",
      "connection6",
      "connectiondesc6",
      "extlinks1",
      "extlinksdesc1",
      "extlinks2",
      "extlinksdesc2",
      "extlinks3",
      "extlinksdesc3",
      "extlinks4",
      "extlinksdesc4",
      "extlinks5",
      "extlinksdesc5",
      "extlinks6",
      "extlinksdesc6",
      "interaction1",
      "interaction1_act1",
      "interaction1_act2",
      "interaction1_act3",
      "interaction1_date",
      "interaction1_location",
      "interaction1_notes",
      "interaction2",
      "interaction2_act1",
      "interaction2_act2",
      "interaction2_act3",
      "interaction2_date",
      "interaction2_location",
      "interaction2_notes",
      "interaction3",
      "interaction3_act1",
      "interaction3_act2",
      "interaction3_act3",
      "interaction3_date",
      "interaction3_location",
      "interaction3_notes",
      "notes"
    ];

    if (!demo) {
      myHeader = myHeader
        .filter(e => e !== "number_of_kids")
        .filter(e => {
          if (noNotes) {
            return e !== 'notes'
          } else {
            return true;
          }
        })
      data = data.map(e => {
        if (noNotes) {
          delete e.notes;
        }
        delete e.kids;
        return e;
      });
    }
    if (demo) {
      data = data.map(e => {
        if (e.kids) {
          e["number_of_kids"] = e.kids;
          delete e.kids;
          return e;
        }
        delete e.kids;
        return e;
      });
    }
    console.log(data);
    let sheets = {};
    const ws = XLSX.utils.json_to_sheet(data, {
      dateNF: formateDate,
      header: myHeader
    });

    // add worksheet to workbook
    fileNames.push(name);
    sheets[name] = ws;

    const wb = {
      SheetNames: fileNames,
      Sheets: sheets
    };
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary"
    });

    FileSaver.saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      name + ".xlsx"
    );
  };

  const checkAuth = () => {
    return false;
    //return demo;
    // const users = [
    //   "NWwiEfvB0fTw0XAb0i9Iro4KQJo2",
    //   "jaa0aXfljybIHWeZoOpcOr7kUgy2"
    // ];
    // if (users.includes(auth.uid)) {
    //   return false;
    // }
    // return true;
  };

  const handleExport = () => {
    let data;
    if (selected.length === 0) {
      data = [...fullList];
    } else {
      data = fullList.filter(el => selected.includes(el.id));
    }
    data = data.map(row => {
      let el = { ...row };
      delete el.fast;
      delete el.file1;
      delete el.file2;
      delete el.file3;
      delete el.file4;
      delete el.file5;
      delete el.file6;
      delete el.file7;
      delete el.file8;
      delete el.file9;
      delete el.file10;
      delete el.interaction1_file1;
      delete el.interaction1_file2;
      delete el.interaction1_file3;
      delete el.interaction2_file1;
      delete el.interaction2_file2;
      delete el.interaction2_file3;
      delete el.interaction3_file1;
      delete el.interaction3_file2;
      delete el.interaction3_file3;
      delete el.foto;
      delete el.id;
      delete el.images;
      delete el.company7;
      delete el.company8;
      delete el.editorId;
      delete el.lat;
      delete el.lng;
      delete el.company;
      delete el.position1;
      delete el.position2;
      delete el.industry1;
      delete el.industry2;
      delete el.industry3;
      //delete el.kids;
      delete el.mobtel;
      delete el.officetel;
      delete el.details1;
      delete el.details2;
      delete el.migrated;

      if (!el.birthdate) {
        el.birthdate = "";
      } else if (el.birthdate.constructor.name === "Timestamp") {
        el.birthdate = moment(el.birthdate.toDate()).format("YYYY-MM-DD");
      }
      if (el.birthdate) {
        el.birthdate = moment(el.birthdate).format("DD.MM.YYYY");
      }
      return el;
    });
    try {
      console.log(data);
      json2excel({
        data,
        name: "export",
        formateDate: "dd.mm.yyyy"
      });
    } catch (e) {
      console.error("export error");
    }
    setSelected([]);
  };

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                checked={selected.length === fullList.length}
                onChange={selectAll}
              />
            </TableCell>
            <TableCell>Имя</TableCell>
            <TableCell align="right">Фамилия</TableCell>
            <TableCell align="right">Отчество</TableCell>
            <TableCell align="right">Тэги</TableCell>
            <TableCell align="right">Область деятельности</TableCell>
            <TableCell align="right">Дата рождения</TableCell>
            <TableCell align="right">Страна</TableCell>
            <TableCell align="right">Город</TableCell>
            <TableCell align="right">Адрес</TableCell>
            <TableCell align="right">Область</TableCell>
            <TableCell align="right">Компания</TableCell>
            <TableCell align="right">Должность</TableCell>
            <TableCell align="right">Мобильный телефон</TableCell>
            <TableCell align="right">Рабочий телефон</TableCell>
            <TableCell align="right">Емейл</TableCell>
            <TableCell align="right">Помощник</TableCell>
            <TableCell align="right">Супруг(а)</TableCell>
            <TableCell align="right">Кол-во детей</TableCell>
            <TableCell align="right">Хобби</TableCell>
            <TableCell align="right">Инфо</TableCell>

            <TableCell align="right">Place of birth</TableCell>
            <TableCell align="right">Mother</TableCell>
            <TableCell align="right">Father</TableCell>
            <TableCell align="right">Siblings</TableCell>
            <TableCell align="right">Childhood activities</TableCell>
            <TableCell align="right">Details 1</TableCell>
            <TableCell align="right">Details 2</TableCell>
            <TableCell align="right">Education 1</TableCell>
            <TableCell align="right">Education 2</TableCell>
            <TableCell align="right">Education 3</TableCell>
            <TableCell align="right">Career</TableCell>
            <TableCell align="right">Professional</TableCell>
            <TableCell align="right">Languages</TableCell>
            <TableCell align="right">Link 1</TableCell>
            <TableCell align="right">Link 2</TableCell>
            <TableCell align="right">Link 3</TableCell>
            <TableCell align="right">Link 4</TableCell>
            <TableCell align="right">Link 5</TableCell>
            <TableCell align="right">Connection 1</TableCell>
            <TableCell align="right">Connection 2</TableCell>
            <TableCell align="right">Connection 3</TableCell>
            <TableCell align="right">Connection 4</TableCell>
            <TableCell align="right">Connection 5</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow
              key={row.id}
              onClick={handleClick(row.id)}
              style={{ cursor: "pointer" }}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isSelected(row.id)}
                  onClick={e => {
                    e.stopPropagation();
                    handleSelect(row.id);
                  }}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.surname}</TableCell>
              <TableCell align="right">{row.patronymic}</TableCell>
              <TableCell align="right">
                {connect(row.tag1, row.tag2, row.tag3, row.tag4, row.tag5)}
              </TableCell>
              <TableCell align="right">
                {connect(row.industry1, row.industry2, row.industry3)}
              </TableCell>
              <TableCell align="right">{getDate(row.birthdate)}</TableCell>
              <TableCell align="right">{row.country}</TableCell>
              <TableCell align="right">{row.city}</TableCell>
              <TableCell align="right">{row.geo1}</TableCell>
              <TableCell align="right">{row.geo2}</TableCell>
              <TableCell align="right">{row.prof1_activity}</TableCell>
              <TableCell align="right">
                {row.prof1_position1}
              </TableCell>
              <TableCell align="right">{row.tel1}</TableCell>
              <TableCell align="right">{row.tel2}</TableCell>
              <TableCell align="right">
                {connect(row.email1, row.email2)}
              </TableCell>
              <TableCell align="right">{row.assistant}</TableCell>
              <TableCell align="right">{row.spousename}</TableCell>
              <TableCell align="right">{row.kids}</TableCell>
              <TableCell align="right">
                {connect(
                  row.hobby1,
                  row.hobby2,
                  row.hobby3,
                  row.hobby4,
                  row.hobby5
                )}
              </TableCell>
              <TableCell align="right">
                {connect(row.info1, row.info2)}
              </TableCell>

              <TableCell align="right">{row.placeofbirth}</TableCell>

              <TableCell align="right">{row.mother}</TableCell>

              <TableCell align="right">{row.father}</TableCell>

              <TableCell align="right">{row.siblings}</TableCell>

              <TableCell align="right">{row.childhoodact}</TableCell>

              <TableCell align="right">{row.details1}</TableCell>

              <TableCell align="right">{row.details2}</TableCell>

              <TableCell align="right">
                {connect(row.education1, row.major1, row.years1)}
              </TableCell>

              <TableCell align="right">
                {connect(row.education2, row.major2, row.years2)}
              </TableCell>

              <TableCell align="right">
                {connect(row.education3, row.major3, row.years3)}
              </TableCell>

              <TableCell align="right">
                {connect(
                  row.company1,
                  row.company2,
                  row.company3,
                  row.company4,
                  row.company5,
                  row.company6
                )}
              </TableCell>

              <TableCell align="right">
                {connect(
                  row.professional1,
                  row.professional2,
                  row.professional3,
                  row.professional4,
                  row.professional5,
                  row.professional6
                )}
              </TableCell>
              <TableCell align="right">
                {connect(
                  row.language1,
                  row.level1,
                  row.language2,
                  row.level2,
                  row.language3,
                  row.level3,
                  row.language4,
                  row.level4
                )}
              </TableCell>
              <TableCell align="right">
                {connect(row.links1, row.linksdesc1)}
              </TableCell>
              <TableCell align="right">
                {connect(row.links2, row.linksdesc2)}
              </TableCell>
              <TableCell align="right">
                {connect(row.links3, row.linksdesc3)}
              </TableCell>
              <TableCell align="right">
                {connect(row.links4, row.linksdesc4)}
              </TableCell>
              <TableCell align="right">
                {connect(row.links5, row.linksdesc5)}
              </TableCell>
              <TableCell align="right">
                {connect(row.connection1, row.connectiondesc1)}
              </TableCell>
              <TableCell align="right">
                {connect(row.connection2, row.connectiondesc2)}
              </TableCell>
              <TableCell align="right">
                {connect(row.connection3, row.connectiondesc3)}
              </TableCell>
              <TableCell align="right">
                {connect(row.connection4, row.connectiondesc4)}
              </TableCell>
              <TableCell align="right">
                {connect(row.connection5, row.connectiondesc5)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        onClick={handleDelete}
        color="secondary"
        variant="contained"
        disabled={selected.length === 0 || checkAuth()}
      >
        <DeleteIcon />
      </Button>
      <Button
        style={{ backgroundColor: "#43A047", marginLeft: "15px" }}
        onClick={handleExport}
        variant="contained"
        disabled={checkAuth()}
      >
        <ExportIcon />
      </Button>
    </Paper>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    db: state.crud.userInfo.db,
    demo: state.crud.userInfo.demo,
    noNotes: state.crud.userInfo.nonotes
  };
};

const mapDispatchToProps = {
  deleteDataFirestore
};

const enhance = compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(TableView);
