import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Redirect } from "react-router-dom";
import { withFirebase } from "react-redux-firebase";
import logo from "../../assets/logo.svg";
import { ImageBlock, LoginBlock } from "./index.style";
import { login } from "../../actions/authActions";
const LoginPage = ({ login, auth, firebase, location }) => {
  const [data, setData] = useState({
    login: "",
    password: "",
    showPassword: false
  });
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const link =
      location && location.state && location.state.ref
        ? location.state.ref
        : null;
    if (auth.isLoaded && !auth.isEmpty) {
      setRedirect(
        <Redirect
          to={{
            pathname: "/",
            state: { ref: link }
          }}
        />
      );
    }
    //eslint-disable-next-line
  }, [auth, location]);

  const handleChange = e => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const handleClickShowPassword = () => {
    setData({ ...data, showPassword: !data.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleLogin = () => {
    let lgn = /@/.test(data.login) ? data.login : `${data.login}@humanradar.ru`;
    login({ login: lgn, pass: data.password }, firebase);
  };

  return (
    <LoginBlock>
      {redirect}
      <ImageBlock>
        <img src={logo} alt="logo" width={250} />
      </ImageBlock>
      <OutlinedInput
        id="login"
        placeholder="Логин"
        variant="outlined"
        onChange={handleChange}
        value={data.login}
      />
      <OutlinedInput
        id="password"
        placeholder="Пароль"
        variant="outlined"
        onChange={handleChange}
        value={data.password}
        type={data.showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {data.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <Button color="primary" variant="outlined" onClick={handleLogin}>
        Войти
      </Button>
    </LoginBlock>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = {
  login
};

const enhance = compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(LoginPage);
