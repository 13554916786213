import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFirestoreConnect, withFirestore } from "react-redux-firebase";
import axios from "axios";
import { compose } from "redux";
import { getStoreName } from "../../actions/crudActions";

const Header = ({ uid, db, firestore, getStoreName }) => {
  const [base, setBase] = useState([{ collection: "blank" }]);

  useEffect(
    () => {
      axios.get(
        `https://us-central1-alafia-tech.cloudfunctions.net/userLoginAction?uid=${uid}`
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (uid) {
      getStoreName(firestore, uid);
    }
    //eslint-disable-next-line
  }, [uid]);

  useEffect(() => {
    if (db) {
      //console.log([{ collection: db }]);
      setBase([{ collection: db }]);
    }
  }, [db]);

  useFirestoreConnect(base);

  return <div></div>;
};

const mapStateToProps = state => {
  return {
    uid: state.firebase.auth.uid,
    db: state.crud.userInfo.db
  };
};

const mapDispatchToProps = {
  getStoreName
};

const enhance = compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Header);
