import React from "react";

import {
  Container,
  TopText,
  Text,
  ImagesWrapper,
  BlockWrapper
} from "./index.style";
import logo from "../../assets/logo.svg";
import feldman from "../../assets/feldman.jpg";
import prokofiev from "../../assets/prokofiev.jpg";

const InfoPage = () => {
  return (
    <Container>
      <img src={logo} alt={logo} height={50} />
      <TopText>
        Технологический проект в области Relationship Intelligence Automation
        (RIA).
      </TopText>
      <Text>
        Проект призван решить проблему осознанного управления профессиональным и
        личным нетворком с использованием AI. Ведётся закрытая разработка.
        Проект будет открыт для сотрудничества с руководителями клубов,
        сообществ, объединений и ассоциаций.
      </Text>
      <ImagesWrapper>
        <BlockWrapper>
          <img
            src={feldman}
            alt="maxim feldman"
            height="300px"
            style={{ marginBottom: "10px" }}
          />
          <Text bold>Максим Фельдман</Text>
          <Text>Founder</Text>
        </BlockWrapper>
        <BlockWrapper>
          <img
            src={prokofiev}
            alt="alexey prokofiev"
            height="300px"
            style={{ marginBottom: "10px" }}
          />
          <Text bold>Алексей Прокофьев</Text>
          <Text>Co-founder</Text>
        </BlockWrapper>
      </ImagesWrapper>
      <Text blue mtop>
        +7 (906) 741-89-53 (whatsapp)
      </Text>
    </Container>
  );
};

export default InfoPage;
