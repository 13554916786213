import {
  FORM_CHANGE_VALUE,
  FORM_LOAD_DATA,
  FORM_LOAD_BLANK,
  FORM_LOAD_DATA_ERROR
} from "../constants/form";

const initialState = {
  name: "",
  images: [],
  surname: "",
  patronymic: "",
  tag1: "",
  tag2: "",
  tag3: "",
  tag4: "",
  tag5: "",
  tag6: "",
  industry1: "",
  industry2: "",
  industry3: "",
  birthdate: null,
  city: "",
  geo1: "",
  geo2: "",
  company: "",
  position1: "",
  position2: "",
  tel1: "",
  tel2: "",
  email1: "",
  email2: "",
  assistant: "",
  hobby1: "",
  hobby2: "",
  hobby3: "",
  hobby4: "",
  hobby5: "",
  spousename: "",
  kids: "",
  info1: "",
  info2: "",
  info3: "",
  info4: "",
  foto: "",
  file1: "",
  file2: "",
  file3: "",
  file4: "",
  file5: "",
  file6: "",
  file7: "",
  file8: "",
  file9: "",
  file10: "",
  notes: "",
  country: "",
  placeofbirth: "",
  mother: "",
  father: "",
  siblings: "",
  childhoodact: "",
  lifestory1: "",
  lifestory2: "",
  education1: "",
  major1: "",
  years1: "",
  education2: "",
  major2: "",
  years2: "",
  education3: "",
  major3: "",
  years3: "",
  company1: "",
  company2: "",
  company3: "",
  company4: "",
  company5: "",
  company6: "",
  professional1: "",
  professional2: "",
  professional3: "",
  professional4: "",
  professional5: "",
  professional6: "",
  language1: "",
  level1: "",
  language2: "",
  level2: "",
  language3: "",
  level3: "",
  language4: "",
  level4: "",
  links1: "",
  links2: "",
  links3: "",
  links4: "",
  links5: "",
  links6: "",
  linksdesc1: "",
  linksdesc2: "",
  linksdesc3: "",
  linksdesc4: "",
  linksdesc5: "",
  linksdesc6: "",
  connection1: "",
  connection2: "",
  connection3: "",
  connection4: "",
  connection5: "",
  connection6: "",
  connectiondesc1: "",
  connectiondesc2: "",
  connectiondesc3: "",
  connectiondesc4: "",
  connectiondesc5: "",
  connectiondesc6: "",
  profile1: "",
  profile2: "",
  cont_field1: "",
  cont_field2: "",
  cont_field3: "",
  prof1_activity: "",
  prof1_position1: "",
  prof1_position2: "",
  prof1_industry1: "",
  prof1_industry2: "",
  prof1_industry3: "",
  prof2_activity: "",
  prof2_position1: "",
  prof2_position2: "",
  prof2_industry1: "",
  prof2_industry2: "",
  prof2_industry3: "",
  prof3_activity: "",
  prof3_position1: "",
  prof3_position2: "",
  prof3_industry1: "",
  prof3_industry2: "",
  prof3_industry3: "",
  company1_about: "",
  company1_industry: "",
  company1_position: "",
  company1_years: "",
  company2_about: "",
  company2_industry: "",
  company2_position: "",
  company2_years: "",
  company3_about: "",
  company3_industry: "",
  company3_position: "",
  company3_years: "",
  company4_about: "",
  company4_industry: "",
  company4_position: "",
  company4_years: "",
  company5_about: "",
  company5_industry: "",
  company5_position: "",
  company5_years: "",
  company6_about: "",
  company6_industry: "",
  company6_position: "",
  company6_years: "",
  professional7: "",
  professional8: "",
  addactivity1_activity: "",
  addactivity1_about: "",
  addactivity1_position: "",
  addactivity2_activity: "",
  addactivity2_about: "",
  addactivity2_position: "",
  spouse_birthdate: "",
  spouse_work: "",
  spouse_details: "",
  kid1: "",
  kid1_birthdate: "",
  kid2: "",
  kid2_birthdate: "",
  kid3: "",
  kid3_birthdate: "",
  kid4: "",
  kid4_birthdate: "",
  hobby6: "",
  bio_lived1: "",
  bio_lived2: "",
  bio_addinfo: "",
  education1_addinfo: "",
  education2_addinfo: "",
  education3_addinfo: "",
  extlinks1: "",
  extlinks2: "",
  extlinks3: "",
  extlinks4: "",
  extlinks5: "",
  extlinks6: "",
  extlinksdesc1: "",
  extlinksdesc2: "",
  extlinksdesc3: "",
  extlinksdesc4: "",
  extlinksdesc5: "",
  extlinksdesc6: "",
  interaction1: "",
  interaction1_date: "",
  interaction1_location: "",
  interaction1_notes: "",
  interaction1_act1: "",
  interaction1_act2: "",
  interaction1_act3: "",
  interaction1_file1: "",
  interaction1_file2: "",
  interaction1_file3: "",
  interaction2: "",
  interaction2_date: "",
  interaction2_location: "",
  interaction2_notes: "",
  interaction2_act1: "",
  interaction2_act2: "",
  interaction2_act3: "",
  interaction2_file1: "",
  interaction2_file2: "",
  interaction2_file3: "",
  interaction3: "",
  interaction3_date: "",
  interaction3_location: "",
  interaction3_notes: "",
  interaction3_act1: "",
  interaction3_act2: "",
  interaction3_act3: "",
  interaction3_file1: "",
  interaction3_file2: "",
  interaction3_file3: "",
};

const formReducer = (
  state = initialState,
  { type, id, picid, value, data }
) => {
  switch (type) {
    case FORM_CHANGE_VALUE:
      if (id === "images" && picid) {
        return {
          ...state,
          images: [...state.images, { id: picid, source: value, desc: "" }]
        };
      } else if (id === "delimage") {
        return {
          ...state,
          images: state.images.filter(e => e.id !== value)
        };
      } else if (id === "photodesc") {
        return {
          ...state,
          images: state.images.map(e => {
            if (e.id !== picid) {
              return e;
            }
            return { ...e, desc: value };
          })
        };
      } else {
        return { ...state, [id]: value };
      }
    case FORM_LOAD_DATA:
      return { ...initialState, ...data };
    case FORM_LOAD_BLANK:
      return { ...initialState };
    case FORM_LOAD_DATA_ERROR:
      return { ...initialState, error: true };
    default:
      return state;
  }
};

export default formReducer;
