import React, { Component } from "react";
import LeftArrow from "@material-ui/icons/KeyboardArrowLeft";
import RightArrow from "@material-ui/icons/KeyboardArrowRight";
import {
  Carousel,
  DIcon,
  OneImage,
  ModalWrapper,
  ModalOverlay,
  ModalContent,
  ImageName,
  Container,
  ButtonBlock,
  MyRightArrow,
  MyLeftArrow,
  TextBlock
} from "./index.style";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";

class ImageCarousel extends Component {
  state = {
    showModal: false,
    selected: null
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyboard);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyboard);
  }

  handleDelete = e => {
    const { onDelete } = this.props;
    e.stopPropagation();
    const id = e.currentTarget.id;
    if (window.confirm("You want delete image?")) {
      onDelete(id);
    }
  };

  handleClick = e => {
    const id = e.currentTarget.id;

    this.setState({
      showModal: true,
      selId: id
    });
  };

  closeModal = () => {
    this.setState({ showModal: false, selId: undefined });
  };

  getName = link => {
    const encode = decodeURI(decodeURI(link));
    let result = encode.match(/uploads%2F\d*-(.*)\?/) || [];
    if (result.length > 1) {
      return result[1];
    }
    return;
  };

  handlePrev = () => {
    const { selId } = this.state;
    const { images } = this.props;
    const index = images.findIndex(e => e.id === selId);

    if (index === 0) {
      this.setState({
        selId: images[images.length - 1].id
      });
    } else {
      this.setState({
        selId: images[index - 1].id
      });
    }
  };

  handleNext = () => {
    const { selId } = this.state;
    const { images } = this.props;

    const index = images.findIndex(e => e.id === selId);
    if (index === images.length - 1) {
      this.setState({
        selId: images[0].id
      });
    } else {
      this.setState({
        selId: images[index + 1].id
      });
    }
  };

  handleKeyboard = e => {
    const { selId } = this.state;
    if (selId !== undefined) {
      if (e.key === "ArrowLeft") {
        this.handlePrev();
      }

      if (e.key === "ArrowRight") {
        this.handleNext();
      }

      if (e.key === "Escape") {
        this.closeModal();
      }
    }
  };

  render() {
    const { images, onUploadFile, onChange, editMode } = this.props;
    const { showModal, selId } = this.state;

    const selected = images.find(e => e.id === selId);
    return (
      <Container>
        <Carousel>
          {images &&
            images.length > 0 &&
            images.map(e => {
              return (
                <OneImage onClick={this.handleClick} key={e.id} id={e.id}>
                  <img src={e.source} width="100%" alt="Userfull view" />
                  <DIcon onClick={this.handleDelete} id={e.id} />
                </OneImage>
              );
            })}
        </Carousel>
        <ButtonBlock>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id={"images"}
            type="file"
            onChange={onUploadFile("images")}
            disabled={!editMode}
          />
          <label htmlFor={"images"}>
            <Button variant="contained" component="span" disabled={!editMode}>
              Загрузить
            </Button>
          </label>
        </ButtonBlock>
        <div>
          {showModal && (
            <ModalWrapper>
              <ModalOverlay onClick={this.closeModal} />
              <ModalContent>
                <img
                  src={selected.source}
                  alt="Fullscreen userfull"
                  width="100%"
                />

                <MyLeftArrow>
                  <LeftArrow
                    style={{ fontSize: 80, color: "#303F9F" }}
                    onClick={this.handlePrev}
                  />
                </MyLeftArrow>

                <MyRightArrow>
                  <RightArrow
                    style={{ fontSize: 80, color: "#303F9F" }}
                    onClick={this.handleNext}
                  />
                </MyRightArrow>
                <ImageName>
                  {`Name: `}
                  <a
                    href={selected.source}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.getName(selected.source)}
                  </a>
                </ImageName>
                <TextBlock>
                  <TextField
                    id={`photodesc-${selected.id}`}
                    label={`Photo description`}
                    value={selected.desc}
                    margin="normal"
                    fullWidth
                    onChange={onChange}
                    disabled={!editMode}
                  />
                </TextBlock>
              </ModalContent>
            </ModalWrapper>
          )}
        </div>
      </Container>
    );
  }
}

export default ImageCarousel;
