import styled from 'styled-components';

export const FileBlock = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  margin-bottom: 8px;
`;
export const FileLabel = styled.div`
  align-self: center;
`;
export const FileDelete = styled.div`
  cursor: pointer;
  align-self: center;
`;

export const NoFileBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 8px;
`;
