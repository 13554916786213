import styled from "styled-components";

export const StylingBlock = styled.div`
  svg path {
    stroke: white;
    stroke-width: 1px;
    //stroke-dasharray: 5px 5px;
  }

  svg text {
    fill: white;
    font-family: "Courier New";
    font-size: 17px;
    font-weight: 800;
  }
`;

export const Container = styled.div`
  display: flex;

  @media (max-width: 1250.98px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TextBlock = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
  
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 1250.98px) {
    padding: 20px;
  }
`;
