import React from "react";
import { connect } from "react-redux";
import { Container, InfoLine, Label, LineBlock } from "./index.style";
import { changeFormValue } from "../../actions/form";
import ConnectionView from "../ConnectionView";

const ConnectionsBlock = ({
  connection1,
  connection2,
  connection3,
  connection4,
  connection5,
  connection6,
  connectiondesc1,
  connectiondesc2,
  connectiondesc3,
  connectiondesc4,
  connectiondesc5,
  connectiondesc6,
  userdata,
  editMode,
  changeFormValue,
  userid,
  checkFreeConnection,
  connectionAction,
  checkConnectionPlace
}) => {

  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const deleteConnection = (connectionId, sourcePlace) => {
    let targetPlace = checkConnectionPlace(connectionId, userid);
    connectionAction("delete", userid, sourcePlace, connectionId, targetPlace);
  };

  const addConnection = (connectionId, sourcePlace) => {
    if (connectionId) {
      let targetPlace = checkFreeConnection(connectionId);
      if (targetPlace === -1) {
        return { error: true, msg: "No free place" };
      }
      connectionAction("add", userid, sourcePlace, connectionId, targetPlace);

      return { error: false };
    }
  };

  const setDescription = (connectionId, sourcePlace, desc) => {
    let targetPlace = checkConnectionPlace(connectionId, userid);
    connectionAction(
      "desc",
      userid,
      sourcePlace,
      connectionId,
      targetPlace,
      desc
    );
  };

  if (userid === "add") {
    return (
      <Container>
        <Label>Связи</Label>
        Добавление связей будет доступно после сохранения новой карточки
      </Container>
    );
  }

  return (
    <Container>
      <Label>Связи в Radar</Label>
      <InfoLine>
        <LineBlock>
          <ConnectionView
            id={1}
            connection={connection1}
            desc={connectiondesc1}
            editMode={editMode}
            data={userdata}
            handleChange={handleChange}
            changeFormValue={changeFormValue}
            deleteConnection={deleteConnection}
            addConnection={addConnection}
            setDescription={setDescription}
          />
          <ConnectionView
            id={4}
            connection={connection4}
            desc={connectiondesc4}
            editMode={editMode}
            data={userdata}
            handleChange={handleChange}
            changeFormValue={changeFormValue}
            deleteConnection={deleteConnection}
            addConnection={addConnection}
            setDescription={setDescription}
          />
        </LineBlock>
        <LineBlock>
          <ConnectionView
            id={2}
            connection={connection2}
            desc={connectiondesc2}
            editMode={editMode}
            data={userdata}
            handleChange={handleChange}
            changeFormValue={changeFormValue}
            deleteConnection={deleteConnection}
            addConnection={addConnection}
            setDescription={setDescription}
          />
          <ConnectionView
            id={5}
            connection={connection5}
            desc={connectiondesc5}
            editMode={editMode}
            data={userdata}
            handleChange={handleChange}
            changeFormValue={changeFormValue}
            deleteConnection={deleteConnection}
            addConnection={addConnection}
            setDescription={setDescription}
          />
        </LineBlock>
        <LineBlock noRightBorder>
          <ConnectionView
            id={3}
            connection={connection3}
            desc={connectiondesc3}
            editMode={editMode}
            data={userdata}
            handleChange={handleChange}
            changeFormValue={changeFormValue}
            deleteConnection={deleteConnection}
            addConnection={addConnection}
            setDescription={setDescription}
          />

          <ConnectionView
            id={6}
            connection={connection6}
            desc={connectiondesc6}
            editMode={editMode}
            data={userdata}
            handleChange={handleChange}
            changeFormValue={changeFormValue}
            deleteConnection={deleteConnection}
            addConnection={addConnection}
            setDescription={setDescription}
          />
        </LineBlock>
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    connection1: state.form.connection1,
    connection2: state.form.connection2,
    connection3: state.form.connection3,
    connection4: state.form.connection4,
    connection5: state.form.connection5,
    connection6: state.form.connection6,
    connectiondesc1: state.form.connectiondesc1,
    connectiondesc2: state.form.connectiondesc2,
    connectiondesc3: state.form.connectiondesc3,
    connectiondesc4: state.form.connectiondesc4,
    connectiondesc5: state.form.connectiondesc5,
    connectiondesc6: state.form.connectiondesc6
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionsBlock);
