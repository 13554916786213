import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const DatesBlock = styled.div`
  display: grid;
  grid-template-columns: 150px repeat(3, 200px);
  gap: 12px;
  margin-bottom: 20px;
`;

export const TagsBlock = styled.div`
  display: grid;
  grid-template-columns:
    repeat(${({ cols = 1, demo }) => `${cols}, ${demo ? 130 : 80}px`})
    1fr;
  gap: 12px;
  margin-bottom: 20px;
`;

export const ActionsBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 20px;
  margin-bottom: 20px;
`;

export const GoogleClndr = styled.div`
  justify-self: center;
`;
