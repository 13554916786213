import {
  // GETDATA_FAIL,
  // GETDATA_SUCCESS,
  // GETDATA_INIT,
  GETSTORE_INIT,
  GETSTORE_SUCCESS,
  GETSTORE_FAIL
} from "../constants/crudActions";
// import { data as fakedata } from "../services/fakedata";
//
// export const getUserdata = () => async dispatch => {
//   dispatch({ type: GETDATA_INIT });
//   const data = await Promise.resolve(fakedata);
//
//   if (data) {
//     dispatch({ type: GETDATA_SUCCESS, payload: data });
//   } else {
//     dispatch({
//       type: GETDATA_FAIL,
//       payload: { error: "Имя пользователя или пароль введены не верно " }
//     });
//   }
// };

export const getStoreName = (firestore, uid) => async dispatch => {
  dispatch({ type: GETSTORE_INIT });

  try {
    const storeReq = await firestore
      .collection("userInfo")
      .doc(uid)
      .get();

    dispatch({ type: GETSTORE_SUCCESS, payload: storeReq.data() });
  } catch (e) {
    dispatch({ type: GETSTORE_FAIL });
  }
};
