import {
  EXPORT_ERROR_ADD,
  EXPORT_ERROR_RESET,
  VIEW_CHANGE_CARD,
  VIEW_CHANGE_PAGE
} from "../constants/viewActions";

const initialState = {
  page: 0,
  type: "grid",
  card: null,
  error: []
};

const viewReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case VIEW_CHANGE_PAGE:
      return { ...state, page: payload.page, card: null };
    case VIEW_CHANGE_CARD:
      return { ...state, card: payload.card };
    case EXPORT_ERROR_ADD:
      return { ...state, error: [...state.error, payload.error] };
    case EXPORT_ERROR_RESET:
      return { ...state, error: [] };
    default:
      return state;
  }
};

export default viewReducer;
