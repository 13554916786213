import React from 'react';
import Button from '@material-ui/core/Button';
import ListIcon from '@material-ui/icons/ViewList';
import GridIcon from '@material-ui/icons/GridOn';
import { Container } from './index.style';

const ViewCustomizer = ({ handleView, logout, view }) => {
  return (
    <Container>
      <Button onClick={handleView('list')} disabled={view === 'list'}>
        <ListIcon />
      </Button>
      <Button onClick={handleView('grid')} disabled={view === 'grid'}>
        <GridIcon />
      </Button>
    </Container>
  );
};

export default ViewCustomizer;
