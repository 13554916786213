import React, { memo } from "react";
import { connect } from "react-redux";
import { Container, GridBlock, Label } from "./index.style";
import TextField from "@material-ui/core/TextField";
import { changeFormValue } from "../../actions/form";

const BioBlock = ({
  placeofbirth,
  mother,
  father,
  siblings,
  childhoodact,
  lifestory1,
  lifestory2,
  education1,
  education2,
  education3,
  major1,
  major2,
  major3,
  years1,
  years2,
  years3,
  bio_lived1,
  bio_lived2,
  bio_addinfo,
  education1_addinfo,
  education2_addinfo,
  education3_addinfo,
  editMode,
  changeFormValue
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Container>
      <Label>Биография</Label>
      <GridBlock columns={3}>
        <TextField
          id="placeofbirth"
          label="Place of birth"
          value={placeofbirth}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="bio_lived1"
          label="Lived 1"
          value={bio_lived1}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="bio_lived2"
          label="Lived 2"
          value={bio_lived2}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
      </GridBlock>
      <GridBlock columns={4}>
        <TextField
          id="mother"
          label="Mother"
          value={mother}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="father"
          label="Father"
          value={father}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />

        <TextField
          id="siblings"
          label="Siblings"
          value={siblings}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />

        <TextField
          id="bio_addinfo"
          label="Additional info"
          value={bio_addinfo}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
      </GridBlock>
      <GridBlock columns={1}>
        <TextField
          id="childhoodact"
          label="Childhood activities"
          value={childhoodact}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="lifestory1"
          label="Life story 1"
          value={lifestory1}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="lifestory2"
          label="Life story 2"
          value={lifestory2}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
      </GridBlock>
      <GridBlock columns={4}>
        <TextField
          id="education1"
          label="Education 1"
          value={education1}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="major1"
          label="Major"
          value={major1}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="years1"
          label="Years"
          value={years1}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="education1_addinfo"
          label="Additional"
          value={education1_addinfo}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />

        <TextField
          id="education2"
          label="Education 2"
          value={education2}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="major2"
          label="Major"
          value={major2}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="years2"
          label="Years"
          value={years2}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="education2_addinfo"
          label="Additional"
          value={education2_addinfo}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />

        <TextField
          id="education3"
          label="Education 3"
          value={education3}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="major3"
          label="Major"
          value={major3}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="years3"
          label="Years"
          value={years3}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="education3_addinfo"
          label="Additional"
          value={education3_addinfo}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
      </GridBlock>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    placeofbirth: state.form.placeofbirth,
    mother: state.form.mother,
    father: state.form.father,
    siblings: state.form.siblings,
    childhoodact: state.form.childhoodact,
    lifestory1: state.form.lifestory1,
    lifestory2: state.form.lifestory2,
    education1: state.form.education1,
    education2: state.form.education2,
    education3: state.form.education3,
    major1: state.form.major1,
    major2: state.form.major2,
    major3: state.form.major3,
    years1: state.form.years1,
    years2: state.form.years2,
    years3: state.form.years3,
    bio_lived1: state.form.bio_lived1,
    bio_lived2: state.form.bio_lived2,
    bio_addinfo: state.form.bio_addinfo,
    education1_addinfo: state.form.education1_addinfo,
    education2_addinfo: state.form.education2_addinfo,
    education3_addinfo: state.form.education3_addinfo
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(BioBlock));
