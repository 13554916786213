import React from "react";
import ControlTab from "../ControlTab";
import {
  BoldTextBlock,
  Container,
  FlexBlock,
  BorderBlock,
  ImageBlock,
  TextBlock,
  BGText
} from "./index.style";

import merge1 from "../../assets/merge1.jpg";
import merge2 from "../../assets/merge2.jpg";

const MergeRadar = () => {
  return (
    <>
      <ControlTab />
      <Container>
        <BoldTextBlock>
          Данный раздел находится в стадии проектирования. Мы сможем приступить
          к его разработке после привлечения финансирования.
        </BoldTextBlock>
        <TextBlock>
          Смысл функции Radar Merge состоит в том, чтобы пользователи Human
          Radar могли объединять свои сети контактов (merge networks).
        </TextBlock>
        <FlexBlock>
          <ImageBlock>
            <img src={merge1} alt="merge" />
          </ImageBlock>
          <TextBlock>
            Если мы с Вами хотим обменяться связями, т.е. приоткрыть свой
            нетворк друг для друга, мы пересылаем контактную информацию, делаем
            introduction, пр. – но это всегда разовое действие, нацеленное на
            точечный обмен. Это хорошо работает тогда, когда мы не очень глубоко
            связаны друг с другом, т.е. когда нас не объединяет либо дружба,
            либо совместные проекты.
          </TextBlock>
        </FlexBlock>
        <FlexBlock>
          <ImageBlock>
            <img src={merge2} alt="merge" />
          </ImageBlock>
          <div>
            <TextBlock>
              Когда же возникает близость общения (например, мы стали партнерами
              в каком-то проекте), почти всегда возникает необходимость
              постоянного и глубокого обмена своими связями, или мы оба
              параллельно приобретаем новые связи – клиентов, инвесторов,
              подрядчиков, сотрудников, пр. Обсуждения из разряда «а давай
              поговорим вот с этим…» или «а давай обратимся вот к этому..» –
              зачастую являются основой любых партнерств.
            </TextBlock>
            <TextBlock>
              Вот тогда нам может очень пригодиться такая функция как Merge
              Networks.
            </TextBlock>
            <TextBlock>
              То есть мы можем договориться о том, к какой информации в нашей
              «записной книжке» мы готовы дать доступ нашему партнеру или другу.
              Или помощнице. Или жене
              <span role="img" aria-label="smile">
                😀
              </span>
            </TextBlock>
            <TextBlock>
              Функция Radar Merge позволит сделать это системно, без постоянной
              пересылки контактной информации по вотсапу или электронной почте,
              а также – что очень важно – позволит быстро и просто
              отрегулировать <b>глубину погружения</b> Вашего партнера/друга в
              данные Вашей учетной записи в Human Radar.
            </TextBlock>
            <TextBlock>
              Это поможет сделать наше общение с партнером более доверительным,
              четким и предметным.
            </TextBlock>
          </div>
        </FlexBlock>
        <BorderBlock>
          <div style={{ fontWeight: "800" }}>Пример 1</div>
          <div>
            <div>
              Пользователь <BGText>Maxim Feldman</BGText> предоставил Вам доступ
              к следующим данным его учетной записи в Human Radar:
            </div>
            <div>
              <span
                role="img"
                aria-label="checkmark"
                style={{ marginRight: "6px" }}
              >
                ✓
              </span>
              Категория контактов (Radar Circle):
              <BGText bold>Партнеры (72 чел.)</BGText>
            </div>
            <div>
              <span
                role="img"
                aria-label="checkmark"
                style={{ marginRight: "6px" }}
              >
                ✓
              </span>
              Для Вас открыты следующие разделы карточек контактов:
            </div>
            <ul style={{ fontWeight: "800" }}>
              <li>ЛИЧНЫЕ ДАННЫЕ</li>
              <li>КОНТАКТНЫЕ ДАННЫЕ</li>
              <li>ТЕКУЩАЯ ПРОФЕССИОНАЛЬНАЯ ДЕЯТЕЛЬНОСТЬ</li>
              <li>КОМПЕТЕНЦИИ</li>
              <li>ДРУГАЯ ДЕЯТЕЛЬНОСТЬ</li>
            </ul>
            <div>
              Нажмите <BGText>Принять</BGText> и эти данные будут доступны Вам
              по тэгу
              <b> #MaximFeldman</b> в поиске <b>Radar Category</b>.
            </div>
          </div>
        </BorderBlock>
        <BorderBlock>
          <div style={{ fontWeight: "800" }}>Пример 2</div>
          <div>
            <div>
              Пользователь <BGText>John Smith</BGText> предоставил Вам доступ к
              следующим данным его учетной записи в Human Radar:
            </div>
            <div>
              <span
                role="img"
                aria-label="checkmark"
                style={{ marginRight: "6px" }}
              >
                ✓
              </span>
              Любые контакты с отметкой <BGText>Телеком</BGText> и/или{" "}
              <BGText>ИТ</BGText> в полях <BGText>Industry</BGText> раздела{" "}
              <BGText>Текущая Профессиональная Деятельность</BGText>.
            </div>
            <div>
              <span
                role="img"
                aria-label="checkmark"
                style={{ marginRight: "6px" }}
              >
                ✓
              </span>
              Для Вас открыты следующие разделы карточек контактов:
            </div>
            <ul style={{ fontWeight: "800" }}>
              <li>ЛИЧНЫЕ ДАННЫЕ</li>
              <li>КОНТАКТНЫЕ ДАННЫЕ</li>
              <li>ТЕКУЩАЯ ПРОФЕССИОНАЛЬНАЯ ДЕЯТЕЛЬНОСТЬ</li>
            </ul>
            <div>
              Нажмите <BGText>Принять</BGText> и эти данные будут доступны Вам
              по тэгу
              <b> #JohnSmith</b> в поиске <b>Radar Category</b>.
            </div>
          </div>
        </BorderBlock>
      </Container>
    </>
  );
};

export default MergeRadar;
