import styled from "styled-components";
import DeleteIcon from "@material-ui/icons/Delete";

export const Carousel = styled.div`
  width: 100%;
  max-height: 152px;
  overflow-y: auto;
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  padding-right: 6px;
`;

export const OneImage = styled.div`
  cursor: pointer;
  position: relative;
`;

export const DIcon = styled(DeleteIcon)`
  color: gray;
  display: block;
  position: absolute;
  top: 0;
  right: 5px;
  font-size: 8px;

  &:hover {
    color: lightcoral;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
`;

export const ModalContent = styled.div`
  z-index: 15;
  width: 50%;
  border: 1px solid lightgray;
  background-color: white;
  position: relative;
`;

export const ImageName = styled.div`
  font-size: 16px;
  margin: 10px;
  margin-bottom: 0;
`;

export const TextBlock = styled.div`
  padding-left: 8px;
  padding-right: 8px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonBlock = styled.div`
  margin-top: 8px;
  align-self: center;
`;

export const MyLeftArrow = styled.div`
  position: absolute;
  left: 5px;
  top: 40%;
  cursor: pointer;
`;
export const MyRightArrow = styled.div`
  position: absolute;
  right: 5px;
  top: 40%;
  cursor: pointer;
`;
