import axios from "axios";

import {
  FORM_CHANGE_VALUE,
  FORM_LOAD_DATA,
  FORM_LOAD_BLANK,
  FORM_LOAD_DATA_ERROR
} from "../constants/form";

export const changeFormValue = (id, value) => {
  let picid;
  if (id === "images" && value !== "loading") {
    picid = `f${(~~(Math.random() * 1e8)).toString(16)}`;
  }
  if (/photodesc/.test(id)) {
    [id, picid] = id.split("-");
  }

  if (picid) {
    return {
      type: FORM_CHANGE_VALUE,
      id,
      value,
      picid
    };
  }

  return {
    type: FORM_CHANGE_VALUE,
    id,
    value,
  };
};

export const loadData = data => {
  if (!data.birthdate) {
    data.birthdate = null;
  } else if (data.birthdate.constructor.name === "Timestamp") {
    data.birthdate = data.birthdate.toDate();
  }
  return {
    type: FORM_LOAD_DATA,
    data
  };
};

export const loadBlank = () => {
  return {
    type: FORM_LOAD_BLANK
  };
};

export const loadShared = path => async dispatch => {
  if (path) {
    try {
      const { data } = await axios.get(
        `https://us-central1-alafia-tech.cloudfunctions.net/share?path=${path}`
      );
      if (data.status === "error") {
        dispatch({
          type: FORM_LOAD_DATA_ERROR
        });
      }
      dispatch({
        type: FORM_LOAD_DATA,
        data
      });
    } catch (e) {
      dispatch({
        type: FORM_LOAD_DATA_ERROR
      });
    }
  }
};
