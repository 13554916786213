import React, { memo } from "react";
import { connect } from "react-redux";
import { Container, InfoLine, Label, LineBlock } from "./index.style";
import { changeFormValue } from "../../actions/form";
import TextField from "@material-ui/core/TextField";
import FileWrapper from "../FileWrapper";
import moment from "moment";

const CommunicationBlock = ({
  firebase,
  editMode,
  changeFormValue,
  interaction1,
  interaction1_date,
  interaction1_location,
  interaction1_notes,
  interaction1_act1,
  interaction1_act2,
  interaction1_act3,
  interaction1_file1,
  interaction1_file2,
  interaction1_file3,
  interaction2,
  interaction2_date,
  interaction2_location,
  interaction2_notes,
  interaction2_act1,
  interaction2_act2,
  interaction2_act3,
  interaction2_file1,
  interaction2_file2,
  interaction2_file3,
  interaction3,
  interaction3_date,
  interaction3_location,
  interaction3_notes,
  interaction3_act1,
  interaction3_act2,
  interaction3_act3,
  interaction3_file1,
  interaction3_file2,
  interaction3_file3
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const deleteFile = id => () => {
    changeFormValue(id, "");
  };

  const onUploadFile = id => e => {
    const file = e.target.files[0];
    let storageRef = firebase.storage().ref();
    let uploadTask = storageRef
      .child(`uploads/${new Date().getTime()}-${file.name}`)
      .put(file);

    uploadTask.on(
      "state_changed",
      function(snapshot) {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING:
            console.log("Upload is running");
            changeFormValue(id, "loading");
            break;
          default:
            break;
        }
      },
      function(error) {
        // Handle unsuccessful uploads
      },
      function() {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          changeFormValue(id, downloadURL);
        });
      }
    );
  };

  const handleDateChange = e => {
    const date = moment(e.target.value).format("YYYY-MM-DD");
    changeFormValue(e.target.id, date);
  };

  const getDate = date => {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    } else {
      return "";
    }
  };

  const smFont = { style: { fontSize: 14, height: 19 } };
  const smNotes = { style: { fontSize: 14 } };

  return (
    <Container>
      <Label>Общение и коммуникации</Label>

      <InfoLine>
        <LineBlock>
          <TextField
            id="interaction1"
            label="Interaction 1"
            value={interaction1}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="interaction1_date"
            label="Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={handleDateChange}
            value={getDate(interaction1_date)}
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            margin="normal"
          />
          <TextField
            id="interaction1_location"
            label="Location"
            value={interaction1_location}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
        </LineBlock>

        <LineBlock>
          <TextField
            id="interaction1_notes"
            value={interaction1_notes}
            placeholder="Meeting notes"
            multiline={true}
            fullWidth
            rows={10}
            rowsMax={10}
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smNotes}
            style={{ marginTop: "5px" }}
            onChange={handleChange}
          />
        </LineBlock>

        <LineBlock>
          <TextField
            id="interaction1_act1"
            label="Action item 1"
            value={interaction1_act1}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="interaction1_act2"
            label="Action item 2"
            value={interaction1_act2}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="interaction1_act3"
            label="Action item 3"
            value={interaction1_act3}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
        </LineBlock>

        <LineBlock>
          <h4>Meeting files</h4>
          <FileWrapper
            name="interaction1_file1"
            link={interaction1_file1}
            deleteFile={deleteFile}
            onUploadFile={onUploadFile}
            withDelete={editMode}
          />
          <FileWrapper
            name="interaction1_file2"
            link={interaction1_file2}
            deleteFile={deleteFile}
            onUploadFile={onUploadFile}
            withDelete={editMode}
          />
          <FileWrapper
            name="interaction1_file3"
            link={interaction1_file3}
            deleteFile={deleteFile}
            onUploadFile={onUploadFile}
            withDelete={editMode}
          />
        </LineBlock>
      </InfoLine>

      <InfoLine>
        <LineBlock>
          <TextField
            id="interaction2"
            label="Interaction 2"
            value={interaction2}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="interaction2_date"
            label="Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={handleDateChange}
            value={getDate(interaction2_date)}
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            margin="normal"
          />
          <TextField
            id="interaction2_location"
            label="Location"
            value={interaction2_location}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
        </LineBlock>

        <LineBlock>
          <TextField
            id="interaction2_notes"
            value={interaction2_notes}
            placeholder="Meeting notes"
            multiline={true}
            fullWidth
            rows={10}
            rowsMax={10}
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smNotes}
            style={{ marginTop: "5px" }}
            onChange={handleChange}
          />
        </LineBlock>

        <LineBlock>
          <TextField
            id="interaction2_act1"
            label="Action item 1"
            value={interaction2_act1}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="interaction2_act2"
            label="Action item 2"
            value={interaction2_act2}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="interaction2_act3"
            label="Action item 3"
            value={interaction2_act3}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
        </LineBlock>

        <LineBlock>
          <h4>Meeting files</h4>
          <FileWrapper
            name="interaction2_file1"
            link={interaction2_file1}
            deleteFile={deleteFile}
            onUploadFile={onUploadFile}
            withDelete={editMode}
          />
          <FileWrapper
            name="interaction2_file2"
            link={interaction2_file2}
            deleteFile={deleteFile}
            onUploadFile={onUploadFile}
            withDelete={editMode}
          />
          <FileWrapper
            name="interaction2_file3"
            link={interaction2_file3}
            deleteFile={deleteFile}
            onUploadFile={onUploadFile}
            withDelete={editMode}
          />
        </LineBlock>
      </InfoLine>

      <InfoLine>
        <LineBlock>
          <TextField
            id="interaction3"
            label="Interaction 3"
            value={interaction3}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="interaction3_date"
            label="Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={handleDateChange}
            value={getDate(interaction3_date)}
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            margin="normal"
          />
          <TextField
            id="interaction3_location"
            label="Location"
            value={interaction3_location}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
        </LineBlock>

        <LineBlock>
          <TextField
            id="interaction3_notes"
            value={interaction3_notes}
            placeholder="Meeting notes"
            multiline={true}
            fullWidth
            rows={10}
            rowsMax={10}
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smNotes}
            style={{ marginTop: "5px" }}
            onChange={handleChange}
          />
        </LineBlock>

        <LineBlock>
          <TextField
            id="interaction3_act1"
            label="Action item 1"
            value={interaction3_act1}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="interaction3_act2"
            label="Action item 2"
            value={interaction3_act2}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            id="interaction3_act3"
            label="Action item 3"
            value={interaction3_act3}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            fullWidth
            onChange={handleChange}
          />
        </LineBlock>

        <LineBlock>
          <h4>Meeting files</h4>
          <FileWrapper
            name="interaction3_file1"
            link={interaction3_file1}
            deleteFile={deleteFile}
            onUploadFile={onUploadFile}
            withDelete={editMode}
          />
          <FileWrapper
            name="interaction3_file2"
            link={interaction3_file2}
            deleteFile={deleteFile}
            onUploadFile={onUploadFile}
            withDelete={editMode}
          />
          <FileWrapper
            name="interaction3_file3"
            link={interaction3_file3}
            deleteFile={deleteFile}
            onUploadFile={onUploadFile}
            withDelete={editMode}
          />
        </LineBlock>
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    interaction1: state.form.interaction1,
    interaction1_date: state.form.interaction1_date,
    interaction1_location: state.form.interaction1_location,
    interaction1_notes: state.form.interaction1_notes,
    interaction1_act1: state.form.interaction1_act1,
    interaction1_act2: state.form.interaction1_act2,
    interaction1_act3: state.form.interaction1_act3,
    interaction1_file1: state.form.interaction1_file1,
    interaction1_file2: state.form.interaction1_file2,
    interaction1_file3: state.form.interaction1_file3,
    interaction2: state.form.interaction2,
    interaction2_date: state.form.interaction2_date,
    interaction2_location: state.form.interaction2_location,
    interaction2_notes: state.form.interaction2_notes,
    interaction2_act1: state.form.interaction2_act1,
    interaction2_act2: state.form.interaction2_act2,
    interaction2_act3: state.form.interaction2_act3,
    interaction2_file1: state.form.interaction2_file1,
    interaction2_file2: state.form.interaction2_file2,
    interaction2_file3: state.form.interaction2_file3,
    interaction3: state.form.interaction3,
    interaction3_date: state.form.interaction3_date,
    interaction3_location: state.form.interaction3_location,
    interaction3_notes: state.form.interaction3_notes,
    interaction3_act1: state.form.interaction3_act1,
    interaction3_act2: state.form.interaction3_act2,
    interaction3_act3: state.form.interaction3_act3,
    interaction3_file1: state.form.interaction3_file1,
    interaction3_file2: state.form.interaction3_file2,
    interaction3_file3: state.form.interaction3_file3
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(CommunicationBlock)
);
