import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import { changePage, changeCard, resetErrors } from "../../actions/viewActions";
import Paginator from "../Paginator";
import SearchBar from "../SearchBar";
import ViewCustomizer from "../ViewCustomizer";
import { ControlsBlock, PaginatorBlock, ButtonWrap } from "./index.style";
import TableView from "../TableView";
import GridView from "../GridView";
import ExcelLoader from "../ExcelLoader";
import Filter from "../Filter";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import BdayRadar from "../BdayRadar";

const UserList = ({
  data,
  push,
  auth,
  changePage,
  page,
  changeCard,
  prevcard,
  error,
  resetErrors,
  bday,
  demo,
                    noNotes
}) => {
  const [rows, setRows] = useState([]);
  const [view, setView] = useState("grid");

  useEffect(() => {
    setRows(data.slice(0, 50));
    if (prevcard && page > 0) {
      const startIndex = page * 50;
      const endIndex = startIndex + 50;
      setRows(data.slice(startIndex, endIndex));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChangePage = (e, page) => {
    const startIndex = page * 50;
    const endIndex = startIndex + 50;
    changePage(page);
    setRows(data.slice(startIndex, endIndex));
  };

  const handleView = id => () => {
    setView(id);
  };

  const handleAddNew = () => {
    push("/user/add");
  };

  const checkAuth = () => {
    return !!demo;
    // return false;
    //return demo;
    // const users = [
    //   "NWwiEfvB0fTw0XAb0i9Iro4KQJo2",
    //   "jaa0aXfljybIHWeZoOpcOr7kUgy2",
    //   "A4fwZeDHq3YBiuNNZrndrXd2da02"
    // ];
    // if (users.includes(auth.uid)) {
    //   return false;
    // }
    // return true;
  };

  const handleOpen = id => {
    changeCard(id);
    push(`/user/${id}`);
  };

  const handleCloseError = () => {
    resetErrors();
  };

  return (
    <>
      {!bday && (
        <ControlsBlock>
          <SearchBar />

          <ButtonWrap>
            <ExcelLoader disabled={checkAuth()} noNotes={noNotes} />
            <Button
              style={{ backgroundColor: "#43A047", marginLeft: "20px" }}
              onClick={handleAddNew}
              disabled={checkAuth()}
            >
              <AddIcon />
            </Button>
          </ButtonWrap>
        </ControlsBlock>
      )}
      {!bday && <Filter />}
      {bday && <BdayRadar />}
      <PaginatorBlock>
        <ViewCustomizer handleView={handleView} view={view} />
        <Paginator
          currentPage={page}
          total={data.length}
          setPaginatorPage={handleChangePage}
        />
      </PaginatorBlock>

      {view === "list" && (
        <TableView rows={rows} handleOpenCard={handleOpen} fullList={data} />
      )}
      {view === "grid" && (
        <GridView
          rows={rows}
          handleOpenCard={handleOpen}
          prevcard={prevcard}
          bday={bday}
        />
      )}

      <Paginator
        currentPage={page}
        total={data.length}
        setPaginatorPage={handleChangePage}
      />
      {error && error.length > 0 && (
        <Dialog open onClose={handleCloseError}>
          <DialogTitle id="alert-dialog-title">Error log</DialogTitle>
          <DialogContent>
            {error.map((el, idx) => {
              return <div key={idx}>{`${el.type}: ${el.name}`}</div>;
            })}
            <DialogActions>
              <Button onClick={handleCloseError} color="primary">
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    page: state.view.page,
    prevcard: state.view.card,
    error: state.view.error,
    demo: state.crud.userInfo.demo,
    noNotes: state.crud.userInfo.nonotes
  };
};

const mapDispatchToProps = {
  push,
  changePage,
  changeCard,
  resetErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
