import React, { useState } from "react";
import { connect } from "react-redux";

import moment from "moment";
import {
  ActionsBlock,
  Container,
  DatesBlock,
  GoogleClndr,
  TagsBlock
} from "./index.style";
import Button from "@material-ui/core/Button";
import DatePicker from "../DatePicker";
import { resetFilter, setFilter } from "../../actions/filterActions";
import clndr from "../../assets/calendar.png";

const BdayRadar = ({ setFilter, resetFilter, demo }) => {
  const [checked, setChecked] = useState([]);
  const [date, setDate] = useState({ startDate: null, endDate: null });

  const tags = demo
    ? ["Друзья", "Партнеры", "Наставники", "Симпатия", "Клиенты"]
    : ["fp", "hichem", "ff", "fj", "fs", "fif"];

  const handleDate = ({ startDate, endDate }) => {
    setDate({ startDate, endDate });
  };

  const handleWeek = () => {
    setDate({ startDate: moment().day(1), endDate: moment().day(7) });
  };
  const handleMonth = () => {
    setDate({ startDate: moment().date(1), endDate: moment().date(31) });
  };
  const handleQuarter = () => {
    setDate({
      startDate: moment()
        .quarter(moment().quarter())
        .startOf("quarter"),
      endDate: moment()
        .quarter(moment().quarter())
        .endOf("quarter")
    });
  };
  const handleClickTag = id => {
    setChecked(c => {
      if (c.includes(id)) {
        return c.filter(e => e !== id);
      } else {
        return [...c, id];
      }
    });
  };
  const getTagButtonColor = id => {
    if (checked.includes(id)) {
      return "secondary";
    } else {
      return "default";
    }
  };

  const handleApply = () => {
    const filter = { radartag: checked.join(",") };
    if (date.startDate && date.endDate) {
      filter.bdate = {
        startDay: date.startDate.format("D"),
        startMonth: date.startDate.format("M"),
        endDay: date.endDate.format("D"),
        endMonth: date.endDate.format("M")
      };
    }
    console.log(filter);
    setFilter(filter);
  };

  const handleSelectAllTags = () => {
    if (checked.length === tags.length) {
      setChecked([]);
    } else {
      setChecked([...tags]);
    }
  };

  const handleReset = () => {
    setChecked([]);
    setDate({ startDate: null, endDate: null });
    resetFilter();
  };

  return (
    <Container>
      <DatesBlock>
        <DatePicker
          handleDate={handleDate}
          startDate={date.startDate}
          endDate={date.endDate}
        />
        <Button variant="outlined" onClick={handleWeek}>
          На этой неделе
        </Button>
        <Button variant="outlined" onClick={handleMonth}>
          В этом месяце
        </Button>
        <Button variant="outlined" onClick={handleQuarter}>
          В этом квартале
        </Button>
      </DatesBlock>
      <TagsBlock cols={tags.length + 1} demo={demo}>
        {tags.map((el, idx) => {
          return (
            <Button
              key={idx}
              variant="outlined"
              onClick={() => handleClickTag(el)}
              color={getTagButtonColor(el)}
            >
              {el}
            </Button>
          );
        })}

        <Button
          variant="outlined"
          onClick={handleSelectAllTags}
          color="primary"
        >
          All
        </Button>
        <GoogleClndr>
          <Button disabled variant='outlined'>
            Export to <img src={clndr} alt="Add to calendar" height="50px" />
          </Button>
        </GoogleClndr>
      </TagsBlock>
      <ActionsBlock>
        <Button onClick={handleApply} color="primary" variant="outlined">
          Применить
        </Button>
        <Button onClick={handleReset} color="secondary" variant="outlined">
          Сбросить
        </Button>
      </ActionsBlock>
    </Container>
  );
};

const mapStateToProps = state => {
  return { demo: state.crud.userInfo.demo };
};

const mapDispatchToProps = {
  setFilter,
  resetFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(BdayRadar);
