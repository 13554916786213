import {
  EXPORT_ERROR_RESET,
  VIEW_CHANGE_CARD,
  VIEW_CHANGE_PAGE
} from "../constants/viewActions";

export const changePage = page => {
  return {
    type: VIEW_CHANGE_PAGE,
    page
  };
};

export const changeCard = card => {
  return {
    type: VIEW_CHANGE_CARD,
    card
  };
};

export const resetErrors = () => {
  return {
    type: EXPORT_ERROR_RESET
  };
};
