import React from "react";
import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import history from "../services/history";

import App from "../components/App";
import LoginPage from "../components/LoginPage";
import NotFound from "../components/NotFound";
import AuthRoute from "./AuthRoute";
import UserFull from "../components/UserFull";
import RadarMap from "../components/RadarMap";
//import CirclesBlock from "../components/CirclesBlock";
import ParsePage from "../components/ParsePage";
import SharedView from "../components/SharedView";
import ZipLoader from "../components/ZipLoader";
import VennDiagram from "../components/VennDiagram";
import RadarConnections from "../components/RadarConnections";
import RadarGames from "../components/RadarGames";
import MergeRadar from "../components/MergeRadar";
import RadarMeetings from "../components/RadarMeetings";
import IntroBlock from "../components/IntroBlock";
import VennDiagramDemo from "../components/VennDiagramDemo";
import InfoPage from "../components/InfoPage";
//import BdayRadar from "../components/BdayRadar";

const checkHostname = () => {
  const host = window.location.hostname;
  if (/alafia.tech/.test(host)) {
    return true;
  }
};

const Router = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <AuthRoute path="/" exact component={App} />
      <AuthRoute path="/user/:userid" exact component={UserFull} />
      <AuthRoute path="/map" exact component={RadarMap} />
      <AuthRoute path="/quiz" exact component={RadarGames} />
      <AuthRoute path="/circles" exact component={VennDiagram} />
      <AuthRoute path="/radarcircles" exact component={VennDiagramDemo} />
      <AuthRoute path="/connections" exact component={RadarConnections} />
      <AuthRoute path="/merge" exact component={MergeRadar} />
      <AuthRoute path="/meet" exact component={RadarMeetings} />
      <AuthRoute path="/intro" exact component={IntroBlock} />
      {/*<AuthRoute path="/radarcircles" exact component={VennDiagram} />*/}
      <AuthRoute path="/bdayradar" exact render={() => <App bday={true} />} />
      <Route
        path="/login"
        exact
        render={() => (checkHostname() ? <LoginPage /> : <InfoPage />)}
      />
      <Route path="/pilotzone" exact component={LoginPage} />
      <Route path="/pdfParse" exact component={ParsePage} />
      <Route path="/zip" exact component={ZipLoader} />
      <Route path="/shared/:path" exact component={SharedView} />
      <Route component={NotFound} />
    </Switch>
  </ConnectedRouter>
);

export default Router;
