import React, { useEffect } from "react";
import { connect } from "react-redux";

import GridCard from "../GridCard";
import { Container } from "./index.style";
import { loadBlank } from "../../actions/form";

const GridView = ({ rows, loadBlank, handleOpenCard, prevcard, bday }) => {
  const handleClick = id => () => {
    handleOpenCard(id);
  };

  useEffect(() => {
    loadBlank();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container>
      {rows.map(el => {
        return (
          <GridCard
            key={el.id}
            {...el}
            handleClick={handleClick(el.id)}
            focus={prevcard === el.id}
            bday={bday}
          />
        );
      })}
    </Container>
  );
};

const mapDispatchToProps = {
  loadBlank
};

export default connect(null, mapDispatchToProps)(GridView);
