import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 18px;
`;

export const TextBlock = styled.div`
  margin-bottom: 12px;
  font-weight: ${({ bold }) => (bold ? "800" : "400")};
`;

export const BoldText = styled.span`
  font-weight: 800;
`;

export const UnderText = styled.span`
  text-decoration: underline;
`;

export const TableBlock = styled.table`
  margin-top: 20px;
  border: 1px solid lightblue;
  border-collapse: collapse;
  font-size: 14px;
  td,
  th {
    padding: 3px 8px;
    border: 1px solid lightblue;
  }

  td {
    height: 150px;
    min-width: 70px;
  }

  ol {
    padding: 0;
    margin-left: 15px;
  }

  tr {
    height: 120px;
    max-height: 120px;
    //max-height: 200px;
  }
`;
