import React, { useState } from "react";

import LoginView from "../LoginView";
import DrawerBlock from "../DrawerBlock";

const ControlTab = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  return (
    <>
      {showDrawer && <DrawerBlock handleClose={() => setShowDrawer(false)} />}
      <LoginView handleOpenMenu={() => setShowDrawer(true)} />
      {/* <SearchBar /> */}
      {/* <Filter handleFilter={handleFilter} /> */}
    </>
  );
};

export default ControlTab;
