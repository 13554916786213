import React, { useState } from "react";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./style.css"
import {Container, Label} from "./index.style";
import moment from "moment";
moment.locale('ru');

const DatePicker = ({ startDate, endDate, handleDate }) => {
  const [focusedInput, setFocused] = useState(null);

  const onChange = ({ startDate, endDate }) => {
    handleDate({ startDate, endDate });
  };

  return (
    <Container>
      <DateRangePicker
        minDate={moment().startOf("year")}
        maxDate={moment().endOf("year")}
        //initialVisibleMonth={() => moment().startOf("year")}
        startDate={startDate}
        startDateId="your_unique_start_date_id"
        endDate={endDate}
        endDateId="your_unique_end_date_id"
        onDatesChange={onChange}
        focusedInput={focusedInput}
        onFocusChange={f => setFocused(f)}
        displayFormat="D MMMM"
        minimumNights={0}
        monthFormat="MMMM"
        numberOfMonths={3}
        isOutsideRange={() => null}
        noBorder
        small
        withFullScreenPortal={false}
        withPortal

      />
      <Label>Birthdate</Label>
    </Container>
  );
};

export default DatePicker;
