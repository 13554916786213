import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const IconBlock = styled.div`
  margin-right: 12px;
  cursor: pointer;
  align-self: center;
`;