import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  padding: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  box-sizing: border-box;
`;

export const Label = styled.h2`
  margin: 0;
`;

export const FileBlock = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 1fr;
   @media (max-width: 991.98px) {
    grid-template-columns: 1fr;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 991.98px) {
    flex-direction: column;
  }
`;

export const FlexBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "100%")};
  padding-right: 20px;
`;
