import React from "react";
import { connect } from "react-redux";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { push } from "connected-react-router";
import { logout } from "../../actions/authActions";
import { compose } from "redux";
import { withFirebase } from "react-redux-firebase";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../assets/logo.svg";

import {
  Container,
  UserName,
  IconWrap,
  MenuBlock,
  UserBlock,
  FloatLogo,
  HeaderText,
  DemoText
} from "./index.style";

const LoginView = ({
  auth,
  push,
  logout,
  firebase,
  handleOpenMenu,
  path,
  demo
}) => {
  const goLogout = () => {
    logout(firebase);
    push("/login");
  };

  const getHeaderText = () => {
    switch (path) {
      case "/map":
        return "Radar Map";
      case "/bdayradar":
        return "Radar Birthdays";
      case "/circles":
        return "Radar Circles";
      case "/connections":
        return "Radar Connections";
      case "/quiz":
        return "Radar Games";
      case "/merge":
        return "Radar Merge";
      case "/meet":
        return "Radar Meetings";
      default:
        return "";
    }
  };

  return (
    <Container>
      <MenuBlock>
        <div style={{ display: "flex", alignItems: "center" }}>
          <MenuIcon fontSize="large" onClick={handleOpenMenu} />
          <HeaderText>{getHeaderText()}</HeaderText>
        </div>

        {demo && <DemoText>Desktop demo version 1.8</DemoText>}
      </MenuBlock>

      <UserBlock>
        <FloatLogo>
          <img src={logo} alt={logo} height={50} onClick={() => push("/")} />
        </FloatLogo>
        <UserName>{auth.email}</UserName>
        <IconWrap onClick={goLogout}>
          <LogoutIcon />
        </IconWrap>
      </UserBlock>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    path: state.router.location.pathname,
    demo: state.crud.userInfo.demo
  };
};

const mapDispatchToProps = {
  logout,
  push
};

const enhance = compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(LoginView);
