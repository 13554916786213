import React, { memo } from "react";
import { connect } from "react-redux";
import { Container, InfoLine, Label } from "./index.style";
import { changeFormValue } from "../../actions/form";
import TextField from "@material-ui/core/TextField";

const CareerBlock = ({
  editMode,
  changeFormValue,
  company1,
  company2,
  company3,
  company4,
  company5,
  company6,
  company1_about,
  company1_industry,
  company1_position,
  company1_years,
  company2_about,
  company2_industry,
  company2_position,
  company2_years,
  company3_about,
  company3_industry,
  company3_position,
  company3_years,
  company4_about,
  company4_industry,
  company4_position,
  company4_years,
  company5_about,
  company5_industry,
  company5_position,
  company5_years,
  company6_about,
  company6_industry,
  company6_position,
  company6_years
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Container>
      <Label>Карьера</Label>
      <InfoLine>
        <TextField
          id="company1"
          label="Company 1"
          value={company1}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company1_about"
          label="About"
          value={company1_about}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company1_industry"
          label="Industry"
          value={company1_industry}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company1_position"
          label="Position"
          value={company1_position}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company1_years"
          label="Years"
          value={company1_years}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />

        <TextField
          id="company2"
          label="Company 2"
          value={company2}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company2_about"
          label="About"
          value={company2_about}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company2_industry"
          label="Industry"
          value={company2_industry}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company2_position"
          label="Position"
          value={company2_position}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company2_years"
          label="Years"
          value={company2_years}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />

        <TextField
          id="company3"
          label="Company 3"
          value={company3}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company3_about"
          label="About"
          value={company3_about}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company3_industry"
          label="Industry"
          value={company3_industry}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company3_position"
          label="Position"
          value={company3_position}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company3_years"
          label="Years"
          value={company3_years}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />

        <TextField
          id="company4"
          label="Company 4"
          value={company4}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company4_about"
          label="About"
          value={company4_about}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company4_industry"
          label="Industry"
          value={company4_industry}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company4_position"
          label="Position"
          value={company4_position}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company4_years"
          label="Years"
          value={company4_years}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />

        <TextField
          id="company5"
          label="Company 5"
          value={company5}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company5_about"
          label="About"
          value={company5_about}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company5_industry"
          label="Industry"
          value={company5_industry}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company5_position"
          label="Position"
          value={company5_position}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company5_years"
          label="Years"
          value={company5_years}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />

        <TextField
          id="company6"
          label="Company 6"
          value={company6}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company6_about"
          label="About"
          value={company6_about}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company6_industry"
          label="Industry"
          value={company6_industry}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company6_position"
          label="Position"
          value={company6_position}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
        <TextField
          id="company6_years"
          label="Years"
          value={company6_years}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          fullWidth
          onChange={handleChange}
        />
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    company1: state.form.company1,
    company2: state.form.company2,
    company3: state.form.company3,
    company4: state.form.company4,
    company5: state.form.company5,
    company6: state.form.company6,
    company1_about: state.form.company1_about,
    company1_industry: state.form.company1_industry,
    company1_position: state.form.company1_position,
    company1_years: state.form.company1_years,
    company2_about: state.form.company2_about,
    company2_industry: state.form.company2_industry,
    company2_position: state.form.company2_position,
    company2_years: state.form.company2_years,
    company3_about: state.form.company3_about,
    company3_industry: state.form.company3_industry,
    company3_position: state.form.company3_position,
    company3_years: state.form.company3_years,
    company4_about: state.form.company4_about,
    company4_industry: state.form.company4_industry,
    company4_position: state.form.company4_position,
    company4_years: state.form.company4_years,
    company5_about: state.form.company5_about,
    company5_industry: state.form.company5_industry,
    company5_position: state.form.company5_position,
    company5_years: state.form.company5_years,
    company6_about: state.form.company6_about,
    company6_industry: state.form.company6_industry,
    company6_position: state.form.company6_position,
    company6_years: state.form.company6_years
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(CareerBlock));
