export const UPDATE_USERINFO_INIT = "UPDATE_USERINFO_INIT";
export const UPDATE_USERINFO_SUCCESS = "UPDATE_USERINFO_SUCCESS";
export const UPDATE_USERINFO_FAIL = "UPDATE_USERINFO_FAIL";
export const CREATE_USERINFO_INIT = "CREATE_USERINFO_INIT";
export const CREATE_USERINFO_SUCCESS = "CREATE_USERINFO_SUCCESS";
export const CREATE_USERINFO_FAIL = "CREATE_USERINFO_FAIL";

export const DELETE_USERINFO_INIT = "DELETE_USERINFO_INIT";
export const DELETE_USERINFO_SUCCESS = "DELETE_USERINFO_SUCCESS";
export const DELETE_USERINFO_FAIL = "DELETE_USERINFO_FAIL";

export const UPDATE_USERINFO_CONNECTION_INIT =
  "UPDATE_USERINFO_CONNECTION_INIT";
export const UPDATE_USERINFO_CONNECTION_SUCCESS =
  "UPDATE_USERINFO_CONNECTION_SUCCESS";
export const UPDATE_USERINFO_CONNECTION_FAIL =
  "UPDATE_USERINFO_CONNECTION_FAIL";
