import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  padding: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  box-sizing: border-box;
`;

export const Label = styled.h2`
  margin: 0;
`;

export const LineBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  column-gap: 20px;
  border-right: 1px solid lightblue;
  padding-right: 12px;
  margin-right: 12px;

  @media (max-width: 991.98px) {
    border-right: none;
    padding: 0;
    margin: 0;
  }
`;

export const LineBlockSecond = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1fr;
  column-gap: 20px;
  border-right: 1px solid lightblue;
  padding-right: 12px;
  margin-right: 12px;
  @media (max-width: 991.98px) {
    border-right: none;
    padding: 0;
    margin: 0;
  }
`;

export const InfoLine = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 2fr;

  @media (max-width: 991.98px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
`;
