import styled from "styled-components";

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  padding: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
`;

export const Label = styled.h2`
  margin: 0;
  margin-bottom: 12px;
`;

export const Container = styled.div`
  display: flex;

  @media (max-width: 991.98px) {
    flex-direction: column;
  }
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 20px;
  width: 100%;
  border-right: ${({ woBorder }) =>
    woBorder ? "none" : "1px solid lightblue"};

  @media (max-width: 991.98px) {
    border-right: none;
    padding: 0;
  }
`;

export const ImageBlock = styled.div`
  padding-right: 20px;
  border-right: 1px solid lightblue;

  @media (max-width: 991.98px) {
    border-right: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FullNameBlock = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(3, 200px);
  margin-bottom: 12px;

  @media (max-width: 991.98px) {
    grid-template-columns: repeat(3, 1fr);
    border-right: none;
  }
`;

export const ContactBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 200px);
  margin-bottom: 12px;
`;

export const LineBlock = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
  border-right: 1px solid lightblue;
  padding-right: 12px;
  margin-right: 12px;
  //min-width: 150px;
  //width: ${({ width = "auto" }) => width};
  
@media (max-width: 991.98px) {
    border-right: none;
  }
`;

export const InfoLine = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
`;
