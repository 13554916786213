import styled from "styled-components";

export const ControlsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  @media (max-width: 575.98px) {
    flex-direction: column;
    margin-bottom: 15px;
    align-items: flex-start;
    justify-items: flex-start;
  }
`;

export const PaginatorBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const ButtonWrap = styled.div`
  height: 35px;
  display: flex;

  @media (max-width: 575.98px) {
    align-self: flex-end;
  }
`;
