import React from "react";
import TextField from "@material-ui/core/TextField";

import { Container, ViewBlock, Link, TopLabel } from "./index.style";

const LinksView = ({ link, editMode, desc, id, handleChange }) => {

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Container>
      {!editMode && (
        <ViewBlock>
          {link && (
            <Link href={link} target="_blank">
              {link}
            </Link>
          )}

          <TopLabel>{`Link ${id}`}</TopLabel>
        </ViewBlock>
      )}

      {/* {!editMode && !link && (
        <TextField
          id={`links${id}`}
          label={`Link ${id}`}
          value={link}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          style={{ marginRight: "15px" }}
          fullWidth
          onChange={handleChange}
        />
      )}*/}

      {editMode && (
        <TextField
          id={`links${id}`}
          label={`Link ${id}`}
          value={link}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          style={{ marginRight: "15px" }}
          fullWidth
          onChange={handleChange}
        />
      )}

      <TextField
        id={`linksdesc${id}`}
        label="Details"
        value={desc}
        margin="normal"
        InputProps={{
          readOnly: !editMode
        }}
        inputProps={smFont}
        fullWidth
        onChange={handleChange}
      />
    </Container>
  );
};

export default LinksView;
