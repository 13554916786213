import styled from "styled-components";

export const LoginBlock = styled.div`
  display: grid;
  gap: 15px;
  width: 250px;
  margin: 0 auto;
  padding-top: 25px;
`;

export const ImageBlock = styled.div`
  margin: 0 auto;
`;
