import { MAP_MOVE } from "../constants/map";

export const mapMove = ({ center, zoom, bounds }) => {
  return {
    type: MAP_MOVE,
    center,
    zoom,
    bounds
  };
};
