import styled from "styled-components";

export const FilterBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  margin-bottom: 15px;
  width: 100%;

  @media (max-width: 991.98px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 575.98px) {
    grid-template-columns: 1fr;
  }
`;

export const ExpandBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  margin-bottom: 15px;
  width: 100%;
`;
