import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export const TextBlock = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const BoldTextBlock = styled(TextBlock)`
  font-weight: 800;
  margin-top: 40px;
`;

export const BlockNameLabel = styled.div`
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 12px;
`;

export const FlexBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 991.98px) {
    flex-direction: column;
  }
`;

export const ImageBlock = styled.div`
  display: flex;
  margin-right: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  @media (max-width: 991.98px) {
    margin-right: 0;
    margin-bottom: 30px;
  }
`;

export const BorderBlock = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 100px 1fr;
  margin-bottom: 20px;
  padding: 20px;
  border-top: 4px solid cornflowerblue;
  font-size: 18px;
  justify-content: space-between;
  align-items: center;
  width: 95%;
`;

export const BGText = styled.span`
  padding: 1px 4px;
  background-color: lightgray;
  font-weight: ${({ bold }) => (bold ? "800" : "400")};
`;
