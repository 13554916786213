import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DatePicker from "../DatePicker";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { resetFilter, setFilter } from "../../actions/filterActions";
import { FilterBlock, ExpandBlock } from "./index.style";
import { changePage } from "../../actions/viewActions";
import { getData } from "../../selectors";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Filter = ({
  filter,
  setFilter,
  resetFilter,
  changePage,
  demo,
  userData
}) => {
  const [expanded, setExpand] = useState(false);
  const [intFilter, setIntFilter] = useState({});
  const [ind, setInd] = useState([]);

  useEffect(() => {
    if (Array.isArray(userData)) {
      const list = userData
        .reduce((acc, el) => {
          const { prof1_industry1, prof1_industry2, prof1_industry3 } = el;
          acc.push(prof1_industry1);
          acc.push(prof1_industry2);
          acc.push(prof1_industry3);
          return acc;
        }, [])
        .filter(e => e);
      setInd(
        [...new Set(list)].sort((a, b) => {
          if (a > b) return 1;
          if (b > a) return -1;
          return 0;
        })
      );
    }
  }, [userData]);

  useEffect(() => {
    setIntFilter(filter);
  }, [filter]);

  const onExpand = () => {
    if (expanded) {
      setExpand(false);
    } else {
      setExpand(true);
    }
  };

  const handleApply = () => {
    let filter = { ...intFilter };
    if (intFilter.startDate && intFilter.endDate) {
      filter.bdate = {
        startDay: intFilter.startDate.format("D"),
        startMonth: intFilter.startDate.format("M"),
        endDay: intFilter.endDate.format("D"),
        endMonth: intFilter.endDate.format("M")
      };
    }
    filter.startDate = "";
    filter.endDate = "";
    changePage(0);
    setFilter(filter);
  };

  const handleReset = () => {
    changePage(0);
    setIntFilter({});
    resetFilter();
  };

  const handleChange = e => {
    setIntFilter({ ...intFilter, [e.target.id]: e.target.value });
  };

  const handleDate = ({ startDate, endDate }) => {
    setIntFilter({ ...intFilter, startDate, endDate });
  };

  const onCh = (_, val) => {
    setIntFilter({ ...intFilter, industry: val });
  };

  return (
    <>
      {expanded && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FilterBlock>
            <TextField
              id="surname"
              label="SURNAME"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.surname || ""}
            />
            <TextField
              id="tags"
              label="TAGS"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.tags || ""}
            />
            {demo && (
              <Autocomplete
                options={ind}
                onChange={onCh}
                //getOptionLabel={option => option.name}
                freeSolo
                disableClearable
                value={intFilter.industry || ""}
                renderInput={params => (
                  <TextField
                    {...params}
                    //id="industry"
                    label="INDUSTRY"
                    variant="outlined"
                    //onChange={handleChange}
                    //value={intFilter.industry || ""}
                  />
                  /*<TextField

                  label={`Name ${id}`}
                  placeholder={`Connection`}
                  margin="normal"
                  fullWidth
                  style={{ minWidth: "300px" }}
                  //inputProps={smFont}
                />*/
                )}
              />
            )}

            {!demo && (
              <TextField
                id="industry"
                label="INDUSTRY"
                variant="outlined"
                onChange={handleChange}
                value={intFilter.industry || ""}
              />
            )}
            <TextField
              id="age"
              label="AGE"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.age || ""}
            />
            <TextField
              id="country"
              label="COUNTRY"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.country || ""}
            />
            <TextField
              id="city"
              label="CITY"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.city || ""}
            />
            <TextField
              id="geo"
              label="GEO"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.geo || ""}
            />
            <TextField
              id="company"
              label="COMPANY"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.company || ""}
            />
            <TextField
              id="position"
              label="POSITION"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.position || ""}
            />
            <TextField
              id="hobby"
              label="HOBBY"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.hobby || ""}
            />
            <TextField
              id="kids"
              label={demo ? "NUMBER OF KIDS" : "KIDS"}
              variant="outlined"
              onChange={handleChange}
              value={intFilter.kids || ""}
              disabled={!demo}
            />
            <TextField
              id="info"
              label={demo ? "RADAR CIRCLES" : "INFO"}
              variant="outlined"
              onChange={handleChange}
              value={intFilter.info || ""}
            />
            <TextField
              id="relatives"
              label="RELATIVES"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.relatives || ""}
              disabled
            />
            <TextField
              id="details"
              label="DETAILS"
              disabled={demo}
              variant="outlined"
              onChange={handleChange}
              value={intFilter.details || ""}
            />
            <TextField
              id="childhood"
              label="CHILDHOOD"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.childhood || ""}
            />
            <TextField
              id="education"
              label="EDUCATION"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.education || ""}
            />
            <TextField
              id="career"
              label="CAREER"
              disabled={demo}
              variant="outlined"
              onChange={handleChange}
              value={intFilter.career || ""}
            />

            <TextField
              id="professional"
              label={demo ? "COMPETENCIES" : "PROFESSIONAL"}
              variant="outlined"
              onChange={handleChange}
              value={intFilter.professional || ""}
            />

            <TextField
              id="language"
              label="LANGUAGE"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.language || ""}
            />
            <DatePicker
              handleDate={handleDate}
              startDate={intFilter.startDate || null}
              endDate={intFilter.endDate || null}
            />
            <TextField
              id="id"
              label="USER ID"
              variant="outlined"
              onChange={handleChange}
              value={intFilter.id || ""}
            />
          </FilterBlock>

          <FilterBlock>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={handleApply}
            >
              Искать
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={handleReset}
            >
              Сбросить
            </Button>
          </FilterBlock>
        </div>
      )}
      <ExpandBlock>
        <Button color="primary" variant="outlined" onClick={onExpand} fullWidth>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </ExpandBlock>
    </>
  );
};

const mapStateToProps = state => {
  return {
    filter: state.filter,
    demo: state.crud.userInfo.demo,
    userData: getData(state)
  };
};

const mapDispatchToProps = {
  setFilter,
  resetFilter,
  changePage
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
