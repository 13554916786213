import React from "react";

import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FileBlock, FileLabel, FileDelete, NoFileBlock } from "./index.style";

const FileWrapper = ({ name, link, deleteFile, onUploadFile, withDelete }) => {
  const getName = link => {
    const encode = decodeURI(decodeURI(link));
    //console.log(encode);
    let result = encode.match(/uploads%2F\d*-(.*)\?/) || [];
    if (result.length > 1) {
      /*todo: обрезать имена выводить тайтл по наведению*/
      return result[1];
    }
    return;
  };

  if (link && link !== "loading") {
    if (/.*\.(xlsx|xls|csv|doc|docx)/.test(link)) {
      const encode = encodeURI(link);
      link = `https://docs.google.com/viewer?url=${encode}`;
    }
    return (
      <FileBlock>
        <FileDelete>
          {withDelete && <CloseIcon onClick={deleteFile(name)} />}
        </FileDelete>
        <FileLabel>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {/*{name === 'foto' ? 'Photo' : name}*/}
            {getName(link) ? getName(link) : name}
          </a>
        </FileLabel>
      </FileBlock>
    );
  } else if (link === "loading") {
    return (
      <NoFileBlock>
        <CircularProgress size={14} />
      </NoFileBlock>
    );
  }

  return (
    <NoFileBlock>
      <input
        accept="*"
        style={{ display: "none" }}
        id={name}
        type="file"
        onChange={onUploadFile(name)}
        disabled={!withDelete}
      />
      <label htmlFor={name}>
        <Button variant="contained" component="span" disabled={!withDelete}>
          Загрузить
        </Button>
      </label>
    </NoFileBlock>
  );
};

export default FileWrapper;
