import React, { memo } from "react";
import { connect } from "react-redux";
import { Container, InfoLine, Label, LineBlock } from "./index.style";
import { changeFormValue } from "../../actions/form";
import TextField from "@material-ui/core/TextField";

const HobbyBlock = ({
  editMode,
  changeFormValue,
  hobby1,
  hobby2,
  hobby3,
  hobby4,
  hobby5,
  hobby6
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Container>
      <Label>Хобби</Label>
      <InfoLine>
        <LineBlock>
          <TextField
            id="hobby1"
            label="Hobby 1"
            value={hobby1}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="hobby4"
            label="Hobby 4"
            value={hobby4}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
        </LineBlock>
        <LineBlock>
          <TextField
            id="hobby2"
            label="Hobby 2"
            value={hobby2}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="hobby5"
            label="Hobby 5"
            value={hobby5}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
        </LineBlock>
        <LineBlock noRightBorder>
          <TextField
            id="hobby3"
            label="Hobby 3"
            value={hobby3}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />

          <TextField
            id="hobby6"
            label="Hobby 6"
            value={hobby6}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
        </LineBlock>
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    hobby1: state.form.hobby1,
    hobby2: state.form.hobby2,
    hobby3: state.form.hobby3,
    hobby4: state.form.hobby4,
    hobby5: state.form.hobby5,
    hobby6: state.form.hobby6
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(HobbyBlock));
