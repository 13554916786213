import React, { useRef, useEffect } from "react";
import moment from "moment";
import "moment/locale/ru";

import { Card, Name, Company, Phone, Photo, Age, BDay } from "./index.style";

const GridCard = ({
  id,
  name = "",
  surname = "",
  patronymic = "",
  company,
  handleClick,
  foto,
  tag1,
  focus,
  bday,
  birthdate
}) => {
  const ref = useRef(null);

  const getNameSize = () => {
    if (name.length > 13 || surname.length > 13 || patronymic.length > 13) {
      return "16px";
    }
  };

  useEffect(() => {
    if (ref.current && focus) {
      ref.current.scrollIntoView();
    }
  });

  return (
    <Card onClick={handleClick} id={`card-${id}`} ref={ref}>
      <Photo>
        {/* <img src={foto} width="100px" height="100px" alt="user" style={{ objectFit: 'cover' }} /> */}
        <div
          style={{
            backgroundImage: `url(${foto})`,
            width: "100%",
            height: "150px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPositionY: "25%"
          }}
        ></div>
      </Photo>
      <Name size={getNameSize()}>{`${name} ${patronymic} ${surname}`}</Name>
      {/* <BDay>{getDate(birthdate)}</BDay> */}
      {!bday && (
        <Company>
          {company && company.length > 33
            ? `${company.slice(0, 33)}...`
            : company}
        </Company>
      )}
      {bday && birthdate && (
        <>
          <BDay>{moment(birthdate).format("D MMMM")}</BDay>
          <Age>{moment().diff(birthdate, "years") || ""}</Age>
        </>
      )}
      <Phone>
        <div>{tag1}</div>
        <div></div>
      </Phone>
    </Card>
  );
};

export default GridCard;
