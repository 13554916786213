import React, { memo, useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import {
  Container,
  Label,
  Block,
  TextBlock,
  FullNameBlock,
  ImageBlock
} from "./index.style";
import { changeFormValue } from "../../actions/form";
import TextField from "@material-ui/core/TextField";

const SharedNameBlock = ({
  foto,
  name,
  surname,
  patronymic,
  tel1,
  email1,
  city,
  country
}) => {
  const [image, setImage] = useState("");

  const getBase64 = async url => {
    //console.log(url);
    const img = await axios.get(url, { responseType: "arraybuffer" });
    let image = btoa(
      new Uint8Array(img.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    return `data:${img.headers["content-type"].toLowerCase()};base64,${image}`;
  };

  useEffect(() => {
    (async () => {
      if (foto) {
        const img = await getBase64(foto);
        setImage(img);
      }
    })();
    //eslint-disable-next-line
  }, [foto]);

  return (
    <Block>
      <Label>Личные данные</Label>

      <Container>
        <ImageBlock>
          {image && <img src={image} alt="person" width="100px"></img>}
        </ImageBlock>

        <TextBlock>
          <FullNameBlock>
            <TextField
              id="surname"
              label="Surname"
              value={surname}
              margin="normal"
              InputProps={{
                readOnly: true
              }}
            />
            <TextField
              id="name"
              label="Name"
              value={name}
              margin="normal"
              InputProps={{
                readOnly: true
              }}
            />

            <TextField
              id="patronymic"
              label="Patronymic"
              value={patronymic}
              margin="normal"
              InputProps={{
                readOnly: true
              }}
            />
          </FullNameBlock>
          <FullNameBlock>
            <TextField
              id="city"
              label="City"
              value={city}
              margin="normal"
              InputProps={{
                readOnly: true
              }}
            />
            <TextField
              id="country"
              label="Country"
              value={country}
              margin="normal"
              InputProps={{
                readOnly: true
              }}
            />
          </FullNameBlock>
        </TextBlock>
        <TextBlock woBorder={true}>
          <Container>
            <TextField
              label="Tel 1"
              value={tel1}
              margin="normal"
              fullWidth
              InputProps={{
                readOnly: true
              }}
            />
          </Container>
          <Container>
            <TextField
              label="Email 1"
              value={email1}
              margin="normal"
              fullWidth
              InputProps={{
                readOnly: true
              }}
            />
          </Container>
        </TextBlock>
      </Container>
    </Block>
  );
};

const mapStateToProps = state => {
  return {
    foto: state.form.foto,
    name: state.form.name,
    surname: state.form.surname,
    patronymic: state.form.patronymic,
    city: state.form.city,
    country: state.form.country,
    tel1: state.form.tel1,
    email1: state.form.email1
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(SharedNameBlock)
);
