import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

import { withFirestore, withFirebase } from "react-redux-firebase";
import { compose } from "redux";

import Button from "@material-ui/core/Button";
import BackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Build";
import ShareIcon from "@material-ui/icons/Share";
import CopyIcon from "@material-ui/icons/FileCopy";

import {
  saveDataFirestore,
  updateDataFirestore,
  deleteDataFirestore,
  updateConnectionFirestore,
  updateConnectionDescFirestore
  //migrateDataFirestore
} from "../../actions/firestoreActions";
import {
  Container,
  ControlsBlock,
  MainContainer,
  ShareBlock,
  ShareBlockHidden
} from "./index.style";
import NameBlock from "../NameBlock";
import ContactBlock from "../ContactBlock";
import AdditionalInfoBlock from "../AdditionalInfoBlock";
import PrivateBlock from "../PrivateBlock";
import BioBlock from "../BioBlock";
import LinksBlock from "../LinksBlock";
import ConnectionsBlock from "../ConnectionsBlock";
import FilesBlock from "../FilesBlock";
import CompetenceBlock from "../CompetenceBlock";
import { loadData, loadBlank } from "../../actions/form";
import LangBlock from "../LangBlock";
import CurrentProfActivityBlock from "../CurrentProfActivityBlock";
import CareerBlock from "../CareerBlock";
import ExtraActivityBlock from "../ExtraActivityBlock";
import HobbyBlock from "../HobbyBlock";
import ExtConnectionsBlock from "../ExtConnectionsBlock";
import CommunicationBlock from "../CommunicationBlock";
import ControlTab from "../ControlTab";
import Clipboard from "react-clipboard.js";
import { TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getData } from "../../selectors";

const UserFull = ({
  match,
  userdata = [],
  goBack,
  saveDataFirestore,
  updateDataFirestore,
  deleteDataFirestore,
  firestore,
  firebase,
  loadData,
  loadBlank,
  updateConnectionFirestore,
  updateConnectionDescFirestore,
  user,
  auth,
  db,
  demo,
  noNotes
}) => {
  //let [data, setData] = useState(blank);
  let [editMode, setEditMode] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [shareLoading, setShareLoading] = useState(false);
  const userid = match.params.userid;

  useEffect(() => {
    loadBlank();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userid !== "add" && !editMode) {
      const udata = userdata.find(el => el.id === userid) || null;
      if (udata) {
        loadData(udata);
      }
    } else if (editMode) {
    } else {
      loadBlank();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userdata, userid, loadData, loadBlank]);

  const saveData = () => {
    if (userid === "add") {
      saveDataFirestore(firestore, user, auth.uid, db);
    } else {
      updateDataFirestore(firestore, user, auth.uid, db);
    }
    handleBack();
  };

  const handleDelete = () => {
    if (window.confirm("Вы точно хотите удалить карточку?")) {
      deleteDataFirestore(firestore, userid, auth.uid, db);
      goBack();
    }
  };

  const handleEditMode = () => {
    setEditMode(true);
  };

  const handleBack = () => {
    goBack();
  };

  const checkFreeConnection = id => {
    let user = userdata.find(e => e.id === id);
    if (user) {
      for (let x = 1; x <= 5; x += 1) {
        if (!user[`connection${x}`]) {
          return x;
        }
      }
    }
    return -1;
  };

  const checkConnectionPlace = (id, userid) => {
    let user = userdata.find(e => e.id === id);
    if (user) {
      for (let x = 1; x <= 5; x += 1) {
        if (user[`connection${x}`] === userid) {
          return x;
        }
      }
    }
    return -1;
  };

  const connectionAction = (
    type,
    source,
    sourceplace,
    target,
    targetplace,
    desc
  ) => {
    switch (type) {
      case "add":
        updateConnectionFirestore(
          firestore,
          source,
          sourceplace,
          target,
          auth.uid,
          db
        );
        updateConnectionFirestore(
          firestore,
          target,
          targetplace,
          source,
          auth.uid,
          db
        );

        break;
      case "delete":
        updateConnectionFirestore(
          firestore,
          source,
          sourceplace,
          "",
          auth.uid,
          db
        );
        if (targetplace !== -1) {
          updateConnectionFirestore(
            firestore,
            target,
            targetplace,
            "",
            auth.uid,
            db
          );
        }
        break;
      case "desc":
        updateConnectionDescFirestore(
          firestore,
          source,
          sourceplace,
          desc,
          auth.uid,
          db
        );
        if (targetplace !== -1) {
          updateConnectionDescFirestore(
            firestore,
            target,
            targetplace,
            desc,
            auth.uid,
            db
          );
        }
        break;
      default:
        break;
    }
  };

  const handleShare = async () => {
    setShareLoading(true);
    const share = await firestore
      .collection("sharedLinks")
      .add({ card: userid, createdAt: Date.now(), user: auth.uid });

    setShareLink(`https://humanradar.ru/shared/${share.id}`);
    setShareLoading(false);
  };

  const checkAuth = () => {
    return false;
    //return demo;
    // const users = [
    //   "NWwiEfvB0fTw0XAb0i9Iro4KQJo2",
    //   "jaa0aXfljybIHWeZoOpcOr7kUgy2"
    // ];
    // if (users.includes(auth.uid)) {
    //   return false;
    // }
    // return true;
  };

  return (
    <MainContainer>
      <ControlTab />
      <ControlsBlock>
        <Button variant="contained" color="primary" onClick={handleBack}>
          <BackIcon />
        </Button>
        <div>
          <ShareBlock
            variant="contained"
            color="primary"
            onClick={handleShare}
            style={{ maxHeight: "36px" }}
          >
            {shareLoading ? (
              <CircularProgress
                size={24}
                style={{ color: "white" }}
                disableShrink={true}
                thickness={8}
              />
            ) : (
              <ShareIcon />
            )}

            {shareLink && (
              <ShareBlockHidden>
                <TextField
                  value={shareLink}
                  disabled
                  style={{ width: "200px" }}
                />
                <Clipboard
                  data-clipboard-text={shareLink}
                  component="i"
                  onSuccess={() => setShareLink("")}
                >
                  <CopyIcon
                    style={{
                      color: "#1E88E5",
                      cursor: "pointer",
                      marginLeft: "24px"
                    }}
                  />
                </Clipboard>
              </ShareBlockHidden>
            )}
          </ShareBlock>

          {userid !== "add" && (
            <Button
              variant="contained"
              color="secondary"
              style={{ marginLeft: "15px" }}
              onClick={handleDelete}
              disabled={checkAuth()}
            >
              <DeleteIcon />
            </Button>
          )}
          {editMode && (
            <Button
              variant="contained"
              style={{ backgroundColor: "#43A047", marginLeft: "15px" }}
              onClick={saveData}
              disabled={checkAuth()}
            >
              <SaveIcon />
            </Button>
          )}
          {!editMode && (
            <Button
              variant="contained"
              style={{ backgroundColor: "#43A047", marginLeft: "15px" }}
              onClick={handleEditMode}
              disabled={checkAuth()}
            >
              <EditIcon />
            </Button>
          )}
        </div>
      </ControlsBlock>
      <Container>
        <NameBlock editMode={editMode} firebase={firebase} />
        <ContactBlock editMode={editMode} />
        <AdditionalInfoBlock editMode={editMode} />
        <CurrentProfActivityBlock editMode={editMode} />
        {!demo && <CareerBlock editMode={editMode} />}
        <CompetenceBlock editMode={editMode} />
        <ExtraActivityBlock editMode={editMode} />

        <PrivateBlock editMode={editMode} />
        <HobbyBlock editMode={editMode} />
        <BioBlock editMode={editMode} />

        <FilesBlock editMode={editMode} firebase={firebase} noNotes={noNotes} />
        <LangBlock editMode={editMode} />
        <LinksBlock editMode={editMode} />

        <ConnectionsBlock
          editMode={editMode}
          userdata={userdata}
          userid={userid}
          checkFreeConnection={checkFreeConnection}
          connectionAction={connectionAction}
          checkConnectionPlace={checkConnectionPlace}
        />

        {!demo && <ExtConnectionsBlock editMode={editMode} />}
        <CommunicationBlock editMode={editMode} firebase={firebase} />
      </Container>
    </MainContainer>
  );
};

const mapStateToProps = state => {
  return {
    userdata: getData(state),
    user: state.form,
    auth: state.firebase.auth,
    db: state.crud.userInfo.db,
    demo: state.crud.userInfo.demo,
    noNotes: state.crud.userInfo.nonotes
  };
};

const mapDispatchToProps = {
  goBack,
  saveDataFirestore,
  updateDataFirestore,
  deleteDataFirestore,
  updateConnectionFirestore,
  loadData,
  loadBlank,
  updateConnectionDescFirestore
};

const enhance = compose(
  withFirestore,
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(UserFull);
