import React, { memo } from "react";
import { connect } from "react-redux";
import { Container, InfoLine, Label } from "./index.style";
import TextField from "@material-ui/core/TextField";
import { changeFormValue } from "../../actions/form";

const CompetenceBlock = ({
  changeFormValue,
  editMode,
  professional1,
  professional2,
  professional3,
  professional4,
  professional5,
  professional6,
  professional7,
  professional8
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Container>
      <Label>Компетенции</Label>
      <InfoLine>
        <TextField
          id="professional1"
          label="Professional 1"
          value={professional1}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="professional2"
          label="Professional 2"
          value={professional2}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="professional3"
          label="Professional 3"
          value={professional3}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="professional4"
          label="Professional 4"
          value={professional4}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="professional5"
          label="Professional 5"
          value={professional5}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="professional6"
          label="Professional 6"
          value={professional6}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="professional7"
          label="Professional 7"
          value={professional7}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="professional8"
          label="Professional 8"
          value={professional8}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    professional1: state.form.professional1,
    professional2: state.form.professional2,
    professional3: state.form.professional3,
    professional4: state.form.professional4,
    professional5: state.form.professional5,
    professional6: state.form.professional6,
    professional7: state.form.professional7,
    professional8: state.form.professional8
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(CompetenceBlock)
);
