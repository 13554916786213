import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

import filterReducer from "./filterReducer";
import authReducer from "./authReducer";
import formReducer from "./formReducer";
import viewReducer from "./viewReducer";
import mapReducer from "./map";
import crudReducer from "./crudReducer";

const rootReducer = history =>
  combineReducers({
    //crud: crudReducer,
    map: mapReducer,
    filter: filterReducer,
    auth: authReducer,
    form: formReducer,
    view: viewReducer,
    crud: crudReducer,
    router: connectRouter(history),
    firestore: firestoreReducer,
    firebase: firebaseReducer
  });
export default rootReducer;
