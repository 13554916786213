import React from "react";
import { Provider } from "react-redux";
import Router from "../../routers";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { YMInitializer } from 'react-yandex-metrika';

import store, { rrfProps } from "../../store";

const EntryPoint = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <YMInitializer
          accounts={[64921282]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
          }}
        />
        <Router />
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default EntryPoint;
