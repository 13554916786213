import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import readXlsxFile from "read-excel-file";
import Button from "@material-ui/core/Button";
import { withFirestore } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { importDataFirestore } from "../../actions/firestoreActions";
import moment from "moment";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";

const ExcelLoader = ({
  firestore,
  importDataFirestore,
  disabled,
  auth,
  db,
  noNotes
}) => {
  const [loading, setLoading] = useState(false);

  let schema = {
    name: {
      prop: "name",
      type: String
    },
    surname: {
      prop: "surname",
      type: String
    },
    patronymic: {
      prop: "patronymic",
      type: String
    },
    tag1: {
      prop: "tag1",
      type: String
    },
    tag2: {
      prop: "tag2",
      type: String
    },
    tag3: {
      prop: "tag3",
      type: String
    },
    tag4: {
      prop: "tag4",
      type: String
    },
    tag5: {
      prop: "tag5",
      type: String
    },
    /* industry1: {
      prop: "industry1",
      type: String
    },
    industry2: {
      prop: "industry2",
      type: String
    },
    industry3: {
      prop: "industry3",
      type: String
    },*/
    birthdate: {
      prop: "birthdate",
      type: Date
    },
    city: {
      prop: "city",
      type: String
    },
    geo1: {
      prop: "geo1",
      type: String
    },
    geo2: {
      prop: "geo2",
      type: String
    },
    /*company: {
      prop: "company",
      type: String
    },
    position1: {
      prop: "position1",
      type: String
    },
    position2: {
      prop: "position2",
      type: String
    },*/
    tel1: {
      prop: "tel1",
      type: String
    },
    tel2: {
      prop: "tel2",
      type: String
    },
    email1: {
      prop: "email1",
      type: String
    },
    email2: {
      prop: "email2",
      type: String
    },
    assistant: {
      prop: "assistant",
      type: String
    },
    hobby1: {
      prop: "hobby1",
      type: String
    },
    hobby2: {
      prop: "hobby2",
      type: String
    },
    hobby3: {
      prop: "hobby3",
      type: String
    },
    hobby4: {
      prop: "hobby4",
      type: String
    },
    hobby5: {
      prop: "hobby5",
      type: String
    },
    spousename: {
      prop: "spousename",
      type: String
    },
    info1: {
      prop: "info1",
      type: String
    },
    info2: {
      prop: "info2",
      type: String
    },
    /*kids: {
      prop: "kids",
      type: String
    },*/
    country: {
      prop: "country",
      type: String
    },
    placeofbirth: {
      prop: "placeofbirth",
      type: String
    },
    mother: {
      prop: "mother",
      type: String
    },
    father: {
      prop: "father",
      type: String
    },
    siblings: {
      prop: "siblings",
      type: String
    },
    childhoodact: {
      prop: "childhoodact",
      type: String
    },
    lifestory1: {
      prop: "lifestory1",
      type: String
    },
    lifestory2: {
      prop: "lifestory2",
      type: String
    },
    education1: {
      prop: "education1",
      type: String
    },
    major1: {
      prop: "major1",
      type: String
    },
    years1: {
      prop: "years1",
      type: String
    },
    education2: {
      prop: "education2",
      type: String
    },
    major2: {
      prop: "major2",
      type: String
    },
    years2: {
      prop: "years2",
      type: String
    },
    education3: {
      prop: "education3",
      type: String
    },
    major3: {
      prop: "major3",
      type: String
    },
    years3: {
      prop: "years3",
      type: String
    },
    company1: {
      prop: "company1",
      type: String
    },
    company2: {
      prop: "company2",
      type: String
    },
    company3: {
      prop: "company3",
      type: String
    },
    company4: {
      prop: "company4",
      type: String
    },
    company5: {
      prop: "company5",
      type: String
    },
    company6: {
      prop: "company6",
      type: String
    },
    professional1: {
      prop: "professional1",
      type: String
    },
    professional2: {
      prop: "professional2",
      type: String
    },
    professional3: {
      prop: "professional3",
      type: String
    },
    professional4: {
      prop: "professional4",
      type: String
    },
    professional5: {
      prop: "professional5",
      type: String
    },
    professional6: {
      prop: "professional6",
      type: String
    },
    language1: {
      prop: "language1",
      type: String
    },
    language2: {
      prop: "language2",
      type: String
    },
    language3: {
      prop: "language3",
      type: String
    },
    language4: {
      prop: "language4",
      type: String
    },
    level1: {
      prop: "level1",
      type: String
    },
    level2: {
      prop: "level2",
      type: String
    },
    level3: {
      prop: "level3",
      type: String
    },
    level4: {
      prop: "level4",
      type: String
    },
    links1: {
      prop: "links1",
      type: String
    },
    links2: {
      prop: "links2",
      type: String
    },
    links3: {
      prop: "links3",
      type: String
    },
    links4: {
      prop: "links4",
      type: String
    },
    links5: {
      prop: "links5",
      type: String
    },
    links6: {
      prop: "links6",
      type: String
    },
    linksdesc1: {
      prop: "linksdesc1",
      type: String
    },
    linksdesc2: {
      prop: "linksdesc2",
      type: String
    },
    linksdesc3: {
      prop: "linksdesc3",
      type: String
    },
    linksdesc4: {
      prop: "linksdesc4",
      type: String
    },
    linksdesc5: {
      prop: "linksdesc5",
      type: String
    },
    linksdesc6: {
      prop: "linksdesc6",
      type: String
    },
    connection1: {
      prop: "connection1",
      type: String
    },
    connection2: {
      prop: "connection2",
      type: String
    },
    connection3: {
      prop: "connection3",
      type: String
    },
    connection4: {
      prop: "connection4",
      type: String
    },
    connection5: {
      prop: "connection5",
      type: String
    },
    connection6: {
      prop: "connection6",
      type: String
    },
    connectiondesc1: {
      prop: "connectiondesc1",
      type: String
    },
    connectiondesc2: {
      prop: "connectiondesc2",
      type: String
    },
    connectiondesc3: {
      prop: "connectiondesc3",
      type: String
    },
    connectiondesc4: {
      prop: "connectiondesc4",
      type: String
    },
    connectiondesc5: {
      prop: "connectiondesc5",
      type: String
    },
    connectiondesc6: {
      prop: "connectiondesc6",
      type: String
    }
  };

  const newFields = [
    "profile1",
    "profile2",
    "cont_field1",
    "cont_field2",
    "cont_field3",
    "tag6",
    "info3",
    "info4",
    "prof1_activity",
    "prof1_position1",
    "prof1_position2",
    "prof1_industry1",
    "prof1_industry2",
    "prof1_industry3",
    "prof2_activity",
    "prof2_position1",
    "prof2_position2",
    "prof2_industry1",
    "prof2_industry2",
    "prof2_industry3",
    "prof3_activity",
    "prof3_position1",
    "prof3_position2",
    "prof3_industry1",
    "prof3_industry2",
    "prof3_industry3",
    "company1_about",
    "company1_industry",
    "company1_position",
    "company1_years",
    "company2_about",
    "company2_industry",
    "company2_position",
    "company2_years",
    "company2_about",
    "company2_industry",
    "company2_position",
    "company2_years",
    "company3_about",
    "company3_industry",
    "company3_position",
    "company3_years",
    "company4_about",
    "company4_industry",
    "company4_position",
    "company4_years",
    "company5_about",
    "company5_industry",
    "company5_position",
    "company5_years",
    "company6_about",
    "company6_industry",
    "company6_position",
    "company6_years",
    "professional7",
    "professional8",
    "addactivity1_activity",
    "addactivity1_about",
    "addactivity1_position",
    "addactivity2_activity",
    "addactivity2_about",
    "addactivity2_position",
    "spouse_work",
    "spouse_details",
    "kid1",
    "kid2",
    "kid3",
    "kid4",
    "hobby6",
    "bio_lived1",
    "bio_lived2",
    "bio_addinfo",
    "education1_addinfo",
    "education2_addinfo",
    "education3_addinfo",
    "extlinks1",
    "extlinks2",
    "extlinks3",
    "extlinks4",
    "extlinks5",
    "extlinks6",
    "extlinksdesc1",
    "extlinksdesc2",
    "extlinksdesc3",
    "extlinksdesc4",
    "extlinksdesc5",
    "extlinksdesc6",
    "interaction1",
    "interaction1_location",
    "interaction1_notes",
    "interaction1_act1",
    "interaction1_act2",
    "interaction1_act3",
    "interaction2",
    "interaction2_location",
    "interaction2_notes",
    "interaction2_act1",
    "interaction2_act2",
    "interaction2_act3",
    "interaction3",
    "interaction3_location",
    "interaction3_notes",
    "interaction3_act1",
    "interaction3_act2",
    "interaction3_act3",
    "notes"
  ];

  const newDateFields = [
    "spouse_birthdate",
    "kid1_birthdate",
    "kid2_birthdate",
    "kid3_birthdate",
    "kid4_birthdate",
    "interaction1_date",
    "interaction2_date",
    "interaction3_date"
  ];

  //console.log(schema);
  newDateFields.forEach(e => {
    schema[e] = {
      prop: e,
      type: Date
    };
  });

  newFields.forEach(e => {
    schema[e] = {
      prop: e,
      type: String
    };
  });

  const filePrepare = async file => {
    setLoading(true);
    if (/.xlsx/.test(file.name)) {
      await handleRead(file);
    } else if (/.zip/.test(file.name)) {
      let formData = new FormData();
      formData.append("file", file);

      const { data } = await axios.post(
        "https://us-central1-alafia-tech.cloudfunctions.net/uplZip",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      console.log(data);
      importDataFirestore(firestore, data, auth.uid, db);
    }
    setLoading(false);
  };

  const handleRead = async file => {
    let res = await readXlsxFile(file, { schema });
    let data = res.rows.map(el => {
      if (el.birthdate) {
        el.birthdate = moment(el.birthdate).toISOString();
      }
      if (el.spouse_birthdate) {
        el.spouse_birthdate = moment(el.spouse_birthdate).toISOString();
      }
      if (el.kid1_birthdate) {
        el.kid1_birthdate = moment(el.kid1_birthdate).toISOString();
      }
      if (el.kid2_birthdate) {
        el.kid2_birthdate = moment(el.kid2_birthdate).toISOString();
      }
      if (el.kid3_birthdate) {
        el.kid3_birthdate = moment(el.kid3_birthdate).toISOString();
      }
      if (el.kid4_birthdate) {
        el.kid4_birthdate = moment(el.kid4_birthdate).toISOString();
      }
      if (el.interaction1_date) {
        el.interaction1_date = moment(el.interaction1_date).toISOString();
      }
      if (el.interaction2_date) {
        el.interaction2_date = moment(el.interaction2_date).toISOString();
      }
      if (el.interaction3_date) {
        el.interaction3_date = moment(el.interaction3_date).toISOString();
      }

      if (el.notes && noNotes) {
        delete el.notes;
      }

      return el;
    });

    importDataFirestore(firestore, data, auth.uid, db);
  };

  const onDrop = acceptedFiles => {
    acceptedFiles.forEach(file => {
      filePrepare(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} disabled={disabled} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <>
          {loading && <CircularProgress size={24} />}
          <Button
            style={{ backgroundColor: "#43A047", marginLeft: "20px" }}
            disabled={disabled}
          >
            Импорт из файла
          </Button>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    db: state.crud.userInfo.db
  };
};

const mapDispatchToProps = {
  importDataFirestore
};

const enhance = compose(
  withFirestore,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ExcelLoader);
