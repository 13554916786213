import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CirIcon from "@material-ui/icons/DeviceHub";
import MapIcon from "@material-ui/icons/Map";
import PlotIcon from "@material-ui/icons/ScatterPlot";
import QuizIcon from "@material-ui/icons/Help";
import MainIcon from "@material-ui/icons/Domain";
import EventIcon from "@material-ui/icons/Event";
import MergeIcon from "@material-ui/icons/CallMerge";
import MeetIcon from "@material-ui/icons/RecordVoiceOver";

const DrawerBlock = ({ handleClose, push, demo }) => {
  /*const handleImport = () => {
    push("/");
  };*/

  return (
    <>
      <Drawer open={true} onClose={handleClose}>
        <div role="presentation" onClick={handleClose} onKeyDown={handleClose}>
          <List>
            <ListItem button onClick={() => push("/")}>
              <ListItemIcon>
                <MainIcon />
              </ListItemIcon>
              <ListItemText primary="На главную" />
            </ListItem>

            {/* <ListItem button onClick={handleImport}>
              <ListItemIcon>
                <ImpIcon />
              </ListItemIcon>
              <ListItemText primary="Импорт из файла" />
            </ListItem>*/}

            <ListItem button onClick={() => push("/map")}>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText primary="RADAR Map" />
            </ListItem>

            {demo && (
              <ListItem button onClick={() => push("/radarcircles")}>
                <ListItemIcon>
                  <CirIcon />
                </ListItemIcon>
                <ListItemText primary="RADAR Circles" />
              </ListItem>
            )}
            {!demo && (
              <ListItem button onClick={() => push("/circles")}>
                <ListItemIcon>
                  <CirIcon />
                </ListItemIcon>
                <ListItemText primary="RADAR Circles" />
              </ListItem>
            )}

            <ListItem button onClick={() => push("/bdayradar")}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText primary="RADAR Birthdays" />
            </ListItem>

            <ListItem button onClick={() => push("/connections")}>
              <ListItemIcon>
                <PlotIcon />
              </ListItemIcon>
              <ListItemText primary="RADAR Connections" />
            </ListItem>

            <ListItem button onClick={() => push("/quiz")}>
              <ListItemIcon>
                <QuizIcon />
              </ListItemIcon>
              <ListItemText primary="RADAR Games" />
            </ListItem>

            <ListItem button onClick={() => push("/meet")}>
              <ListItemIcon>
                <MeetIcon />
              </ListItemIcon>
              <ListItemText primary="RADAR Meetings" />
            </ListItem>

            <ListItem button onClick={() => push("/merge")}>
              <ListItemIcon>
                <MergeIcon />
              </ListItemIcon>
              <ListItemText primary="RADAR Merge" />
            </ListItem>

            <Divider />
          </List>
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = state => {
  return {
    demo: state.crud.userInfo.demo
  };
};

const mapDispatchToProps = {
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerBlock);
