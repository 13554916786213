import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 16px;
`;

export const ButtonsBlock = styled.div`
  align-self: flex-end;
`;

export const TextBlockLarge = styled.div`
  font-size: 18px;
  margin-bottom: 20px;
`;

export const TextBlockStd = styled.div`
  margin-bottom: 16px;
`;

export const ImageBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 12px;
  width: 240px;
  float: left;
`;

export const HorFlex = styled.div`
  display: flex;
  width: 100%;
`;

export const VerFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LineElement = styled.div`
  grid-area: ${({ area }) => area};
  //border: 1px solid red;
  @media (max-width: 1100.98px) {
    display: ${({ area }) => (area === "a" || area === "b" ? "none" : "block")};
  }
`;

export const BorderedBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  border: 2px dashed royalblue;
  margin-right: 12px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 100px 100px 290px 1fr;
  grid-template-rows: repeat(3, 180px);
  align-items: center;
  row-gap: 10px;
  grid-template-areas:
    "a b c d"
    "a b e f"
    "a b g h";

  @media (max-width: 1100.98px) {
    grid-template-areas:
      "c d"
      "e f"
      "g h";
    grid-template-columns: 290px 1fr;
  }
`;
