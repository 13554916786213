import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const Container = styled.div`
  //display: grid;
  //grid-template-columns: 1fr;
  //gap: 15px;
  display: flex;
  flex-direction: column;
`;

export const MainContainer = styled.div`
  max-width: 100%;
`;

const Block = styled.div`
  display: grid;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  padding: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  grid-template-columns: 1fr;
`;

export const ControlsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const Label = styled.h2``;

export const DoubleBlock = styled(Block)`
  grid-column: 1 / span 3;
  grid-template-columns: 50% 50%;
  gap: 15px;
`;

export const ShareBlock = styled(Button)`
  position: relative;

  &:hover {
    & > * > div {
      display: flex;
    }
  }
`;

export const ShareBlockHidden = styled.div`
  display: flex;
  align-items: center;
  
  position: absolute;
  top: 110%;
  left: -150px;
  width: 250px;
  height: 50px;
  padding: 0 12px;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 5px;
`;
