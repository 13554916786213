import React, { memo } from "react";
import { connect } from "react-redux";
import { Container, Content, FileBlock, FlexBlock, Label } from "./index.style";
import { changeFormValue } from "../../actions/form";
import FileWrapper from "../FileWrapper";
import TextField from "@material-ui/core/TextField";

const FilesBlock = ({
  file1,
  file2,
  file3,
  file4,
  file5,
  file6,
  file7,
  file8,
  file9,
  file10,
  notes,
  noNotes,
  editMode,
  changeFormValue,
  firebase
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const deleteFile = id => () => {
    changeFormValue(id, "");
  };

  const onUploadFile = id => e => {
    const file = e.target.files[0];
    let storageRef = firebase.storage().ref();
    let uploadTask = storageRef
      .child(`uploads/${new Date().getTime()}-${file.name}`)
      .put(file);

    uploadTask.on(
      "state_changed",
      function(snapshot) {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING:
            console.log("Upload is running");
            changeFormValue(id, "loading");
            break;
          default:
            break;
        }
      },
      function(error) {
        // Handle unsuccessful uploads
      },
      function() {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          changeFormValue(id, downloadURL);
        });
      }
    );
  };

  const smFont = { style: { fontSize: 14 } };

  return (
    <Container>
      <Label>{noNotes ? "Файлы" : "Заметки и Файлы"}</Label>
      <Content>
        {!noNotes && (
          <FlexBlock>
            <h4>Заметки</h4>
            <TextField
              id="notes"
              value={notes}
              placeholder="Заметки"
              multiline={true}
              fullWidth
              rows={13}
              rowsMax={13}
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              onChange={handleChange}
            />
          </FlexBlock>
        )}
        <FlexBlock width="500px">
          <h4>Файлы</h4>
          <FileBlock>
            <FileWrapper
              name="file1"
              link={file1}
              deleteFile={deleteFile}
              onUploadFile={onUploadFile}
              withDelete={editMode}
            />
            <FileWrapper
              name="file2"
              link={file2}
              deleteFile={deleteFile}
              onUploadFile={onUploadFile}
              withDelete={editMode}
            />
            <FileWrapper
              name="file3"
              link={file3}
              deleteFile={deleteFile}
              onUploadFile={onUploadFile}
              withDelete={editMode}
            />
            <FileWrapper
              name="file4"
              link={file4}
              deleteFile={deleteFile}
              onUploadFile={onUploadFile}
              withDelete={editMode}
            />
            <FileWrapper
              name="file5"
              link={file5}
              deleteFile={deleteFile}
              onUploadFile={onUploadFile}
              withDelete={editMode}
            />
            <FileWrapper
              name="file6"
              link={file6}
              deleteFile={deleteFile}
              onUploadFile={onUploadFile}
              withDelete={editMode}
            />
            <FileWrapper
              name="file7"
              link={file7}
              deleteFile={deleteFile}
              onUploadFile={onUploadFile}
              withDelete={editMode}
            />
            <FileWrapper
              name="file8"
              link={file8}
              deleteFile={deleteFile}
              onUploadFile={onUploadFile}
              withDelete={editMode}
            />
            <FileWrapper
              name="file9"
              link={file9}
              deleteFile={deleteFile}
              onUploadFile={onUploadFile}
              withDelete={editMode}
            />
            <FileWrapper
              name="file10"
              link={file10}
              deleteFile={deleteFile}
              onUploadFile={onUploadFile}
              withDelete={editMode}
            />
          </FileBlock>
        </FlexBlock>
      </Content>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    file1: state.form.file1,
    file2: state.form.file2,
    file3: state.form.file3,
    file4: state.form.file4,
    file5: state.form.file5,
    file6: state.form.file6,
    file7: state.form.file7,
    file8: state.form.file8,
    file9: state.form.file9,
    file10: state.form.file10,
    notes: state.form.notes
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(FilesBlock));
