import styled from 'styled-components';

export const Container = styled.div`
  justify-self: end;
  display: grid;

  grid-template-columns: repeat(2, 65px);
  border-radius: 25px;
  background-color: rgba(0, 0, 0, 0.12);
  color: #aaa;
  height: 35px;
`;
