import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import supercluster from "points-cluster";
import MarkerIcon from "@material-ui/icons/Room";
import { push } from "connected-react-router";
import { setFilter } from "../../actions/filterActions";
import ControlTab from "../ControlTab";

import {
  Container,
  MarkerCounter,
  MarkerGroup,
  MarkerInGroupStyled,
  MarkerStyled
} from "./index.style";
import { CircularProgress } from "@material-ui/core";
import { mapMove } from "../../actions/map";
import { getData } from "../../selectors";

const Marker = ({ inGroup = false, handleClick }) => {
  return (
    <div onClick={handleClick}>
      {inGroup ? (
        <MarkerInGroupStyled>
          <MarkerIcon />
        </MarkerInGroupStyled>
      ) : (
        <MarkerStyled>
          <MarkerIcon />
        </MarkerStyled>
      )}
    </div>
  );
};

const ClusterMarker = ({ points, handleClick }) => {
  return (
    <MarkerGroup length={points.length} onClick={handleClick}>
      <MarkerCounter>{points.length}</MarkerCounter>
    </MarkerGroup>
  );
};

const RadarMap = ({ userdata, push, setFilter, mapMove, mapOptions }) => {
  const [cluster, setCluster] = useState([]);
  const [latlngData, setlatlngData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userdata) {
      const f = userdata
        .filter(e => e.lat && e.lng)
        .map(({ id, lat, lng }) => ({ id, lat, lng }));
      setlatlngData(f);
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [userdata]);

  const recall = f => {
    const clusters = supercluster(f, {
      minZoom: 0,
      maxZoom: 16,
      radius: 60
    });
    setCluster(
      clusters(mapOptions).map(({ wx, wy, numPoints, points }) => ({
        lat: wy,
        lng: wx,
        numPoints,
        //id: `${numPoints}_${points[0].id}`,
        id: points[0].id,
        points
      }))
    );
  };

  const handleMapChange = ({ center, zoom, bounds }) => {
    mapMove({
      center,
      zoom,
      bounds
    });
  };

  const handleClick = id => {
    setFilter({ id: id.join(",") });
    push("/");
  };

  useEffect(() => {
    if (mapOptions.bounds && latlngData) {
      recall(latlngData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapOptions, latlngData]);

  return (
    <Container>
      <ControlTab />
      {loading && <CircularProgress size={50} style={{ margin: "0 auto" }} />}
      {!loading && (
        <div style={{ height: "100%", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyAxOBwSrruy4SUcMMjSq9nLgzOji4YZHMA"
            }}
            center={mapOptions.center}
            zoom={mapOptions.zoom}
            options={{ maxZoom: 19 }}
            onChange={handleMapChange}
          >
            {cluster.map(item => {
              if (item.numPoints === 1) {
                return (
                  <Marker
                    id={item.id}
                    key={item.id}
                    lat={item.points[0].lat}
                    lng={item.points[0].lng}
                    handleClick={() => {
                      handleClick([item.id]);
                    }}
                  />
                );
              }

              return (
                <ClusterMarker
                  key={item.id}
                  lat={item.lat}
                  lng={item.lng}
                  points={item.points}
                  handleClick={() => {
                    handleClick(item.points.map(e => e.id));
                  }}
                />
              );
            })}
          </GoogleMapReact>
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    userdata: getData(state),
    mapOptions: state.map
  };
};

const mapDispatchToProps = {
  push,
  setFilter,
  mapMove
};

export default connect(mapStateToProps, mapDispatchToProps)(RadarMap);
