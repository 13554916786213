import styled from "styled-components";

export const Card = styled.div`
  display: grid;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.6);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 14px;
  grid-template-areas:
    "photo"
    "name"
    "company"
    "bday"
    "age"
    "phone";

  cursor: pointer;
`;

export const Name = styled.div`
  grid-area: name;
  font-size: ${({ size }) => size || "18px"}
  padding: 0 10px;
`;

export const BDay = styled.div`
  grid-area: bday;
  font-size: 14px;
  font-weight: 800;
  padding: 0 10px;
`;

export const Company = styled.div`
  grid-area: company;
  font-size: 14px;
  padding: 0 10px;
`;

export const Phone = styled.div`
  grid-area: phone;
  display: grid;
  padding: 0 10px;
`;

export const Age = styled.div`
  grid-area: age;
  font-size: 14px;
  padding: 0 10px;
  color: #2196f3;
`;

export const Photo = styled.div`
  grid-area: photo;
`;
