import React, { useState } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import OkIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";

const ParsePage = () => {
  const [filesList, setFiles] = useState([]);
  const [ready, setReady] = useState([]);
  const [error, setError] = useState([]);

  const s2ab = s => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }

    return buf;
  };

  const json2excel = opts => {
    let { data = [], name = "excel", formateDate = "dd.mm.yyyy" } = opts;

    let fileNames = [];
    const myHeader = [
      "surname",
      "name",
      "patronymic",
      "birthdate",
      "city",
      "country",
      "geo1",
      "geo2",
      "profile1",
      "profile2",
      //"company",
      // "position1",
      // "position2",
      // "mobtel",
      // "officetel",
      "tel1",
      "tel2",
      "email1",
      "email2",
      "cont_field1",
      "cont_field2",
      "cont_field3",
      "tag1",
      "tag2",
      "tag3",
      "tag4",
      "tag5",
      "tag6",
      "info1",
      "info2",
      "info3",
      "info4",
      "prof1_activity",
      "prof1_position1",
      "prof1_position2",
      "prof1_industry1",
      "prof1_industry2",
      "prof1_industry3",
      "prof2_activity",
      "prof2_position1",
      "prof2_position2",
      "prof2_industry1",
      "prof2_industry2",
      "prof2_industry3",
      "prof3_activity",
      "prof3_position1",
      "prof3_position2",
      "prof3_industry1",
      "prof3_industry2",
      "prof3_industry3",
      // "industry1",
      // "industry2",
      // "industry3",
      "company1",
      "company1_about",
      "company1_industry",
      "company1_position",
      "company1_years",
      "company2",
      "company2_about",
      "company2_industry",
      "company2_position",
      "company2_years",
      "company3",
      "company3_about",
      "company3_industry",
      "company3_position",
      "company3_years",
      "company4",
      "company4_about",
      "company4_industry",
      "company4_position",
      "company4_years",
      "company5",
      "company5_about",
      "company5_industry",
      "company5_position",
      "company5_years",
      "company6",
      "company6_about",
      "company6_industry",
      "company6_position",
      "company6_years",
      "professional1",
      "professional2",
      "professional3",
      "professional4",
      "professional5",
      "professional6",
      "professional7",
      "professional8",
      "addactivity1_activity",
      "addactivity1_about",
      "addactivity1_position",
      "addactivity2_activity",
      "addactivity2_about",
      "addactivity2_position",

      "assistant",

      "spousename",
      "spouse_birthdate",
      "spouse_work",
      "spouse_details",
      //"kids",
      "kid1",
      "kid1_birthdate",
      "kid2",
      "kid2_birthdate",
      "kid3",
      "kid3_birthdate",
      "kid4",
      "kid4_birthdate",

      "hobby1",
      "hobby2",
      "hobby3",
      "hobby4",
      "hobby5",
      "hobby6",

      "placeofbirth",
      "bio_lived1",
      "bio_lived2",
      "mother",
      "father",
      "siblings",
      "bio_addinfo",
      "childhoodact",
      // "details1",
      // "details2",
      "lifestory1",
      "lifestory2",
      "education1",
      "major1",
      "years1",
      "education1_addinfo",
      "education2",
      "major2",
      "years2",
      "education2_addinfo",
      "education3",
      "major3",
      "years3",
      "education3_addinfo",
      "language1",
      "level1",
      "language2",
      "level2",
      "language3",
      "level3",
      "language4",
      "level4",
      "links1",
      "linksdesc1",
      "links2",
      "linksdesc2",
      "links3",
      "linksdesc3",
      "links4",
      "linksdesc4",
      "links5",
      "linksdesc5",
      "links6",
      "linksdesc6",
      "connection1",
      "connectiondesc1",
      "connection2",
      "connectiondesc2",
      "connection3",
      "connectiondesc3",
      "connection4",
      "connectiondesc4",
      "connection5",
      "connectiondesc5",
      "connection6",
      "connectiondesc6",
      "extlinks1",
      "extlinksdesc1",
      "extlinks2",
      "extlinksdesc2",
      "extlinks3",
      "extlinksdesc3",
      "extlinks4",
      "extlinksdesc4",
      "extlinks5",
      "extlinksdesc5",
      "extlinks6",
      "extlinksdesc6",
      "interaction1",
      "interaction1_act1",
      "interaction1_act2",
      "interaction1_act3",
      "interaction1_date",
      "interaction1_location",
      "interaction1_notes",
      "interaction2",
      "interaction2_act1",
      "interaction2_act2",
      "interaction2_act3",
      "interaction2_date",
      "interaction2_location",
      "interaction2_notes",
      "interaction3",
      "interaction3_act1",
      "interaction3_act2",
      "interaction3_act3",
      "interaction3_date",
      "interaction3_location",
      "interaction3_notes",
      "notes"
    ];
    let sheets = {};
    const ws = XLSX.utils.json_to_sheet(data, {
      dateNF: formateDate,
      header: myHeader
    });

    // add worksheet to workbook
    fileNames.push(name);
    sheets[name] = ws;

    const wb = {
      SheetNames: fileNames,
      Sheets: sheets
    };
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary"
    });

    FileSaver.saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      name + ".xlsx"
    );
  };

  const handleLoad = async e => {
    const files = [];
    for (const file of e.target.files) {
      files.push(file);
    }
    setFiles(files);

    const promises = files.map(async file => {
      let formData = new FormData();
      formData.append("file", file);
      try {
        const { data } = await axios.post(
          //"https://us-central1-bu-dev.cloudfunctions.net/pdfParser",
          "https://backend.syntelly.com/pdfParse",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
        if (data.name) {
          setReady(i => [...i, file.name]);
          return data;
        } else {
          setError(i => [...i, file.name]);
          return Promise.resolve(null);
        }
      } catch (e) {
        setError(i => [...i, file.name]);
        return Promise.resolve(null);
      }
    });

    let result = await Promise.all(promises);
    console.log(result);
    result = result
      .filter(e => e)
      .map(el => {
        const [name = "", surname = ""] = el.name.split(" ");
        const [city = "", country = ""] = el.city.split(", ");
        const email1 = el.mail || "";
        const links1 = el.linked || "";
        const linksdesc1 = "LinkedIn";
        const prof1_activity = el.company || "";
        const edu = el.edu
          .slice(-3)
          .reverse()
          .reduce((acc, { label, major, years }, idx) => {
            acc[`education${idx + 1}`] = label;
            acc[`major${idx + 1}`] = major;
            acc[`years${idx + 1}`] = years;

            return acc;
          }, {});
        const lang = el.lang
          .slice(0, 4)
          .reduce((acc, { lang = "", level = "" }, idx) => {
            acc[`language${idx + 1}`] = lang;
            acc[`level${idx + 1}`] = level;

            return acc;
          }, {});

        const exp = el.exp
          .slice(0, 6)
          .reduce((acc, { company = "", pos = "", years = "" }, idx) => {
            acc[`company${idx + 1}`] = company;
            acc[`company${idx + 1}_position`] = pos;
            acc[`company${idx + 1}_years`] = years;

            return acc;
          }, {});

        let x = {
          name,
          surname,
          email1,
          city,
          country,
          links1,
          linksdesc1,
          prof1_activity,
          ...edu,
          ...lang,
          ...exp
        };
        return x;
      });
    json2excel({
      data: result,
      name: "linkedParser",
      formateDate: "dd.mm.yyyy"
    });
  };

  return (
    <div>
      <input
        accept=".pdf"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        multiple
        onChange={handleLoad}
      />
      <label htmlFor="raised-button-file">
        <Button color="primary" component="span">
          Load
        </Button>
      </label>
      <div>
        {filesList.map(({ name }, idx) => {
          return (
            <div key={idx}>
              {name}
              {error.includes(name) && <ErrorIcon />}
              {ready.includes(name) && <OkIcon />}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ParsePage;
