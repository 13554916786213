import React, { useState, useEffect } from "react";
import { Container, IconBlock } from "./index.style";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PersonIcon from "@material-ui/icons/Person";

const ConnectionView = ({
  id,
  connection,
  desc,
  editMode,
  data,
  handleChange,
  changeFormValue,
  deleteConnection,
  addConnection,
  setDescription
}) => {
  const [user, setUser] = useState(null);
  const [value, setValue] = useState({ id: "", name: "", surname: "" });

  const genName = u => {
    return `${u.name} ${u.surname}`;
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  const onCh = (e, val) => {
    if (val === null) {
      let oldId = value.id;
      deleteConnection(oldId, id);
      changeFormValue(`connectiondesc${id}`, "");
      changeFormValue(`connection${id}`, "");
    } else if (value && value.id && val !== value.id) {
      let oldId = value.id;
      deleteConnection(oldId, id);
      changeFormValue(`connectiondesc${id}`, "");
      changeFormValue(`connection${id}`, "");
      let resp = addConnection(val.id, id);
      if (resp.error) {
        alert(resp.msg);
      } else {
        changeFormValue(`connection${id}`, val.id);
      }
    } else {
      let resp = addConnection(val.id, id);
      if (resp.error) {
        alert(resp.msg);
      } else {
        changeFormValue(`connection${id}`, val.id);
      }
    }

    setValue(val);
  };

  const leaveDesc = () => {
    setDescription(value.id, id, desc);
  };

  useEffect(() => {
    if (data && connection) {
      const user = data.find(el => el.id === connection);
      if (user) {
        setUser(user);
      }
    }
  }, [data, connection]);

  useEffect(() => {
    if (editMode) {
      const user = data.find(el => el.id === connection);
      if (user) {
        setValue(user);
      }
    }
  }, [editMode, data, connection]);

  return (
    <Container>
      {user && !editMode && (
        <IconBlock>
          <a
            href={`https://${window.location.hostname}:${window.location.port}/user/${connection}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PersonIcon />
          </a>
        </IconBlock>
      )}
      {value && value.id && editMode && (
        <IconBlock>
          <a
            href={`http://${window.location.hostname}:${window.location.port}/user/${value.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PersonIcon />
          </a>
        </IconBlock>
      )}
      {user && !editMode && (
        <TextField
          id={`connection${id}`}
          label={`Name ${id}`}
          value={genName(user)}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          //inputProps={smFont}
          style={{ marginRight: "15px" }}
          fullWidth
          onChange={handleChange}
        />
      )}
      {!editMode && (!connection || !user) && (
        <TextField
          //id={`connection${id}`}
          label={`Name ${id}`}
          //value={""}
          margin="normal"
          InputProps={{
            readOnly: true
          }}
          //inputProps={smFont}
          style={{ marginRight: "15px" }}
          fullWidth
          //onChange={handleChange}
        />
      )}

      {editMode && (
        <>
          <Autocomplete
            options={data.map(el => {
              return {
                surname: el.surname,
                name: el.name,
                id: el.id
              };
            })}
            onChange={onCh}
            getOptionLabel={option => `${option.name} ${option.surname}`}
            value={value}
            renderInput={params => (
              <TextField
                {...params}
                label={`Name ${id}`}
                placeholder={`Connection`}
                margin="normal"
                fullWidth
                style={{ minWidth: "300px" }}
                //inputProps={smFont}
              />
            )}
          />
          <div style={{ width: "35px" }}></div>
        </>
      )}

      <TextField
        id={`connectiondesc${id}`}
        label="Details"
        value={desc}
        margin="normal"
        InputProps={{
          readOnly: !editMode
        }}
        inputProps={smFont}
        fullWidth
        onChange={handleChange}
        disabled={!user}
        onBlur={leaveDesc}
      />
    </Container>
  );
};

export default ConnectionView;
