import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAxOBwSrruy4SUcMMjSq9nLgzOji4YZHMA',
  authDomain: 'alafia-tech.firebaseapp.com',
  databaseURL: 'https://alafia-tech.firebaseio.com',
  projectId: 'alafia-tech',
  storageBucket: 'alafia-tech.appspot.com',
  messagingSenderId: '4987782666',
  appId: '1:4987782666:web:9d06349b5fcc0b62a301f3',
  measurementId: 'G-CHT2F29WJM',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//firebase.firestore().settings({ timestampsInSnapshots: true });
firebase.firestore();
firebase.storage();

export default firebase;
