import React, { memo } from "react";
import { connect } from "react-redux";
import {
  Container,
  Label,
  ImagesBlock,
  Block,
  TextBlock,
  FullNameBlock,
  LineBlock,
  InfoLine
} from "./index.style";
import { changeFormValue } from "../../actions/form";
import moment from "moment";
import FileWrapper from "../FileWrapper";
import TextField from "@material-ui/core/TextField";
import ImageCarousel from "../ImageCarousel";

const NameBlock = ({
  foto,
  name,
  surname,
  patronymic,
  birthdate,
  editMode,
  images,
  changeFormValue,
  firebase,
  geo1,
  geo2,
  profile1,
  profile2,
  city,
  country
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    if (id !== "birthdate") {
      changeFormValue(id, value);
    }
  };

  const handleDateChange = e => {
    let date = moment(e.target.value).format("YYYY-MM-DD");
    changeFormValue("birthdate", date);
  };

  const deleteFile = id => () => {
    changeFormValue(id, "");
  };

  const handleDeleteImage = id => {
    changeFormValue("delimage", id);
  };

  const onUploadFile = id => e => {
    const file = e.target.files[0];
    let storageRef = firebase.storage().ref();
    let uploadTask = storageRef
      .child(`uploads/${new Date().getTime()}-${file.name}`)
      .put(file);

    uploadTask.on(
      "state_changed",
      function(snapshot) {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING:
            console.log("Upload is running");
            //changeFormValue(id, "loading");
            break;
          default:
            break;
        }
      },
      function(error) {
        // Handle unsuccessful uploads
      },
      function() {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          changeFormValue(id, downloadURL);
        });
      }
    );
  };

  const getDate = date => {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    } else {
      return "";
    }
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Block>
      <Label>Личные данные</Label>
      <Container>
        <ImagesBlock>
          <div>
            {foto && <img src={foto} alt="person" width="100px"></img>}
            <FileWrapper
              name="foto"
              link={foto}
              deleteFile={deleteFile}
              onUploadFile={onUploadFile}
              withDelete={editMode}
            />
          </div>
          <ImageCarousel
            images={images}
            onUploadFile={onUploadFile}
            onDelete={handleDeleteImage}
            onChange={handleChange}
            editMode={editMode}
          />
        </ImagesBlock>
        <TextBlock>
          <FullNameBlock>
            <TextField
              id="surname"
              label="Surname"
              value={surname}
              margin="normal"
              onChange={handleChange}
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
            />
            <TextField
              id="name"
              label="Name"
              value={name}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              onChange={handleChange}
            />

            <TextField
              id="patronymic"
              label="Patronymic"
              value={patronymic}
              margin="normal"
              onChange={handleChange}
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
            />
          </FullNameBlock>
          <InfoLine>
            <LineBlock width="170px">
              <TextField
                id="birthdate"
                label="Birthdate"
                type="date"
                onChange={handleDateChange}
                value={getDate(birthdate)}
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                InputLabelProps={{ shrink: true }}
                margin="normal"
              />
              <TextField
                id="age"
                label="Age"
                value={moment().diff(birthdate, "years") || ""}
                InputProps={{
                  readOnly: true
                }}
                inputProps={smFont}
                margin="normal"
              />
            </LineBlock>

            <LineBlock>
              <TextField
                id="city"
                label="City"
                value={city}
                margin="normal"
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                onChange={handleChange}
              />
              <TextField
                id="country"
                label="Country"
                value={country}
                margin="normal"
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                onChange={handleChange}
              />
            </LineBlock>

            <LineBlock width="250px">
              <TextField
                id="geo1"
                label="Geo1"
                value={geo1}
                margin="normal"
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                onChange={handleChange}
              />
              <TextField
                id="geo2"
                label="Geo2"
                value={geo2}
                margin="normal"
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                onChange={handleChange}
              />
            </LineBlock>

            <LineBlock width="250px">
              <TextField
                id="profile1"
                label="Profile1"
                value={profile1}
                margin="normal"
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                onChange={handleChange}
              />
              <TextField
                id="profile2"
                label="Profile2"
                value={profile2}
                margin="normal"
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                onChange={handleChange}
              />
            </LineBlock>
          </InfoLine>
        </TextBlock>
      </Container>
    </Block>
  );
};

const mapStateToProps = state => {
  return {
    foto: state.form.foto,
    name: state.form.name,
    surname: state.form.surname,
    patronymic: state.form.patronymic,
    birthdate: state.form.birthdate,
    images: state.form.images,
    city: state.form.city,
    country: state.form.country,
    geo1: state.form.geo1,
    geo2: state.form.geo2,
    profile1: state.form.profile1,
    profile2: state.form.profile2
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(NameBlock));
