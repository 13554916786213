import React, { memo } from "react";
import { connect } from "react-redux";
import { Container, InfoLine, Label, LineBlock } from "./index.style";
import { changeFormValue } from "../../actions/form";
import LinksView from "../LinksView";

const LinksBlock = ({
  links1,
  links2,
  links3,
  links4,
  links5,
  links6,
  linksdesc1,
  linksdesc2,
  linksdesc3,
  linksdesc4,
  linksdesc5,
  linksdesc6,
  editMode,
  changeFormValue
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  return (
    <Container>
      <Label>Ссылки</Label>
      <InfoLine>
        <LineBlock>
          <LinksView
            link={links1}
            editMode={editMode}
            desc={linksdesc1}
            id={1}
            handleChange={handleChange}
          />
          <LinksView
            link={links2}
            editMode={editMode}
            desc={linksdesc2}
            id={2}
            handleChange={handleChange}
          />
        </LineBlock>
        <LineBlock>
          <LinksView
            link={links3}
            editMode={editMode}
            desc={linksdesc3}
            id={3}
            handleChange={handleChange}
          />
          <LinksView
            link={links4}
            editMode={editMode}
            desc={linksdesc4}
            id={4}
            handleChange={handleChange}
          />
        </LineBlock>
        <LineBlock noRightBorder>
          <LinksView
            link={links5}
            editMode={editMode}
            desc={linksdesc5}
            id={5}
            handleChange={handleChange}
          />
          <LinksView
            link={links6}
            editMode={editMode}
            desc={linksdesc6}
            id={6}
            handleChange={handleChange}
          />
        </LineBlock>
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    links1: state.form.links1,
    links2: state.form.links2,
    links3: state.form.links3,
    links4: state.form.links4,
    links5: state.form.links5,
    links6: state.form.links6,
    linksdesc1: state.form.linksdesc1,
    linksdesc2: state.form.linksdesc2,
    linksdesc3: state.form.linksdesc3,
    linksdesc4: state.form.linksdesc4,
    linksdesc5: state.form.linksdesc5,
    linksdesc6: state.form.linksdesc6
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(LinksBlock));
