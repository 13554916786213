import React, { memo } from "react";
import { connect } from "react-redux";
import {
  Container,
  InfoLine,
  KidsBlock,
  Label,
  LineBlock,
  SpouseBlock
} from "./index.style";
import TextField from "@material-ui/core/TextField";
import { changeFormValue } from "../../actions/form";
import moment from "moment";

const PrivateBlock = ({
  spouse_birthdate,
  spouse_work,
  spouse_details,
  kid1,
  kid1_birthdate,
  kid2,
  kid2_birthdate,
  kid3,
  kid3_birthdate,
  kid4,
  kid4_birthdate,
  spousename,
  editMode,
  changeFormValue,
  demo,
  kids
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const handleDateChange = e => {
    const date = moment(e.target.value).format("YYYY-MM-DD");
    changeFormValue(e.target.id, date);
  };

  const getDate = date => {
    if (date) {
      return moment(date).format("YYYY-MM-DD");
    } else {
      return "";
    }
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Container>
      <Label>Семейные сведения</Label>
      <SpouseBlock>
        <TextField
          id="spousename"
          label="Spouse"
          value={spousename}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="spouse_birthdate"
          label="Birthdate"
          type="date"
          InputLabelProps={{ shrink: true }}
          onChange={handleDateChange}
          value={getDate(spouse_birthdate)}
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          margin="normal"
        />
        <TextField
          label="Age"
          value={moment().diff(spouse_birthdate, "years") || ""}
          margin="normal"
          InputProps={{
            readOnly: true
          }}
          inputProps={smFont}
          //onChange={handleChange}
        />
        <TextField
          id="spouse_work"
          label="Work"
          value={spouse_work}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
        <TextField
          id="spouse_details"
          label="Details"
          value={spouse_details}
          margin="normal"
          InputProps={{
            readOnly: !editMode
          }}
          inputProps={smFont}
          onChange={handleChange}
        />
      </SpouseBlock>
      {demo && (
        <div>
          <TextField
            id="kids"
            label="Number of kids"
            value={kids}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
        </div>
      )}
      <InfoLine>
        <LineBlock>
          <KidsBlock>
            <TextField
              id="kid1"
              label="Kid 1"
              value={kid1}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              onChange={handleChange}
            />
            <TextField
              id="kid1_birthdate"
              label="Birthdate"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={handleDateChange}
              value={getDate(kid1_birthdate)}
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              margin="normal"
            />
            <TextField
              label="Age"
              value={moment().diff(kid1_birthdate, "years") || ""}
              margin="normal"
              InputProps={{
                readOnly: true
              }}
              inputProps={smFont}
              //onChange={handleChange}
            />
            <TextField
              id="kid3"
              label="Kid 3"
              value={kid3}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              onChange={handleChange}
            />
            <TextField
              id="kid3_birthdate"
              label="Birthdate"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={handleDateChange}
              value={getDate(kid3_birthdate)}
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              margin="normal"
            />
            <TextField
              label="Age"
              value={moment().diff(kid3_birthdate, "years") || ""}
              margin="normal"
              InputProps={{
                readOnly: true
              }}
              inputProps={smFont}
              //onChange={handleChange}
            />
          </KidsBlock>
        </LineBlock>
        <LineBlock noRightBorder>
          <KidsBlock>
            <TextField
              id="kid2"
              label="Kid 2"
              value={kid2}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              onChange={handleChange}
            />
            <TextField
              id="kid2_birthdate"
              label="Birthdate"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={handleDateChange}
              value={getDate(kid2_birthdate)}
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              margin="normal"
            />
            <TextField
              label="Age"
              value={moment().diff(kid2_birthdate, "years") || ""}
              margin="normal"
              InputProps={{
                readOnly: true
              }}
              inputProps={smFont}
              //onChange={handleChange}
            />

            <TextField
              id="kid4"
              label="Kid 4"
              value={kid4}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              onChange={handleChange}
            />
            <TextField
              id="kid4_birthdate"
              label="Birthdate"
              type="date"
              InputLabelProps={{ shrink: true }}
              onChange={handleDateChange}
              value={getDate(kid4_birthdate)}
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              margin="normal"
            />
            <TextField
              label="Age"
              value={moment().diff(kid4_birthdate, "years") || ""}
              margin="normal"
              InputProps={{
                readOnly: true
              }}
              inputProps={smFont}
              //onChange={handleChange}
            />
          </KidsBlock>
        </LineBlock>
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    spousename: state.form.spousename,
    spouse_birthdate: state.form.spouse_birthdate,
    spouse_work: state.form.spouse_work,
    spouse_details: state.form.spouse_details,
    kid1: state.form.kid1,
    kid1_birthdate: state.form.kid1_birthdate,
    kid2: state.form.kid2,
    kid2_birthdate: state.form.kid2_birthdate,
    kid3: state.form.kid3,
    kid3_birthdate: state.form.kid3_birthdate,
    kid4: state.form.kid4,
    kid4_birthdate: state.form.kid4_birthdate,
    demo: state.crud.userInfo.demo,
    kids: state.form.kids
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(PrivateBlock));
