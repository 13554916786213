import React, { useState } from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { setFastSearch } from '../../actions/filterActions';

import { SearchBlock } from './index.style';
import {changePage} from "../../actions/viewActions";

const SearchBar = ({ filter, setFastSearch, changePage }) => {
  const [search, setSearch] = useState(filter.fast);
  const clearSearchText = () => {
    changePage(0);
    setSearch('');
    setFastSearch('');
  };

  const handleTyping = e => {
    changePage(0);
    setFastSearch(e.target.value);
    setSearch(e.target.value);
  };

  return (
    <SearchBlock>
      <TextField
        label="Поиск"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={clearSearchText}>{search && <CloseIcon />}</IconButton>
            </InputAdornment>
          ),
        }}
        onChange={handleTyping}
        value={search}
        autoFocus
        variant="outlined"
      />
    </SearchBlock>
  );
};

const mapStateToProps = state => {
  return {
    filter: state.filter,
  };
};

const mapDispatchToProps = {
  setFastSearch,
  changePage
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
