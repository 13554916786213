import { MAP_MOVE } from "../constants/map";

const initialState = {
  center: {
    lat: 55.75,
    lng: 37.61
  },
  zoom: 9
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAP_MOVE:
      return {
        ...state,
        center: action.center,
        zoom: action.zoom,
        bounds: action.bounds
      };
    default:
      return state;
  }
};

export default mapReducer;
