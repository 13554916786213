import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import CurrentProfActivityBlock from "../CurrentProfActivityBlock";
import SharedNameBlock from "../SharedNameBlock";
//import PDFIcon from "@material-ui/icons/PictureAsPdf";
import MailIcon from "@material-ui/icons/Mail";
//import html2pdf from "html2pdf.js";

import { loadShared } from "../../actions/form";

import logo from "../../assets/logo.svg";
import {
  //ActionText,
  //BoldText,
  Container,
  DistributorBlock,
  DistributorNameBlock,
  HoursBlock,
  LogoBlock,
  MailText,
  ShareBlock,
  ShareTypeBlock,
  TopBlock
} from "./index.style";

const SharedView = ({ match, loadShared, error, fullname }) => {
  const ref = useRef(null);

  /*const handlePdf = () => {
    if (ref && ref.current) {
      const opt = {
        margin: 1,
        filename: "share.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          dpi: 300,
          letterRendering: true,
          useCORS: true
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "l" },
        pagebreak: { mode: "avoid-all" }
      };

      html2pdf()
        .set(opt)
        .from(ref.current)
        .save();
    }
  };*/

  useEffect(() => {
    const path = match.params.path;
    if (path) {
      loadShared(path);
    }
    //eslint-disable-next-line
  }, [match.params.path]);

  return (
    <Container>
      {error && <div>Link is not valid</div>}
      {!error && (
        <>
          <TopBlock>
            <LogoBlock>
              <div>
                <img src={logo} alt="human radar" width={370} />
              </div>
              {/*<BoldText>
                CRM-система для стратегического управления личным нетворком
              </BoldText>*/}
            </LogoBlock>
            <ShareBlock>
              <DistributorBlock>
                <div>Этим контактом с Вами поделился:</div>
                {fullname && (
                  <DistributorNameBlock>{fullname}</DistributorNameBlock>
                )}
              </DistributorBlock>
              <HoursBlock>
                Данная ссылка будет доступна в течение 48 часов
              </HoursBlock>
            </ShareBlock>
          </TopBlock>
          <div style={{ display: "flex", flexDirection: "column" }} ref={ref}>
            <SharedNameBlock />
            <CurrentProfActivityBlock />
          </div>

          <ShareTypeBlock>
            {/*<PDFIcon />
            <ActionText onClick={handlePdf}>save as pdf</ActionText>*/}
            <div></div>
            <div></div>
            <MailIcon />
            <MailText
              href={`mailto:?to=&body=https://humanradar.ru/shared/${match.params.path}&subject=Human Radar: с Вами поделились контактом`}
            >
              send by email
            </MailText>
          </ShareTypeBlock>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = state => {
  return { error: state.form.error, fullname: state.form.fullname };
};

const mapDispatchToProps = { loadShared };

export default connect(mapStateToProps, mapDispatchToProps)(SharedView);
