import { SET_FILTER, SET_SEARCH, RESET_FILTER } from '../constants/filterActions';

const initialState = { name: '', surname: '', patronymic: '', fast: '' };

const filterReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_FILTER:
      return { ...state, ...payload };
    case SET_SEARCH:
      return { ...state, fast: payload };
    case RESET_FILTER:
      return { fast: state.fast ? state.fast : '' };
    default:
      return state;
  }
};

export default filterReducer;
