import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { createFirestoreInstance } from 'redux-firestore';
import { routerMiddleware } from 'connected-react-router';
import { getFirebase } from 'react-redux-firebase';
import { getFirestore } from 'redux-firestore';

import rootReducer from '../reducers';
import history from '../services/history';
import firebase from '../config/firebase';

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

const middlewaresList = [
  thunk.withExtraArgument({ getFirebase, getFirestore }),
  logger,
  routerMiddleware(history),
];

/* const middlewaresList = [thunk, logger, routerMiddleware(history)]; */

const enhancers = compose(
  applyMiddleware(...middlewaresList),
  //reactReduxFirebase(firebaseConfig),
  //reduxFirestore(firebaseConfig),
);

const store = createStore(rootReducer(history), {}, enhancers);

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

export default store;
