import React, { memo } from "react";
import { connect } from "react-redux";
import {
  Container,
  FieldBlock,
  InfoLine,
  Label,
  MailBlock,
  TelBlock
} from "./index.style";
import TextField from "@material-ui/core/TextField";
import { changeFormValue } from "../../actions/form";

const ContactBlock = ({
  tel1,
  tel2,
  email1,
  email2,
  cont_field1,
  cont_field2,
  cont_field3,
  editMode,
  changeFormValue
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Container>
      <Label>Контактные данные</Label>
      <InfoLine>
        <TelBlock>
          <TextField
            id="tel1"
            label="Tel 1"
            value={tel1}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="tel2"
            label="Tel 2"
            value={tel2}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
        </TelBlock>
        <MailBlock>
          <TextField
            id="email1"
            label="E-mail 1"
            value={email1}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="email2"
            label="E-mail 2"
            value={email2}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
        </MailBlock>
        <FieldBlock>
          <TextField
            id="cont_field1"
            label="Field1"
            value={cont_field1}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="cont_field2"
            label="Field2"
            value={cont_field2}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="cont_field3"
            label="Field3"
            value={cont_field3}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
        </FieldBlock>
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    tel1: state.form.tel1,
    tel2: state.form.tel2,
    email1: state.form.email1,
    email2: state.form.email2,
    cont_field1: state.form.cont_field1,
    cont_field2: state.form.cont_field2,
    cont_field3: state.form.cont_field3
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(ContactBlock));
