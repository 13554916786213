import {
  UPDATE_USERINFO_INIT,
  UPDATE_USERINFO_SUCCESS,
  UPDATE_USERINFO_FAIL,
  CREATE_USERINFO_INIT,
  CREATE_USERINFO_SUCCESS,
  CREATE_USERINFO_FAIL,
  DELETE_USERINFO_INIT,
  DELETE_USERINFO_SUCCESS,
  DELETE_USERINFO_FAIL,
  UPDATE_USERINFO_CONNECTION_INIT,
  UPDATE_USERINFO_CONNECTION_FAIL,
  UPDATE_USERINFO_CONNECTION_SUCCESS
} from "../constants/firestoreActions";
import { EXPORT_ERROR_ADD } from "../constants/viewActions";
import axios from "axios";

const getCoordinates = async city => {
  let lat = "",
    lng = "";

  if (city) {
    try {
      const { data: geocode } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${city}&key=AIzaSyAxOBwSrruy4SUcMMjSq9nLgzOji4YZHMA`
      );
      if (geocode.status === "OK") {
        lat = geocode.results[0].geometry.location.lat;
        lng = geocode.results[0].geometry.location.lng;
      }
    } catch (e) {
      console.log(e);
    }
  }
  return { lat, lng };
};

export const saveDataFirestore = (
  firestore,
  data,
  uid,
  db
) => async dispatch => {
  dispatch({ type: CREATE_USERINFO_INIT });

  try {
    const coord = await getCoordinates(data.city);
    await firestore.collection(db).add({ ...data, editorId: uid, ...coord });
    dispatch({ type: CREATE_USERINFO_SUCCESS });
  } catch (err) {
    console.log(err);
    dispatch({ type: CREATE_USERINFO_FAIL });
  }
};

export const updateDataFirestore = (
  firestore,
  data,
  uid,
  db
) => async dispatch => {
  dispatch({ type: UPDATE_USERINFO_INIT });
  try {
    const coord = await getCoordinates(data.city);
    await firestore
      .collection(db)
      .doc(data.id)
      .update({ ...data, editorId: uid, ...coord });
    dispatch({ type: UPDATE_USERINFO_SUCCESS });
  } catch (err) {
    console.log(err);
    dispatch({ type: UPDATE_USERINFO_FAIL });
  }
};

export const deleteDataFirestore = (
  firestore,
  id,
  uid,
  db
) => async dispatch => {
  dispatch({ type: DELETE_USERINFO_INIT });
  try {
    if (db === "fakedata") {
      await firestore
        .collection(db)
        .doc(id)
        .update({ delete: true, editorId: uid });
    } else {
      await firestore
        .collection(db)
        .doc(id)
        .delete();
    }

    dispatch({ type: DELETE_USERINFO_SUCCESS });
  } catch (err) {
    console.log(err);
    dispatch({ type: DELETE_USERINFO_FAIL });
  }
};

/*export const migrateDataFirestore = firestore => async dispatch => {
  let store = [];

  let snap = await firestore.collection("fakedata").get();
  snap.forEach(d => {
    const obj = {
      id: d.id,
      ...d.data()
    };

    store.push(obj);
  });
  console.log(
    store.filter(e => !e.migrated).length
  );

  let promiseList = store
    .filter(e => !e.migrated)
    .filter((_, idx) => idx <= 500)
    .map(async e => {
      await firestore
        .collection("fakedata")
        .doc(e.id)
        .update({
          prof1_activity: e.company || "",
          prof1_position1: e.position1 || "",
          prof1_position2: e.position2 || "",
          prof1_industry1: e.industry1 || "",
          prof1_industry2: e.industry2 || "",
          prof1_industry3: e.industry3 || "",
          tel1: e.mobtel || "",
          tel2: e.officetel || "",
          kid1: e.kids || "",
          lifestory1: e.details1 || "",
          lifestory2: e.details2 || "",
          migrated: true
        });
    });
  await Promise.all(promiseList);
};*/

export const importDataFirestore = (
  firestore,
  data,
  uid,
  db
) => async dispatch => {
  dispatch({ type: CREATE_USERINFO_INIT });
  let store = [];

  let snap = await firestore.collection(db).get();
  snap.forEach(d => {
    const obj = {
      id: d.id,
      ...d.data()
    };

    store.push(obj);
  });

  data.map(async el => {
    let search = store.filter(
      e =>
        el.name === e.name &&
        el.surname === e.surname &&
        ((!el.patronymic && !e.patronymic) ||
          (el.patronymic && e.patronymic && el.patronymic === e.patronymic))
    );
    if (search.length === 1) {
      //update card
      let upd = {};
      let compare = search[0];
      let id = search[0].id;
      for (let key in el) {
        if (!compare[key] && compare[key] !== el[key]) {
          upd[key] = el[key];
        }
      }
      if (upd.city) {
        const coord = await getCoordinates(upd.city);
        upd = { ...upd, ...coord };
      }
      await firestore
        .collection(db)
        .doc(id)
        .update({ ...upd, editorId: uid });
      dispatch({ type: UPDATE_USERINFO_SUCCESS });
    } else if (search.length === 0) {
      //add new card
      const coord = await getCoordinates(el.city);
      await firestore.collection(db).add({ ...el, editorId: uid, ...coord });
      dispatch({ type: CREATE_USERINFO_SUCCESS });
    } else {
      dispatch({
        type: EXPORT_ERROR_ADD,
        error: {
          name: `${el.name} ${el.patronymic || ""} ${el.surname}`,
          type: "more than one user with equal full name"
        }
      });
    }
  });
};

export const updateConnectionFirestore = (
  firestore,
  id,
  place,
  target,
  uid,
  db
) => async dispatch => {
  dispatch({ type: UPDATE_USERINFO_CONNECTION_INIT });
  try {
    let s = await firestore.collection(db).doc(id);

    s.update({
      [`connection${place}`]: target,
      [`connectiondesc${place}`]: "",
      editorId: uid
    });
    dispatch({ type: UPDATE_USERINFO_CONNECTION_SUCCESS });
  } catch (err) {
    console.log(err);
    dispatch({ type: UPDATE_USERINFO_CONNECTION_FAIL });
  }
};

export const updateConnectionDescFirestore = (
  firestore,
  id,
  place,
  desc,
  uid,
  db
) => async dispatch => {
  dispatch({ type: UPDATE_USERINFO_CONNECTION_INIT });
  try {
    let s = await firestore.collection(db).doc(id);

    s.update({ [`connectiondesc${place}`]: desc, editorId: uid });
    dispatch({ type: UPDATE_USERINFO_CONNECTION_SUCCESS });
  } catch (err) {
    console.log(err);
    dispatch({ type: UPDATE_USERINFO_CONNECTION_FAIL });
  }
};
