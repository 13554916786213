import React, { useRef, useEffect, useState } from "react";
import * as venn from "venn.js";
import * as d3 from "d3";
import { StylingBlock } from "./index.style.js";
import { getData } from "../../selectors";
import { push } from "connected-react-router";
import { setFilter } from "../../actions/filterActions";
import { connect } from "react-redux";
import ControlTab from "../ControlTab";

const VennDiagram = ({ userdata, setFilter, push, demo }) => {
  const [sets, setSets] = useState([]);

  const ref = useRef(null);

  const chart = venn
    .VennDiagram()
    .width(1000)
    .height(700);

  const getIndex = name => {
    switch (name) {
      case "fp":
      case "друзья":
        return 0;
      case "hichem":
      case "партнеры":
        return 1;
      case "fj":
      case "есть симпатия":
        return 2;
      case "ff":
      case "клиенты":
        return 3;
      case "fs":
      case "наставники":
        return 4;
      case "fif":
        return 5;
      default:
        console.log(name);
        return -1;
    }
  };

  const getName = idx => {
    switch (idx) {
      case 0:
        return "fp";
      case 1:
        return "hichem";
      case 2:
        return "fj";
      case 3:
        return "ff";
      case 4:
        return "fs";
      case 5:
        return "fif";
      default:
        return;
    }
  };

  const buildSet = arr => {
    const z = arr.map(elem => {
      return elem
        .toLowerCase()
        .split(" ")
        .filter(e => e !== "skf" && e !== "dormant")
        .sort()
        .join(", ");
    });
    console.log(z)
    const nSet = new Set(z);

    const set = [...nSet];

    console.log(set);
    const cList = ["fp", "hichem", "fj", "ff", "fs", "fif"];
    let categories = cList.map(e => ({
      label: e,
      size: e === "fs" ? 1 : 0,
      sets: [getIndex(e)],
      top: true
    }));

    categories = set.reduce((acc, e) => {
      const spl = e.split(",");
      if (spl.length > 1) {
        const setsIndex = spl.map(e => getIndex(e.trim()));
        acc.push({ label: e, size: 0, sets: setsIndex });
      }
      return acc;
    }, categories);

    z.forEach(e => {
      categories = categories.map(cat => {
        //console.log(cat);
        //const ctg = cat.split(',');
        //console.log(ctg);
        if (cat.label === e) {
          return {
            ...cat,
            size: cat.size + 1
          };
        }
        return cat;
      });
    });

    const res = categories.map(e => {
      if (!e.top) {
        delete e.label;
        return e;
      }
      e.label = e.label.toUpperCase();
      return e;
    });

    console.log(res);

    setSets(res);
  };

  useEffect(() => {
    if (userdata) {
      //console.log(userdata.filter(({info1}) => /skf|dormant/i.test(info1)).map(({info1, name, surname}) => ({info1, name, surname})));
      const tags = userdata
        .map(e => e.info1)
        .filter(e => e)
        .filter(e => /fp|chem|fj|ff|fs|fif/i.test(e));

      buildSet(tags);

      //setLoading(false);
    } else {
      //setLoading(true);
    }
    //eslint-disable-next-line
  }, [userdata]);

  const handleChartClick = ctg => {
    const circles = ctg.map(e => getName(e)).join(",");
    setFilter({ circles });

    push("/");
  };

  useEffect(() => {
    if (ref && ref.current) {
      const div = d3.select(ref.current);

      div.datum(sets).call(chart);

      /* const tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "venntooltip");*/

      const tooltip = d3.select(".venntooltip");

      /*div
        .selectAll("path")
        .style("stroke-opacity", 0)
        .style("stroke", "#fff")
        .style("stroke-width", 3);*/

      div
        .selectAll("g")
        .on("mouseover", function(d) {
          // sort all the areas relative to the current item
          venn.sortAreas(div, d);

          // Display a tooltip with the current size
          tooltip
            .transition()
            .duration(400)
            .style("opacity", 0.9);
          tooltip.text(d.size + " users");

          // highlight the current path
          const selection = d3
            .select(this)
            .transition("tooltip")
            .duration(400);
          selection
            .select("path")
            .style("fill-opacity", d.sets.length === 1 ? 0.4 : 0.1)
            .style("stroke-opacity", 1);
        })

        .on("mousemove", function() {
          tooltip
            .style("left", d3.event.pageX + "px")
            .style("top", d3.event.pageY - 28 + "px");
        })

        .on("mouseout", function(d) {
          tooltip
            .transition()
            .duration(400)
            .style("opacity", 0);
          const selection = d3
            .select(this)
            .transition("tooltip")
            .duration(400);
          selection
            .select("path")
            .style("fill-opacity", d.sets.length === 1 ? 0.25 : 0.0)
            .style("stroke-opacity", 0);
        })
        .on("click", function(d) {
          //tooltip.style("opacity", 0);
          handleChartClick(d.sets);
        });
    }
    //eslint-disable-next-line
  }, [ref, sets]);

  return (
    <>
      <ControlTab />

      <StylingBlock>
        <div className="venntooltip"></div>
        <div ref={ref}></div>
      </StylingBlock>
    </>
  );
};

const mapStateToProps = state => {
  return {
    userdata: getData(state),
    demo: state.crud.userInfo.demo
  };
};

const mapDispatchToProps = { push, setFilter };

export default connect(mapStateToProps, mapDispatchToProps)(VennDiagram);
