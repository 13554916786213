import {
  // GETDATA_FAIL,
  // GETDATA_SUCCESS,
  GETSTORE_FAIL,
  GETSTORE_SUCCESS
} from "../constants/crudActions";

const initialState = {
  error: false,
  userInfo: { db: "", fullName: "", demo: false }
};

const crudReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // case GETDATA_SUCCESS:
    //   return { ...state, userdata: payload, error: false };
    // case GETDATA_FAIL:
    //   return { ...state, userdata: [], error: true };
    case GETSTORE_SUCCESS:
      return { ...state, userInfo: payload, error: false };
    case GETSTORE_FAIL:
      return { ...state, userInfo: {}, error: true };
    default:
      return state;
  }
};

export default crudReducer;
