import React, { memo } from "react";
import { connect } from "react-redux";
import {
  Container,
  InfoLine,
  Label,
  LineBlock,
  LineBlockSecond
} from "./index.style";
import TextField from "@material-ui/core/TextField";
import { changeFormValue } from "../../actions/form";

const AdditionalInfoBlock = ({
  changeFormValue,
  editMode,
  tag1,
  tag2,
  tag3,
  tag4,
  tag5,
  tag6,
  info1,
  info2,
  info3,
  info4,
  demo
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Container>
      <Label>Дополнительные данные</Label>
      <InfoLine>
        <LineBlock>
          <TextField
            id="tag1"
            label="Tag 1"
            value={tag1}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="tag2"
            label="Tag 2"
            value={tag2}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="tag3"
            label="Tag 3"
            value={tag3}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="tag4"
            label="Tag 4"
            value={tag4}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="tag5"
            label="Tag 5"
            value={tag5}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="tag6"
            label="Tag 6"
            value={tag6}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
        </LineBlock>
        <LineBlockSecond>
          <TextField
            id="info1"
            label={demo ? "Radar Circle 1" : "Info 1"}
            value={info1}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="info2"
            label={demo ? "Radar Circle 2" : "Info 2"}
            value={info2}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="info3"
            label={demo ? "Radar Circle 3" : "Info 3"}
            value={info3}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
          <TextField
            id="info4"
            label={demo ? "Radar Circle 4" : "Info 4"}
            value={info4}
            margin="normal"
            InputProps={{
              readOnly: !editMode
            }}
            inputProps={smFont}
            onChange={handleChange}
          />
        </LineBlockSecond>
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    tag1: state.form.tag1,
    tag2: state.form.tag2,
    tag3: state.form.tag3,
    tag4: state.form.tag4,
    tag5: state.form.tag5,
    tag6: state.form.tag6,
    info1: state.form.info1,
    info2: state.form.info2,
    info3: state.form.info3,
    info4: state.form.info4,
    demo: state.crud.userInfo.demo
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(AdditionalInfoBlock)
);
