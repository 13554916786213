import { createSelector } from "reselect";

export const getUserdata = state => {
  const db = state.crud.userInfo.db;
  if (db) {
    return state.firestore.ordered[db];
  }
  return [];
  //return state.firestore.ordered.fakedata;
};

const getDb = state => state.crud.userInfo.db;
const getCollections = state => state.firestore.ordered;

const generateFastSearch = (data = []) => {
  const fastSearchFields = [
    "name",
    "surname",
    "company",
    "mobtel",
    "officetel",
    "email1",
    "email2",
    "patronymic",
    "tag1",
    "tag2",
    "tag3",
    "tag4",
    "tag5",
    "position1",
    "position2",
    "assistant",
    "info1",
    "info2",
    "info3",
    "info4",
    "spousename",
    "hobby1",
    "hobby2",
    "hobby3",
    "hobby4",
    "hobby5",
    "industry1",
    "industry2",
    "industry3",
    "geo1",
    "geo2",
    "city",
    "birthdate",
    "country",
    "placeofbirth",
    "mother",
    "father",
    "siblings",
    "childhoodact",
    "details1",
    "details2",
    "education1",
    "major1",
    "years1",
    "education2",
    "major2",
    "years2",
    "education3",
    "major3",
    "years3",
    "company1",
    "company2",
    "company3",
    "company4",
    "company5",
    "company6",
    "professional1",
    "professional2",
    "professional3",
    "professional4",
    "professional5",
    "professional6",
    "language1",
    "language2",
    "language3",
    "language4"
  ];
  if (data.length > 0) {
    return data.map(el => {
      const fast = fastSearchFields
        .map(field => {
          return el[field];
        })
        .join(", ")
        .toLowerCase();

      return { ...el, fast };
    });
  }
  return [];
};

export const getUserListWithSearch = createSelector([getUserdata], data =>
  generateFastSearch(data)
);

export const getData = createSelector([getDb, getCollections], (db, collections) => collections[db]);