import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  margin: 0 auto;
`;

export const TopText = styled.div`
  font-size: 36px;
  margin: 30px 0;
  text-align: center;
`;

export const Text = styled.div`
  font-size: 24px;
  text-align: center;
  ${({ bold }) => bold && "font-weight: 800;"}
  ${({ blue }) => blue && "color: #283593;"}
  ${({ mtop }) => mtop && "margin-top: 40px;"}
  
`;

export const ImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  justify-content: center;
  margin-top: 40px;

  @media (max-width: 991.98px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
