import React, { useState, useEffect } from "react";
import {
  BorderedBlock,
  ButtonsBlock,
  Container,
  GridContainer,
  ImageBlock,
  LineElement,
  TextBlockLarge,
  TextBlockStd
} from "./index.style";
import ControlTab from "../ControlTab";
import Button from "@material-ui/core/Button";
import { push } from "connected-react-router";
import { connect } from "react-redux";

import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import LeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import feldman from "../../assets/feldman.jpg";
import danbarcir from "../../assets/danbarcir.jpg";
import question from "../../assets/question.jpg";
import circles from "../../assets/circles.jpg";
import man from "../../assets/man.jpg";
import ppls from "../../assets/ppls.jpg";
import arrows from "../../assets/arrows.jpg";
import axios from "axios";

const IntroBlock = ({ push, auth, visited }) => {
  const [page, setPage] = useState(0);

  const handleNext = () => {
    setPage(p => p + 1);
  };
  const handlePrev = () => {
    setPage(p => p - 1);
  };
  const handleStart = async () => {
    await axios.post(
      `https://us-central1-alafia-tech.cloudfunctions.net/setVisited`,
      { uid: auth.uid }
    );
    window.location.reload();
  };

  useEffect(() => {
    if (visited) push("/");
    //eslint-disable-next-line
  }, [visited]);

  return (
    <>
      <ControlTab />
      <Container>
        {page === 0 && (
          <div>
            <h2>Добро пожаловать в демо Human Radar!</h2>
            <TextBlockLarge>
              Мы ценим Вашу готовность поработать в демо версии системы и
              предоставить нам обратную связь! СПАСИБО!!!
            </TextBlockLarge>
            <TextBlockLarge>
              Перед тем как приступить к тестированию функционала системы, мы
              ознакомим Вас с информацией о том, как родился этот проект, в чем
              его идея и философия. Всего 5 страниц сжатой информации, после
              чего Вы зайдете в демо версию и сможете пройти <i>маршрутом</i>,
              предложенным в присланной Вам инструкции.
            </TextBlockLarge>
          </div>
        )}
        {page === 1 && (
          <div>
            <h2>Как появился этот проект и в чем его идея</h2>

            <ImageBlock>
              <img src={feldman} alt="maxim feldman" height="250px" />
              <div style={{ marginBottom: "8px", marginTop: "8px" }}>
                <b>Максим Фельдман</b>
              </div>
              <div>Основатель проекта Human Radar</div>
            </ImageBlock>

            <TextBlockStd>Этот стартап вырос из личной истории.</TextBlockStd>
            <TextBlockStd>
              В августе 2019 г. в силу нового карьерного поворота я решил
              <b> научиться управлять своим нетворком более эффективно</b>,
              чтобы через него [мой нетворк] открыть для себя новые проекты. Я
              начал читать книги по теме, нанял тренера, освоил несколько
              методик, и начал собирать имена всех своих контактов сначала в
              блокноте, потом в файле Excel, присваивая каждому контакту
              категории и метки, внося данные, прикладывая фото, пр. На отметке
              в 150-200 человек я с одной стороны начал «входить во вкус», а с
              другой – столкнулся с некоторыми трудностями.
            </TextBlockStd>
            <TextBlockStd>
              Во-первых, я понял, что круг важных для меня контактов составляет
              не менее двух-трех тысяч человек и что вносить всех их вручную
              займет слишком много времени. Во-вторых, работать в Excel
              становилось все менее удобно – например, было невозможно быстро
              зайти в файл с мобильного устройства и получить/внести информацию.
              В-третьих, я осознал, что мне не достаточно данных из LinkedIn,
              Facebook или записной книжки моего телефона - ведь моя философия в
              выстраивании своего круга общения всегда заключалась в том, чтобы
              <b>интересоваться деталями и знать про людей много</b>. Я понял,
              что мне придется и «доставать» эти детали из своей памяти, и
              собирать их вновь, и что – раз я ставлю перед собой цель научиться
              эффективно управлять достаточно большим нетворком – мне
              понадобится какое-то программное обеспечение.
            </TextBlockStd>
            <TextBlockStd>
              Так я стал искать для себя <b>личную CRM-систему</b>. Перепробовав
              с десяток облачных CRM-решений (и мировых лидеров, и никому не
              известных приложений), я не нашел того, что бы мне подошло. Тогда
              я нанял программистов и стал создавать систему «под себя».
            </TextBlockStd>
            <TextBlockStd>
              Вот уже несколько месяцев как
              <b> облачная платформа HUMAN RADAR</b> является неотъемлемой
              частью моей ежедневной рутины. Все (или почти все) мои контакты
              собраны в одном приложении, карточки контакта содержат информацию
              о людях, а первые аналитические инструменты помогают мне делать
              подборки по отраслям, искать знакомых людей в определенной
              компании или географии, подбирать экспертов в соответствии с их
              опытом и компетенциями, отслеживать связи между людьми внутри
              моего нетворка, заранее планировать кого и как я поздравлю с днем
              рождения, вести учет встреч с важными для меня людьми – и многое,
              многое, другое.
            </TextBlockStd>
            <TextBlockStd>
              Я решил превратить этот проект в коммерческий стартап потому, что
              уверен в том, что
              <b> такое решение будет интересно многим, очень многим людям</b>.
              И вот почему.
            </TextBlockStd>
          </div>
        )}
        {page === 2 && (
          <div>
            <h2>Число Данбара</h2>
            <img
              src={danbarcir}
              alt="Danbar"
              style={{ float: "right" }}
              width="400px"
            />
            <TextBlockStd>
              В 1990-х годах британский антрополог и психолог Робин Данбар
              выдвинул теорию, в соответствии с которой человеческий мозг в
              состоянии поддерживать в среднем около 150 <b>постоянных</b>{" "}
              социальных связей.
            </TextBlockStd>
            <TextBlockStd>
              В модели, предложенной Данбаром, наши <i>круги</i> связей
              начинаются с 5 самых близких людей (support clique) и
              заканчиваются 1,500 (узнаем в лицо) или даже 5,000 контактами
              (среднее количество знакомств за всю жизнь). <br />
              Если Вам не доводилось познакомиться с этой теорией, то предлагаю
              посмотреть вот этот 3-минутный ролик:{" "}
              <a
                href="https://www.youtube.com/watch?v=3MZgddLz4T4"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.youtube.com/watch?v=3MZgddLz4T4
              </a>
            </TextBlockStd>
            <TextBlockStd>
              <b>
                Итак, в соответствии с теорией Данбара, вычислительной мощности
                коры нашего головного мозга хватает на поддержание постоянных
                отношений в среднем со 150 людьми.
              </b>
            </TextBlockStd>
            <TextBlockStd>
              <b>
                Но, очевидно, что предприниматели и менеджеры во всем мире
                сегодня вынуждены «держать» гораздо большее количество
                профессиональных и личных связей.
              </b>{" "}
              Как же быть им? Как в эпоху relationship marketing and network
              oriented communications справляться с тем количеством связей,
              которых требует от нас время? И, главное, как не позволить
              растущему количеству наших связей навредить качеству наших
              отношений с самыми близкими <i>кругами</i> (5,15 и 50)?
            </TextBlockStd>
            <TextBlockStd>
              <b>
                Наша гипотеза состоит в том, что для осознанного и эффективного
                управления своим нетворком нам нужны две вещи:
              </b>
              <ol>
                <li>Методики</li>
                <li>Технологии</li>
              </ol>
            </TextBlockStd>
          </div>
        )}
        {page === 3 && (
          <div>
            <h2>Методика</h2>
            <GridContainer>
              <LineElement area="a">
                <img src={question} alt="question" width="100px" />
              </LineElement>
              <LineElement area="b">
                <img src={arrows} alt="arrows" height="320px" />
              </LineElement>
              <LineElement area="c">
                <BorderedBlock>
                  <div>
                    1. К какому из моих кругов Данбара относится этот человек?
                  </div>
                  <img src={circles} alt="circles" width="250px" />
                </BorderedBlock>
              </LineElement>
              <LineElement area="d">
                Это может показаться удивительным, но исследования показывают,
                что современному цифровому человеку бывает крайне непросто
                назвать тех, кто входит в его третий (50) и четвертый (150)
                круг. Благодаря мессенджерам и соцсетям мы переполнены
                коммуникациями, и приоритизировать свои связи становится сложной
                задачей. Инструмент Dunbar Circles пока не реализован в нашей
                системе, но обязательно в ней появится после дополнительных
                консультаций с социологами.
                <br />
                Пользователи Human Radar будут также иметь возможность проходить
                онлайн-обучение по методике Данбара (и другим методикам), а
                премиальные пользователи смогут даже консультироваться у
                специалистов-социологов.
              </LineElement>
              <LineElement area="e">
                <BorderedBlock>
                  <div>
                    2. Кем этот человек мне приходится – другом, партнером,
                    наставником, руководителем, пр.?
                  </div>
                  <img src={ppls} alt="peoples" width="250px" />
                </BorderedBlock>
              </LineElement>
              <LineElement area="f">
                Данный функционал уже реализован в демо-версии Human Radar –
                каждая карточка контакта содержит поля Radar Circle 1,2,3,4, а
                анализировать свои Circles вы сможете в дешборде Radar Circles.
              </LineElement>
              <LineElement area="g">
                <BorderedBlock>
                  <div>
                    3. Что мне важно/полезно знать/помнить про этого человека?
                  </div>
                  <img src={man} alt="man" width="250px" />
                </BorderedBlock>
              </LineElement>
              <LineElement area="h">
                Как Вы увидите, карточка контакта в системе Human Radar
                предоставляет возможность записать про людей много! Конечно,
                такая глубина погружения будет зависеть от ответа на предыдущие
                два вопроса. Философия проекта заключается в том, что
                знать/помнить много про тех людей, которые Вам близки или важны,
                - это и есть стратегическое и системное отношение к управлению
                своим нетворком.
              </LineElement>
            </GridContainer>
            <h2>Технологии</h2>
            <div>
              Благодаря применению технологий искусственного интеллекта
              платформа Human Radar позволит пользователям:
            </div>
            <ul>
              <li>
                Наполнять систему данными о своих контактах и вести их учет.
              </li>
              <li>
                Получать апдейты о данных человека, которые тот размещает в
                открытом доступе в социальных сетях.
              </li>
              <li>
                Применять аналитические инструменты для анализа и системного
                управления своим нетворком.
              </li>
              <li>
                Тренировать память и лучше помнить детали о важных/полезных для
                пользователя людях.
              </li>
              <li>Интегрировать Human Radar с другими приложениями.</li>
              <li>
                Эффективно обмениваться данными с другими пользователя, выбирая
                такую <i>глубину погружения</i>, которая соответствует близости
                с человеком.
              </li>
            </ul>
            <TextBlockLarge>
              Итак, миссия проекта Human Radar заключена в том, чтобы помочь
              людям подходить к управлению своими связями осознанно и системно.
              И, возможно, преодолеть число Данбара!{" "}
              <span role="img" aria-label="smile">
                😀
              </span>
            </TextBlockLarge>
            <TextBlockLarge>
              А если и не преодолеть, то точно получать больше удовольствия и
              пользы от общения с теми людьми, которыми мы себя окружаем – ведь
              как сказано в самом начале главной книги человечества «Нехорошо
              быть человеку одному…».
            </TextBlockLarge>
          </div>
        )}

        {page === 4 && (
          <div>
            <h2>
              Техническая информация (для тех, кому интересно{" "}
              <span role="img" aria-label="smile">
                😀
              </span>
              )
            </h2>
            <TextBlockStd>
              В качестве технологической платформы для сервиса Human Radar мы
              используем <b>Google Cloud Platform</b> – одну из лидирующих среди
              всех облачных решений. NoSQL база данных Cloud Firestore
              обеспечивает надёжное хранение данных, возможность real-time
              обновления, и с ней легко работать с любого клиентского
              устройства.
            </TextBlockStd>
            <TextBlockStd>
              <b>Пользовательский интерфейс</b> написан с использованием
              технологии React и PWA – это значит, что сервис полноценно
              использует все современные возможности Web-платформы, и его можно
              использовать как в десктоп, так и в мобильной версии, - без потери
              функциональности.
            </TextBlockStd>
            <TextBlockStd>
              За <b>аутентификацию и авторизацию</b> пользователей отвечает
              Firebase Authentication – сервис, который централизованно
              управляет доступом пользователей к системе, и позволяет
              настраивать способы входа. В данный момент реализован логин с
              помощью имени пользователя и пароля, а в коммерческой версии
              платформы будет подключена двухфакторную аутентификацию с помощью
              смс-кода или кода, отправляемого на электронную почту. Действия
              пользователей в системе постоянно логируются, также настроен
              ежедневный бэкап пользовательских данных – это означает
              невозможность повреждения или пропажи данных.
            </TextBlockStd>
            <TextBlockStd>
              Все подключения к Google Cloud защищены сертификатом SSL, обмен
              данными происходит по защищенному HTTPS подключению. Firebase
              Hosting позволяет получить быстрый доступ к сервису, так как файлы
              сервиса хранятся в распределенной CDN (Content Delivery Network)
              Google Cloud полностью соответствует всем европейским и
              американским нормативным актам по хранению и защите персональных
              данных пользователей (
              <a
                href="https://support.google.com/googlecloud/answer/6056694?hl=ru"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.google.com/googlecloud/answer/6056694?hl=ru
              </a>
              ). Сегодня данные демо версии хранятся в европейских ЦОДах Google,
              <b> для соответствия ФЗ-152 Российской Федерации</b> в
              коммерческой версии Human Radar будет предусмотрена возможность
              использования сервиса Яндекс Облако, для того чтобы данные
              хранились в дата-центрах на территории РФ. Технических препятствий
              для переноса сервиса в Яндекс Облако нет.
            </TextBlockStd>
            <TextBlockStd>
              Все данные пользователей будут, во-первых, защищены шифрованием, а
              во-вторых – могут храниться не на серверах Human Radar, а в том
              облаке, которое удобно пользователю (например, iCloud). Эти две
              меры будут означать, что Human Radar не будет иметь прямого
              доступа к конфиденциальным данным пользователей, что может стать
              крайне важным аспектом, учитывая специфику проекта.
            </TextBlockStd>
          </div>
        )}
        {page === 5 && (
          <div>
            <h2>Вы готовы начать тестирование?</h2>
            <TextBlockStd>
              <b>Просьба убедиться в том, что:</b>
            </TextBlockStd>
            <ol>
              <li style={{ marginBottom: "6px" }}>
                <u>Вы используете для теста компьютер</u>. Вы можете войти в
                систему и со смартфона, но – пока у нас не появится мобильная
                версия – это будет гораздо менее удобно.
              </li>
              <li style={{ marginBottom: "6px" }}>
                <u>У Вас перед глазами есть присланная Вам инструкция.</u>
              </li>
              <li style={{ marginBottom: "6px" }}>
                <u>
                  Те из вас, кто пользуется Telegram, имеет под рукой свой
                  мобильный телефон
                </u>
                . Как будто сегодня бывает по-другому…{" "}
                <span role="img" aria-label="smile">
                  😀
                </span>
              </li>
              <li style={{ marginBottom: "6px" }}>
                <u>
                  Вы не забудете написать или надиктовать обратную связь по
                  форме, высланной Вам вместе с инструкцией.
                </u>{" "}
                Очень рекомендуем держать ее перед глазами (распечатать, если
                это возможно) и делать пометки, чтобы не забыть фидбек, который
                может оказаться для нас очень ценным.
              </li>
            </ol>
            <TextBlockLarge>
              Мы почти готовы начать! Нам осталось лишь объяснить Вам один{" "}
              <b>ОЧЕНЬ ВАЖНЫЙ</b> момент!
            </TextBlockLarge>
            <TextBlockStd>
              В силу конфиденциальности личного нетворка каждого человека мы,
              конечно, не могли дать Вам доступ к учетной записи Максима
              Фельдмана. Поэтому мы создали демо-среду Human Radar, которая
              призвана помочь Вам представить, что Вы управляете своим
              нетворком!
            </TextBlockStd>
            <TextBlockLarge>
              <b>Представьте, что это Ваш нетворк</b>!!! Теперь представьте еще
              раз!{" "}
              <span role="img" aria-label="smile">
                😀
              </span>{" "}
              Постарайтесь напоминать себе об этом во время тестирования!
            </TextBlockLarge>
            <TextBlockStd>
              <b>Вы – серьезный российский бизнесмен (или бизнесвуман)</b>, и
              поэтому неудивительно, что Ваш деловой нетворк состоит из
              представителей списка Форбс (192 чел.) и управленцев, вошедших в
              рейтинг «Лучшие менеджеры России» от Коммерсанта (201 чел.).
            </TextBlockStd>
            <TextBlockStd>
              <b>
                Важно отметить следующее: А) вся информация в карточках собрана
                исключительно из открытых источников; Б) в целях демонстрации
                функционала системы мы изменили такие детали как город и страну
                проживания, поле Geo, пофантазировали о том, в какие круги
                входят эти контакты (Друзья, Партнеры, Наставники, пр.), а также
                поставили несуществующую контактную информацию. Вся остальная
                информация в карточках соответствует действительности!
              </b>
              И если Вы захотите, то сможете выгрузить эту базу в Excel и
              оставить ее себе – мы покажем, как это сделать.
            </TextBlockStd>
            <TextBlockStd>
              Как уже было сказано, среди Ваших контактов есть люди, кого Вы
              знаете хорошо (Партнеры и Друзья), и в их карточки Вы внесли
              больше информации, чем в те, кого Вы знаете хуже. Но раз Вы
              добавили всех этих 396 человек в свой Human Radar, значит все они
              для Вас ценны!
            </TextBlockStd>
            <TextBlockStd>
              <b>Итак, это Ваш нетворк</b>, и у Вас выдался час времени для
              того, чтобы с ним поработать!{" "}
              <span role="img" aria-label="smile">
                😀
              </span>
            </TextBlockStd>
            <h2>ПОЕХАЛИ!</h2>
            <Button
              onClick={handleStart}
              variant="contained"
              color="primary"
              style={{ width: "270px" }}
            >
              Начать тестирование
            </Button>
          </div>
        )}
        <ButtonsBlock>
          <Button onClick={handlePrev} disabled={page === 0}>
            <LeftIcon />
          </Button>
          <Button onClick={handleNext} disabled={page === 5}>
            <RightIcon />
          </Button>
        </ButtonsBlock>
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return { auth: state.firebase.auth, visited: state.crud.userInfo.visited };
};

const mapDispatchToProps = {
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(IntroBlock);
