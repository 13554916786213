import styled from "styled-components";

export const TextBlock = styled.div`
  padding: 8px;
  font-size: 16px;
`;

export const TextBlockBold = styled(TextBlock)`
  font-weight: 800;
`;

export const TextSideBlock = styled.div`
  margin-top: 190px;

  @media (max-width: 991.98px) {
    margin-top: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  //flex-direction: column;
  //border: 1px solid red;
  width: 100%;
  max-width: 100%;

  @media (max-width: 991.98px) {
    flex-direction: column;
  }
`;
