import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
// import { isAuth } from '../services/auth';
import Header from "../components/Header";

const AuthRoute = props => {
  const { component: Component, auth, path } = props;

  if (auth.isLoaded && !auth.isEmpty) {
    return (
      <>
        <Header />
        {Component ? (
          <Route component={Component} {...props} />
        ) : (
          <Route {...props} />
        )}
      </>
    );
  }
  return (
    <Redirect
      to={{
        pathname: "/login",
        state: { ref: path }
      }}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.elementType
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    path: state.router.location.pathname
  };
};

export default connect(mapStateToProps)(AuthRoute);
