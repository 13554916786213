import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import ControlTab from "../ControlTab";
import UserList from "../UserList";
import { getUserListWithSearch } from "../../selectors/";
import moment from "moment";
import { Redirect } from "react-router-dom";
import ym from "react-yandex-metrika";

const App = ({ filter, data, location, bday, auth, demo, visited }) => {
  const [rows, setRows] = useState(data);

  ym("setUserID", auth.uid);

  const chk = (temp, arr) => {
    if (temp.startsWith('"')) {
      temp = `^${temp.slice(1)}`;
    }
    if (temp.endsWith('"')) {
      temp = `${temp.slice(0, -1)}$`;
    }
    const regex = new RegExp(temp, "i");
    return arr.some(el => regex.test(el));
  };

  const checker = (value, arr) => {
    if (Array.isArray(value)) {
      return value.every(e => chk(e.trim(), arr));
    } else if (/,/.test(value)) {
      return value.split(",").every(e => chk(e.trim(), arr));
    } else {
      return chk(value, arr);
    }
  };

  const range = (value, chk) => {
    if (!chk) return;
    if (/-/.test(value)) {
      let [from, to] = value.split("-");
      if (+chk >= +from && +chk <= +to) {
        return true;
      }
    } else {
      if (+value === +chk) {
        return true;
      }
    }
  };

  const filtering = (data, key, temp) => {
    let value = temp;

    if (value.startsWith('"')) {
      value = `^${value.slice(1)}`;
    }
    if (value.endsWith('"')) {
      value = `${value.slice(0, -1)}$`;
    }

    const regex = new RegExp(value, "i");
    switch (key) {
      case "fast":
        //return data.filter(el => regex.test(el.fast));
        return data.filter(el =>
          checker(value.split(" "), el.fast.split(", "))
        );
      case "surname":
        return data.filter(el => regex.test(el.surname));
      case "country":
        return data.filter(el => regex.test(el.country));
      case "tags":
        return data.filter(el =>
          checker(value, [el.tag1, el.tag2, el.tag3, el.tag4, el.tag5, el.tag6])
        );
      case "industry":
        return data.filter(el =>
          checker(value, [
            el.prof1_industry1,
            el.prof1_industry2,
            el.prof1_industry3,
            el.prof2_industry1,
            el.prof2_industry2,
            el.prof2_industry3,
            el.prof3_industry1,
            el.prof3_industry2,
            el.prof3_industry3
          ])
        );
      case "age":
        return data.filter(el =>
          range(value, moment().diff(el.birthdate, "years"))
        );
      case "city":
        return data.filter(el => regex.test(el.city));
      case "geo":
        return data.filter(el => checker(value, [el.geo1, el.geo2]));
      case "company":
        return data.filter(el =>
          checker(value, [
            el.prof1_activity,
            el.prof2_activity,
            el.prof3_activity,
            el.addactivity1_activity,
            el.addactivity2_activity
          ])
        );
      case "id":
        const idList = value.split(",");
        return data.filter(({ id }) => idList.includes(id));
      //return data.filter(el => regex.test(el.id));
      case "position":
        return data.filter(el =>
          checker(value, [
            el.addactivity1_position,
            el.addactivity2_position,
            el.prof1_position1,
            el.prof1_position2,
            el.prof2_position1,
            el.prof2_position2,
            el.prof3_position1,
            el.prof3_position2
          ])
        );
      case "hobby":
        return data.filter(el =>
          checker(value, [
            el.hobby1,
            el.hobby2,
            el.hobby3,
            el.hobby4,
            el.hobby5,
            el.hobby6
          ])
        );
      case "kids":
        return data.filter(el => range(value, el.kids));
      case "info":
        return data.filter(el =>
          checker(value, [el.info1, el.info2, el.info3, el.info4])
        );
      case "radartag":
        const x = value.split(",");
        return data.filter(el => {
          if (!el.info1) return false;
          return x.some(v => {
            const reg = new RegExp(v, "i");
            return reg.test(el.info1);
          });
        });
      case "circles":
        //return data.filter(el => checker(value, [el.info1, el.info2]));
        return data.filter(el => {
          if (!el.info1) return false;

          const foo = value.split(",");
          const bar = el.info1.split(" ");
          if (foo.length !== bar.length) {
            return false;
          }
          return checker(value, [el.info1]);
        });
      case "relatives":
        return data.filter(el =>
          checker(value, [el.siblings, el.father, el.mother])
        );
      case "details":
        console.log(value);
        return data.filter(el =>
          checker(value, [el.spouse_work, el.spouse_details])
        );
      case "childhood":
        return data.filter(el =>
          checker(value, [
            el.placeofbirth,
            el.bio_lived1,
            el.bio_lived2,
            el.mother,
            el.father,
            el.siblings,
            el.bio_addinfo,
            el.childhoodact,
            el.lifestory1,
            el.lifestory2
          ])
        );

      case "education":
        return data.filter(el =>
          checker(value, [
            el.education1,
            el.education2,
            el.education3,
            el.major1,
            el.major2,
            el.major3,
            el.education1_addinfo,
            el.education2_addinfo,
            el.education3_addinfo
          ])
        );
      case "career":
        return data.filter(el =>
          checker(value, [
            el.company1,
            el.company2,
            el.company3,
            el.company4,
            el.company5,
            el.company6,
            el.company1_about,
            el.company1_industry,
            el.company1_position,
            el.company2_about,
            el.company2_industry,
            el.company2_position,
            el.company3_about,
            el.company3_industry,
            el.company3_position,
            el.company4_about,
            el.company4_industry,
            el.company4_position,
            el.company5_about,
            el.company5_industry,
            el.company5_position,
            el.company6_about,
            el.company6_industry,
            el.company6_position
          ])
        );
      case "professional":
        return data.filter(el =>
          checker(value, [
            el.professional1,
            el.professional2,
            el.professional3,
            el.professional4,
            el.professional5,
            el.professional6,
            el.professional7,
            el.professional8
          ])
        );
      case "language":
        return data.filter(el =>
          checker(value, [
            el.language1,
            el.language2,
            el.language3,
            el.language4,
            el.level1,
            el.level2,
            el.level3,
            el.level4
          ])
        );

      default:
        return data;
    }
  };

  const filterBDay = (data, value) => {
    return data.filter(el => {
      if (!el.birthdate) return false;
      const day = moment(el.birthdate).format("D");
      const month = moment(el.birthdate).format("M");
      if (
        day >= value.startDay &&
        day <= value.endDay &&
        month >= value.startMonth &&
        month <= value.endMonth
      ) {
        return true;
      }
      return false;
    });
  };

  const getFilteredData = (data, filter) => {
    return Object.entries(filter).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }
      if (key === "bdate") {
        return filterBDay(acc, value);
      } else {
        return filtering(acc, key, value);
      }
    }, data);
  };

  useEffect(() => {
    // if (collections[db]) {
    //   console.log('qwerty')
    //   return collections[db].sort(({ info1: a }, { info1: b }) => {
    //     if (
    //       (a === "Партнеры" || a === "Друзья") &&
    //       (b !== "Друзья" || b !== "Партнеры")
    //     )
    //       return 1;
    //     if (
    //       (a === "Партнеры" || a === "Друзья") &&
    //       (b === "Друзья" || b === "Партнеры")
    //     )
    //       return 0;
    //     return -1;
    //   });
    // }

    // console.log(
    //   data
    //     .map(({ info1 }) => info1)
    //     .sort(({info1: a}, {info1: b}) => {
    //       a = /партнеры/i.test(a) ? "Партнеры" : a;
    //       b = /партнеры/i.test(b) ? "Партнеры" : b;
    //       a = /друзья/i.test(a) ? "Друзья" : a;
    //       b = /друзья/i.test(b) ? "Друзья" : b;
    //
    //       if (a === b) return 0;
    //       if (a === "Партнеры" && b === "Друзья") return 1;
    //       if (b === "Партнеры" && a === "Друзья") return -1;
    //       if (
    //         (a === "Партнеры" || a === "Друзья") &&
    //         (b !== "Партнеры" || b !== "Друзья")
    //       )
    //         return -1;
    //       return 0;
    //     })
    // );
    //
    // console.log(
    //   data.sort(({ info1: a }, { info1: b }) => {
    //     if (
    //       (a === "Партнеры" || a === "Друзья") &&
    //       (b !== "Друзья" || b !== "Партнеры")
    //     )
    //       return 1;
    //     if (
    //       (a === "Партнеры" || a === "Друзья") &&
    //       (b === "Друзья" || b === "Партнеры")
    //     )
    //       return 0;
    //     return -1;
    //   })
    // );

    let tempData;
    if (demo) {
      tempData = data.sort(({ info1: a }, { info1: b }) => {
        a = /партнеры/i.test(a) ? "Партнеры" : a;
        b = /партнеры/i.test(b) ? "Партнеры" : b;
        a = /друзья/i.test(a) ? "Друзья" : a;
        b = /друзья/i.test(b) ? "Друзья" : b;

        if (a === b) return 0;
        if (a === "Партнеры" && b === "Друзья") return 1;
        if (b === "Партнеры" && a === "Друзья") return -1;
        if (
          (a === "Партнеры" || a === "Друзья") &&
          (b !== "Партнеры" || b !== "Друзья")
        )
          return -1;
        return 0;
      });
    } else {
      tempData = data;
    }

    if (!filter) {
      setRows(data);
    } else {
      tempData = getFilteredData(data, filter);
      setRows(tempData);
    }
    // eslint-disable-next-line
  }, [filter, data]);

  return (
    <>
      <ControlTab />
      <UserList data={rows} bday={bday} />
      {location && location.state && location.state.ref && (
        <Redirect to={location.state.ref} />
      )}
      {demo && !visited && <Redirect to="/intro" />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    filter: state.filter,
    data: getUserListWithSearch(state),
    auth: state.firebase.auth,
    demo: state.crud.userInfo.demo,
    visited: state.crud.userInfo.visited
  };
};

export default connect(mapStateToProps)(App);
