import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  padding: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 15px;
`;
export const Label = styled.h2`
  margin: 0;
`;

export const LineBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid lightblue;
  padding-right: 12px;
  margin-right: 12px;

  @media (max-width: 991.98px) {
    border-right: none;
    padding: 0;
    margin: 0;
  }
`;

export const TelBlock = styled(LineBlock)`
  grid-area: t;
`;
export const MailBlock = styled(LineBlock)`
  grid-area: m;
`;
export const FieldBlock = styled(LineBlock)`
  grid-area: f;
`;

export const InfoLine = styled.div`
  display: grid;
  width: 100%;
  //grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "t m f";

  @media (max-width: 991.98px) {
    grid-template-areas:
      "t m"
      "f f";
    gap: 12px;
  }

  @media (max-width: 575.98px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "t"
      "m"
      "f";
  }
`;
