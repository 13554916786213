import React, { useEffect, useState } from "react";
import ControlTab from "../ControlTab";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import {
  BoldText,
  Container,
  TableBlock,
  TextBlock,
  UnderText
} from "./index.style";
import { getData } from "../../selectors";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";

const RadarMeetings = ({ userdata, push }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (userdata && userdata.length > 0) {
      const foo =
        userdata &&
        userdata
          .reduce((acc, el) => {
            const { name, surname, foto, id } = el;

            for (let step = 1; step <= 3; step += 1) {
              if (el[`interaction${step}`]) {
                const obj = {
                  id,
                  name,
                  surname,
                  foto,
                  type: el[`interaction${step}`],
                  date: el[`interaction${step}_date`],
                  notes: el[`interaction${step}_notes`],
                  act1: el[`interaction${step}_act1`],
                  act2: el[`interaction${step}_act2`],
                  act3: el[`interaction${step}_act3`]
                };
                acc.push(obj);
              }
            }
            return acc;
          }, [])
          .sort(({ date: date1 }, { date: date2 }) => {
            const momentDate1 = moment(date1);
            const momentDate2 = moment(date2);
            return momentDate1.isSame(momentDate2)
              ? 0
              : momentDate1.isAfter(momentDate2)
              ? 1
              : -1;
          });
      setData(foo);
      setLoading(false);
    }
  }, [userdata]);

  const showNotes = notes => {
    return notes.split("\n").map((e, idx) => {
      return <TextBlock key={idx}>{e}</TextBlock>;
    });
  };

  return (
    <>
      <ControlTab />
      <Container>
        <TextBlock>
          Дешборд <BoldText>Radar Meetings</BoldText> находится в разработке,{" "}
          <UnderText>но уже работает в ограниченном режиме</UnderText>.
        </TextBlock>
        <TextBlock>
          Заниматься своим нетворком системно помимо прочего значит и вести учет
          важных для нас встреч. Поэтому в каждой карточке платформы Human Radar
          есть раздел <BoldText>Общение и коммуникации</BoldText>, в котором
          пользователь имеет возможность вести учет interactions (встреч,
          звонков, пр.) с теми своими контактами, по которым вести такой учет
          считает важным.
        </TextBlock>
        <TextBlock>
          В дешборде <BoldText>Radar Meetings</BoldText> автоматически
          собирается эта информация из карточек. Смысл этого дешборда в том,
          чтобы в одном месте видеть информацию по всем встречам и всем action
          items, которые были записаны. В будущем мы добавим в этот дешборд
          функционал задачника (task manager), а также обеспечим возможность
          экспортировать задачи в самые популярные приложения to-do (Google
          tasks, Microsoft To Do, Todoist, Wunderlist, пр.).
        </TextBlock>
        <TextBlock>
          Вы можете зайти в любую карточку Human Radar и заполнить одно из полей
          <BoldText> Interaction 1,2,3</BoldText> в разделе
          <BoldText> Общение и коммуникации</BoldText> – и эта информация сразу
          появится в дешборде Radar Meetings.
        </TextBlock>

        {loading && <CircularProgress size={50} />}
        {data.length > 0 && (
          <TableBlock>
            <thead>
              <tr>
                <th>CONTACT</th>
                <th>INTERACTION TYPE</th>
                <th>DATE</th>
                <th style={{ minWidth: "300px" }}>MEETING NOTES</th>
                <th style={{ minWidth: "300px" }}>ACTION ITEMS</th>
              </tr>
            </thead>
            <tbody>
              {data.map((el, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "16px",
                          cursor: "pointer"
                        }}
                        onClick={() => push(`/user/${el.id}`)}
                      >
                        <img src={el.foto} width={60} alt="user card" />
                        <div style={{ fontWeight: 800, marginTop: "10px" }}>
                          {el.name} {el.surname}
                        </div>
                      </div>
                    </td>
                    <td>{el.type}</td>
                    <td>{moment(el.date).format("DD.MM.YYYY")}</td>
                    <td>
                      <div
                        style={{
                          maxHeight: "120px",
                          height: "100%",
                          overflowY: "scroll"
                        }}
                      >
                        {showNotes(el.notes)}
                      </div>
                    </td>
                    <td>
                      <ol>
                        {el.act1 && <li>{el.act1}</li>}
                        {el.act2 && <li>{el.act2}</li>}
                        {el.act3 && <li>{el.act3}</li>}
                      </ol>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </TableBlock>
        )}
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    userdata: getData(state)
  };
};

const mapDispatchToProps = {
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(RadarMeetings);
