import {
  LOGIN_INIT,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_INIT,
  LOGOUT_SUCCESS
} from "../constants/authActions";

export const login = (
  { login: loginName, pass },
  firebase
) => async dispatch => {
  dispatch({ type: LOGIN_INIT });
  try {
    firebase.auth().signInWithEmailAndPassword(loginName, pass);
    dispatch({ type: LOGIN_SUCCESS, payload: { username: loginName } });
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: { error: "Имя пользователя или пароль введены не верно " }
    });
  }
};

export const logout = firebase => async dispatch => {
  dispatch({ type: LOGOUT_INIT });
  try {
    await firebase.logout();
    dispatch({ type: LOGOUT_SUCCESS });
  } catch (e) {
    console.log(e);
  }
};
