import styled from "styled-components";

export const MarkerInGroupStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-left: -7px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: red;
  background-size: cover;
  background-position: center;
`;

export const MarkerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: red;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.2);
  }
`;

export const MarkerGroup = styled.div`
  display: flex;
  width: ${props => (props.length === 2 ? "55px" : "80px")};
  border-radius: 100px;
`;

export const MarkerCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  padding: 8px;
  //margin-left: -10px;
  margin-left: 0;
  text-align: center;
  font-size: 14px;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: red;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 98vh;
`;
