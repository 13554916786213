import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;

  //@media (max-width: 991.98px) {
  //  flex-direction: column;
  //  padding: 0;
  //  align-items: flex-start;
  //  justify-content: flex-start;
  //}
`;

export const MenuBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  //border: 1px solid red;
  width: 100%;
  margin-right: 40px;
`;

export const UserBlock = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const UserName = styled.div`
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  margin-right: 15px;
`;

export const IconWrap = styled.div`
  cursor: pointer;
`;

export const FloatLogo = styled.div`
  margin-right: 20px;
  cursor: pointer;
  @media (max-width: 575.98px) {
    display: none;
  }
`;

export const HeaderText = styled.div`
  margin-left: 20px;
  font-size: 26px;
  font-weight: 800;
`;

export const DemoText = styled.div`
  margin-left: 30px;
  font-size: 16px;
  color: #2196f3;
`;
