import React, { useState } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import OkIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";

const ZipLoader = () => {
  const [filesList, setFiles] = useState([]);
  const [ready, setReady] = useState([]);
  const [error, setError] = useState([]);

  const handleLoad = async e => {
    const files = [];
    for (const file of e.target.files) {
      files.push(file);
    }
    setFiles(files);

    const promises = files.map(async file => {
      let formData = new FormData();
      formData.append("file", file);
      try {
        const { data } = await axios.post(
          "https://us-central1-alafia-tech.cloudfunctions.net/uplZip",

          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
        if (data.name) {
          setReady(i => [...i, file.name]);
          return data;
        } else {
          setError(i => [...i, file.name]);
          return Promise.resolve(null);
        }
      } catch (e) {
        setError(i => [...i, file.name]);
        return Promise.resolve(null);
      }
    });

    let result = await Promise.all(promises);
    console.log(result);
  };

  return (
    <div>
      <input
        accept=".zip"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        //multiple
        onChange={handleLoad}
      />
      <label htmlFor="raised-button-file">
        <Button color="primary" component="span">
          Load
        </Button>
      </label>
      <div>
        {filesList.map(({ name }, idx) => {
          return (
            <div key={idx}>
              {name}
              {error.includes(name) && <ErrorIcon />}
              {ready.includes(name) && <OkIcon />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ZipLoader;
