import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  padding: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  box-sizing: border-box;
`;

export const Label = styled.h2`
  margin: 0;
`;

export const LineBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-right: ${({ noRightBorder }) =>
    noRightBorder ? "none" : "1px solid lightblue"};
  padding-right: 12px;
  margin-right: 12px;
  @media (max-width: 991.98px) {
    display: grid;
    gap: 12px;
    grid-template-columns: 2fr 1fr;
    padding: 0;
    margin: 0;
    border-right: none;
  }
`;

export const InfoLine = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 991.98px) {
    grid-template-columns: 1fr;
  }
`;
