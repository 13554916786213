import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopBlock = styled.div`
  display: flex;

  margin-bottom: 12px;
  @media (max-width: 991.98px) {
    flex-direction: column;
  }
`;
export const LogoBlock = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid lightgray;
  font-size: 22px;
  width: 400px;

  @media (max-width: 991.98px) {
    flex-direction: column;
    margin-bottom: 12px;
    width: 100%;
    border-bottom: 1px solid lightgray;
    border-right: none;
    padding-bottom: 12px;
  }
`;

export const ShareBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  justify-content: center;

  font-size: 22px;

  @media (max-width: 991.98px) {
    margin-left: 0;
  }
`;

export const BoldText = styled.div`
  font-weight: 800;
  margin-left: 30px;

  @media (max-width: 991.98px) {
    margin-left: 0;
    margin-top: 12px;
  }
`;

export const DistributorBlock = styled.div`
  display: flex;
  align-items: center;
`;
export const DistributorNameBlock = styled.div`
  padding: 4px 12px;
  background-color: lightgray;
  margin-left: 12px;
`;
export const HoursBlock = styled.div`
  margin-top: 12px;
  font-size: 18px;
`;

export const ShareTypeBlock = styled.div`
  display: grid;
  grid-template-columns: 20px 120px 20px 120px;
  align-items: center;
  justify-content: flex-end;
`;

export const ActionText = styled.div`
  font-size: 14px;
  margin-left: 12px;
  cursor: pointer;
`;

export const MailText = styled.a`
  font-size: 14px;
  margin-left: 12px;
  cursor: pointer;
  color: #000;
  text-decoration: none;
`;
