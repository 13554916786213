import debounce from 'lodash.debounce';
import { SET_FILTER, SET_SEARCH, RESET_FILTER } from '../constants/filterActions';

export const setFilter = filter => dispatch => {
  dispatch({ type: SET_FILTER, payload: filter });
};

export const resetFilter = () => dispatch => {
  dispatch({ type: RESET_FILTER });
};

const debouncedFilter = debounce((str, dispatch) => {
  dispatch({ type: SET_SEARCH, payload: str });
}, 200);

export const setFastSearch = str => dispatch => {
  debouncedFilter(str, dispatch);
};
