import React from 'react';
import PropTypes from 'prop-types';

import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationContainer } from './index.style';

const Paginator = ({ currentPage, total, portionSize = 50, setPaginatorPage }) => {
  return (
    <TablePaginationContainer>
      {total > 0 && (
        <TablePagination
          component="div"
          count={total}
          rowsPerPage={portionSize}
          rowsPerPageOptions={[]}
          page={currentPage}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={setPaginatorPage}
        />
      )}
    </TablePaginationContainer>
  );
};

Paginator.propTypes = {
  total: PropTypes.number.isRequired,
  portionSize: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  setPaginatorPage: PropTypes.func.isRequired,
};

export default Paginator;
