import React, { memo } from "react";
import { connect } from "react-redux";
import {
  ColumnBlock,
  Container,
  GridBlock,
  InfoLine,
  Label,
  LineBlock
} from "./index.style";
import { changeFormValue } from "../../actions/form";
import TextField from "@material-ui/core/TextField";

const LangBlock = ({
  editMode,
  changeFormValue,
  prof1_activity,
  prof1_position1,
  prof1_position2,
  prof1_industry1,
  prof1_industry2,
  prof1_industry3,
  prof2_activity,
  prof2_position1,
  prof2_position2,
  prof2_industry1,
  prof2_industry2,
  prof2_industry3,
  prof3_activity,
  prof3_position1,
  prof3_position2,
  prof3_industry1,
  prof3_industry2,
  prof3_industry3
  /* company,
  position1,
  position2,
  industry1,
  industry2,
  industry3*/
}) => {
  const handleChange = e => {
    const id = e.target.id;
    const value = e.target.value;
    changeFormValue(id, value);
  };

  const smFont = { style: { fontSize: 14, height: 19 } };

  return (
    <Container>
      <Label>Текущая профессиональная деятельность</Label>
      <InfoLine>
        <LineBlock>
          <GridBlock columns={2}>
            <TextField
              id="prof1_activity"
              label="Activity 1"
              value={prof1_activity}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
            <ColumnBlock>
              <TextField
                id="prof1_position1"
                label="Position 1"
                value={prof1_position1}
                margin="normal"
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                fullWidth
                onChange={handleChange}
              />
              <TextField
                id="prof1_position2"
                label="Position 2"
                value={prof1_position2}
                margin="normal"
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                fullWidth
                onChange={handleChange}
              />
            </ColumnBlock>
          </GridBlock>
          <GridBlock columns={3}>
            <TextField
              id="prof1_industry1"
              label="Industry 1"
              value={prof1_industry1}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              id="prof1_industry2"
              label="Industry 2"
              value={prof1_industry2}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              id="prof1_industry3"
              label="Industry 3"
              value={prof1_industry3}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
          </GridBlock>
        </LineBlock>
        <LineBlock>
          <GridBlock columns={2}>
            <TextField
              id="prof2_activity"
              label="Activity 2"
              value={prof2_activity}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
            <ColumnBlock>
              <TextField
                id="prof2_position1"
                label="Position 1"
                value={prof2_position1}
                margin="normal"
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                fullWidth
                onChange={handleChange}
              />
              <TextField
                id="prof2_position2"
                label="Position 2"
                value={prof2_position2}
                margin="normal"
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                fullWidth
                onChange={handleChange}
              />
            </ColumnBlock>
          </GridBlock>
          <GridBlock columns={3}>
            <TextField
              id="prof2_industry1"
              label="Industry 1"
              value={prof2_industry1}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              id="prof2_industry2"
              label="Industry 2"
              value={prof2_industry2}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              id="prof2_industry3"
              label="Industry 3"
              value={prof2_industry3}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
          </GridBlock>
        </LineBlock>
        <LineBlock noRightBorder>
          <GridBlock columns={2}>
            <TextField
              id="prof3_activity"
              label="Activity 3"
              value={prof3_activity}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
            <ColumnBlock>
              <TextField
                id="prof3_position1"
                label="Position 1"
                value={prof3_position1}
                margin="normal"
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                fullWidth
                onChange={handleChange}
              />
              <TextField
                id="prof3_position2"
                label="Position 2"
                value={prof3_position2}
                margin="normal"
                InputProps={{
                  readOnly: !editMode
                }}
                inputProps={smFont}
                fullWidth
                onChange={handleChange}
              />
            </ColumnBlock>
          </GridBlock>
          <GridBlock columns={3}>
            <TextField
              id="prof3_industry1"
              label="Industry 1"
              value={prof3_industry1}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              id="prof3_industry2"
              label="Industry 2"
              value={prof3_industry2}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
            <TextField
              id="prof3_industry3"
              label="Industry 3"
              value={prof3_industry3}
              margin="normal"
              InputProps={{
                readOnly: !editMode
              }}
              inputProps={smFont}
              fullWidth
              onChange={handleChange}
            />
          </GridBlock>
        </LineBlock>
      </InfoLine>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    // company: state.form.company,
    // position1: state.form.position1,
    // position2: state.form.position2,
    // industry1: state.form.industry1,
    // industry2: state.form.industry2,
    // industry3: state.form.industry3,
    prof1_activity: state.form.prof1_activity,
    prof1_position1: state.form.prof1_position1,
    prof1_position2: state.form.prof1_position2,
    prof1_industry1: state.form.prof1_industry1,
    prof1_industry2: state.form.prof1_industry2,
    prof1_industry3: state.form.prof1_industry3,
    prof2_activity: state.form.prof2_activity,
    prof2_position1: state.form.prof2_position1,
    prof2_position2: state.form.prof2_position2,
    prof2_industry1: state.form.prof2_industry1,
    prof2_industry2: state.form.prof2_industry2,
    prof2_industry3: state.form.prof2_industry3,
    prof3_activity: state.form.prof3_activity,
    prof3_position1: state.form.prof3_position1,
    prof3_position2: state.form.prof3_position2,
    prof3_industry1: state.form.prof3_industry1,
    prof3_industry2: state.form.prof3_industry2,
    prof3_industry3: state.form.prof3_industry3
  };
};

const mapDispatchToProps = {
  changeFormValue
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(LangBlock));
