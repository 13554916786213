import styled from "styled-components";

export const StylingBlock = styled.div`
  svg path {
    stroke: white;
    stroke-width: 1px;
    //stroke-dasharray: 5px 5px;
  }

  svg text {
    fill: white;
    font-family: "Courier New";
    font-size: 10px;
  }
`;
