import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
`;

export const ViewBlock = styled.div`
  font-size: 16px;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  padding-bottom: 6px;
  margin-bottom: 8px;
  margin-right: 15px;
  box-sizing: content-box;
  position: relative;
  overflow: hidden;
  word-break: break-all;
  &:hover {
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
  }
`;

export const Link = styled.a`
  padding: 0;
  margin: 0;
  text-decoration: none;
`;

export const TopLabel = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: -24px;
  left: 0;
`;
