import styled from "styled-components";

export const Container = styled.div`
  //width: 300px; 
  padding: 10px 10px;
  //margin-bottom: 10px;
  border: 1px solid lightgray;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  position: relative;
`;

export const Label = styled.div`
  position: absolute;
  top: -8px;
  left: 10px;
  font-family: Roboto;
  font-size: 12px;
  z-index: 1;
  text-transform: uppercase;
  padding: 0 5px;
  background-color: white;
`
